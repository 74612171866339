.l_pageBanner {
    position: relative;
    min-height: 350px;
    height: 100vh;
    transition: height 300ms $easeOutCubic; // synchro avec alerts.scss ligne 9

    @media screen and (max-width: 1024px) { max-height: 830px; }

    &:before {
        content: '';
        position: absolute;
        height: 50%;
        right: 0;
        bottom: 0;
        background: $color2;
        transition: left 1300ms $easeInOutQuint;

        @media screen and (max-width: 500px) { right: 100px; }
    }

    &[data-scrollfire="fire"] {
        &:before {
            left: 200px;

            @media screen and (max-width: 1024px), screen and (max-height: 750px) { left: 150px; }
            @media screen and (max-width: 850px),  screen and (max-height: 599px) { left: 120px; }
            @media screen and (max-width: 600px)  { left: 100px; }
            @media screen and (max-width: 500px)  { left: 0%; }
        }

        .content .wrapper {
            picture {
                width: 100%;

                img { transform: none; }
            }

            .m_credit { opacity: 1; }
        }
    }

    .content {
        height: calc(100% - 100px);
        padding-right: 80px;

        @media screen and (max-width: 1024px), screen and (max-height: 750px) { height: calc(100% - 70px); padding-right: 50px; }
        @media screen and (max-width: 850px),  screen and (max-height: 599px) { height: calc(100% - 55px); }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
        @media screen and (max-width: 600px)  { height: calc(100% - 45px); padding-right: 25px; }
        @media screen and (max-width: 500px)  { padding-right: 0; }

        .wrapper {
            position: relative;
            height: 100%;

            picture {
                position: relative;
                width: 0%;
                height: 100%;
                overflow: hidden;
                transition: width 1300ms $easeInOutQuint;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(0deg, rgba($color1, 0.9) 0%, rgba($color1, 0) 100%);
                    z-index: 1;
                }

                img {
                    width: calc(100vw - 80px);
                    transition: transform 1300ms $easeInOutQuint;

                    @media screen and (max-width: 1024px), screen and (max-height: 750px) { width: calc(100vw - 50px); }
                    @media screen and (max-width: 768px) { width: calc(100vw - 30px); }
                    @media screen and (max-width: 600px) { width: calc(100vw - 25px); }
                    @media screen and (max-width: 500px) { width: 100vw; }
                }
            }

            h1 {
                position: absolute;
                padding-right: 100px;
                bottom: 70px;
                left: 250px;
                color: #fff;
                z-index: 2;

                @media screen and (max-width: 1400px) { left: 200px; }
                @media screen and (max-width: 1200px) { left: 180px; }
                @media screen and (max-width: 1024px), screen and (max-height: 750px) { padding-right: 50px; left: 150px; }
                @media screen and (max-width: 850px),  screen and (max-height: 599px) { left: 120px; }
                @media screen and (max-width: 768px)  { padding-right: 30px; left: 30px; bottom: 60px; }
                @media screen and (max-width: 600px)  { padding-right: 25px; }
            }

            .m_credit {
                position: absolute;
                top: 0;
                right: 0;
                opacity: 0;
                transition: opacity 300ms linear 1300ms;
            }
        }
    }
}

html.showLanding .l_pageBanner, html.showTransition .l_pageBanner {
    &:before {
        left: 100%;

        @media screen and (max-width: 500px) { left: calc(100% - 100px); right: 100px; }
    }

    .content .wrapper {
        picture {
            width: 0%;

            img { transform: scale(1.1); }
        }

        .m_credit { opacity: 0; }
    }
}
