/*** |- ---------------------------------------- -| ***/
/*** |- BOUTON BLANC --------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.btn.t_white {
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;

    span {
        background: #fff;

        &:before { background: $color3; }
        &:after { background: url('../images/content/icons/darkBluePointer.svg') no-repeat center / contain; }
    }

    &:hover span:after { background: url('../images/content/icons/whitePointer.svg') no-repeat center / contain; }
}


/*** |- ---------------------------------------- -| ***/
/*** |- LIEN BLANC ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.link.t_white {
    color: #fff;
    &:before { background: #fff; }
}


/*** |- ---------------------------------------- -| ***/
/*** |- TITRE DE PAGE ALIGNÉ AVEC LE HEADER ---- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_pageTitle.t_alignedWithHeader {
    .content {
        width: calc(100% - 400px);
        margin-left: auto;

        @media screen and (max-width: 1400px) { width: calc(100% - 300px); }
        @media screen and (max-width: 1300px) { width: calc(100% - 250px); }
        @media screen and (max-width: 1200px) { width: calc(100% - 200px); }
        @media screen and (max-width: 1024px) { width: calc(100% - 150px); }
        @media screen and (max-width: 850px)  { width: 100%; padding-right: 30px; padding-left: 30px; }
        @media screen and (max-width: 600px)  { padding-right: 25px; padding-left: 25px; }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- CATÉGORIE VERTE ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.m_category.t_green {
    background: $color2;
    border-color: $color2;
}
