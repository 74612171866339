footer {
    .content {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        .backtoTopWrapper {
            position: relative;
            width: 200px;

            @media screen and (max-width: 1024px) { width: 150px; }
            @media screen and (max-width: 850px)  { width: 120px; }
            @media screen and (max-width: 600px)  { display: none; }

            .backToTop {
                position: absolute;
                width: 90px;
                height: calc(90px + 160px);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                @media screen and (max-width: 1024px) { width: 70px; }
                @media screen and (max-width: 850px)  { width: 60px; }

                &:hover div:before {
                    transform: scale(1.1);
                }

                div {
                    position: relative;
                    width: 90px;
                    height: 90px;
                    margin: 0 auto;

                    @media screen and (max-width: 1024px) { width: 70px; height: 70px; }
                    @media screen and (max-width: 850px)  { width: 60px; height: 60px; }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: $color3;
                        border-radius: 50%;
                        transition: transform 300ms $easeOutCubic, background 600ms;
                    }

                    svg {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        top: 50%;
                        left: 50%;
                        opacity: 1;
                        fill: #fff;
                        stroke: #fff;
                        transform: translate(-50%, -50%) rotate(-90deg);
                        transition: opacity 600ms;
                    }
                }

                span {
                    display: block;
                    width: 158px;
                    height: 162px;
                    font: 500 rem(15)/90px $font1;
                    color: $color1;
                    text-transform: uppercase;
                    white-space: nowrap;
                    word-spacing: 6px;
                    transform: rotate(-90deg);

                    @media screen and (max-width: 1024px) { line-height: 70px; }
                    @media screen and (max-width: 850px)  { line-height: 60px; }
                }
            }
        }

        .wrapper {
            width: calc(100% - 200px);
            padding-right: 200px;
            padding-left: 200px;
            background: $color1;

            @media screen and (max-width: 1400px) { padding-right: 100px; padding-left: 100px; }
            @media screen and (max-width: 1200px) { padding-right: 90px; padding-left: 90px; }
            @media screen and (max-width: 1024px) { width: calc(100% - 150px); padding-right: 50px; padding-left: 50px; }
            @media screen and (max-width: 850px)  { width: calc(100% - 120px); }
            @media screen and (max-width: 768px)  { padding-right: 30px; padding-left: 30px; }
            @media screen and (max-width: 600px)  { width: 100%; }

            .infos {
                @media screen and (max-width: 768px) { width: 100%; }
            }

            .links {
                @media screen and (max-width: 768px) { display: none; }

                li {
                    &:not(:last-child) {
                        margin-bottom: 30px;

                        @media screen and (max-width: 1200px) { margin-bottom: 25px; }
                        @media screen and (max-width: 1024px) { margin-bottom: 20px; }
                    }

                    a {
                        font-family: $font1;
                        font-size: rem(35);
                        line-height: 1.3;
                        color: #fff;
                        transition: color 300ms;

                        @media screen and (max-width: 1200px) { font-size: rem(32); }
                        @media screen and (max-width: 1024px) { font-size: rem(29); }
                        @media screen and (max-width: 850px)  { font-size: rem(27); }
                        @media screen and (max-width: 768px)  { font-size: rem(25); }

                        &:hover { color: $color3; }
                    }
                }
            }

            h2 {
                margin-bottom: 5px;
                font: 500 rem(22)/1 $font1;
                color: #fff;
            }

            p {
                font-size: rem(20);
                color: #fff;

                @media screen and (max-width: 1024px) { font-size: rem(18); }
            }

            .tel {
                margin: 10px 0 20px;
                font-family: $font1;
                font-size: rem(35);
                line-height: 1.3;
                color: #fff;
                transition: color 300ms;

                @media screen and (max-width: 1200px) { font-size: rem(32); margin: 10px 0 15px; }
                @media screen and (max-width: 1024px) { font-size: rem(29); }
                @media screen and (max-width: 850px)  { font-size: rem(27); }
                @media screen and (max-width: 768px)  { font-size: rem(25); margin-bottom: 10px; }

                &:hover { color: $color3; }
            }

            form {
                margin-top: 50px;

                @media screen and (max-width: 1200px) { margin-top: 40px; }
                @media screen and (max-width: 1024px) { margin-top: 30px; }

                .title {
                    margin-bottom: 15px;
                }
            }

            .follow {
                margin-top: 30px;

                @media screen and (max-width: 1024px) { margin-top: 25px; }
                @media screen and (max-width: 768px)  { margin-top: 20px; }

                span { position: relative; top: 1px; }

                .facebook {
                    margin: 0 40px 0 15px;

                    @media screen and (max-width: 600px) { margin: 0 20px 0 10px; }

                    &:hover svg { fill: $color3; }

                    svg {
                        width: 24px;
                        height: 24px;
                        fill: #fff;
                        vertical-align: middle;
                        transition: fill 300ms;
                    }
                }
                img {
                    height: 24px;
                    vertical-align: middle;
                }
            }
        }

        .copyright {
            margin-top: 90px;

            @media screen and (max-width: 1024px) { margin-top: 70px; }
            @media screen and (max-width: 768px)  { margin-top: 50px; }
            @media screen and (max-width: 500px)  { margin-top: 30px; }

            .itemCopyright { @media screen and (max-width: 768px) { width: calc( 100% - 97px); } }
            .itemBlanko {
                @media screen and (max-width: 768px) { width: 97px; }
                @media screen and (max-width: 600px) { margin-top: 10px; }
            }

            .itemCopyright, .itemBlanko {
                @media screen and (max-width: 600px) { width: 100%; }
            }

            p, a {
                font: 300 rem(13)/1 $font1;
                color: rgba(#fff, 0.6);
            }

            a {
                display: block;
                transition: color 300ms;

                &:hover { color: #fff; }
            }
        }
    }
}
