/*** |- ---------------------------------------- -| ***/
/*** |- WHITE ---------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.slickNav.t_white {
    .slideNumber span { color: #fff; }
    .slick-dots li {
        &.slick-active button { background: #fff; }
        button { background: rgba(#fff, 0.2); }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- DARK BLUE ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.slickNav.t_darkBlue {
    .slideNumber span { color: $color1; }
    .slick-dots li {
        &.slick-active button { background: $color1; }
        button { background: rgba($color1, 0.2); }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- IS VERTICAL ---------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.slickNav.t_vertical {
    .slick-dots {
        width: 3px;
        height: 200px;
        bottom: 0;
        margin: 15px auto 0;

        li {
            display: block;
            width: auto;
            height: auto;
            margin: 0;
            padding: 5px 0;


            button {
                padding: 0;
                width: 100%;
                height: 100%;
                border-radius: 0;

                &:before { display: none; }
            }
        }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- IS HORIZONTAL -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.slickNav.t_horizontal {
    display: inline-flex;

    .slideNumber {
        width: 100px;
        text-align: left;

        @media screen and (max-width: 1024px) { width: 80px; }
    }
    .slick-dots {
        width: 270px;
        height: 4px;
        bottom: 0;
        margin: 16px 0 0 20px;

        @media screen and (max-width: 1024px) { margin-top: 14px; }
        @media screen and (max-width: 768px) { margin-top: 12px; margin-left: 0; width: 200px; }
        @media screen and (max-width: 500px) { width: 170px; }
        @media screen and (max-width: 375px) { width: 150px; }

        li {
            display: inline-block;
            width: auto;
            height: auto;
            padding: 0 10px;
            margin: 0;

            @media screen and (max-width: 768px) { padding: 0 5px; }
            @media screen and (max-width: 425px) { padding: 0 3px; }

            button {
                padding: 0;
                width: 100%;
                height: 4px;
                border-radius: 0;

                &:before { display: none; }
            }
        }
    }
}
