/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_rubricsList > li.open {
    background: $color5;

    .clickable .flex div p { color: $color1; }
    .clickable .icon {
        background: $color3;
        border-color: $color3;
        svg { stroke: #fff; transform: translate(-50%, -50%) rotate(-90deg); }
    }

    & > .drawer {
        opacity: 1;
        overflow: hidden;
        transform: translateY(0);
        transition: transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms, opacity 600ms linear 300ms;
    }
}
