.m_event {
    display: block;
    position: relative;
    padding-left: 40%;
    padding-bottom: 60px;
    border-bottom: 1px solid rgba($color1, 0.3);
    overflow: hidden;

    @media screen and (max-width: 1024px) { padding-bottom: 40px; padding-left: 45%; }
    @media screen and (max-width: 600px)  { padding: 0 25px 30px; border-width: 1px; }

    &:hover {
        picture img { transform: scale(1.05); }
        .text .subtitle .bigWord {
            color: $color2;
            -webkit-text-stroke-color: rgba(255, 255, 255, 0);
        }
    }

    picture {
        position: absolute;
        height: auto;
        top: 0;
        width: 40%;
        bottom: 60px;
        left: 0;
        overflow: hidden;

        @media screen and (max-width: 1200px) { bottom: 50px; }
        @media screen and (max-width: 1024px) { width: 45%; bottom: 40px; }
        @media screen and (max-width: 600px)  {
            position: relative;
            width: 100%;
            height: 400px;
            bottom: 0;
        }
        @media screen and (max-width: 500px) { height: 300px; }
        @media screen and (max-width: 375px) { height: 250px; }

        &[data-picture]:after { // Transition scrollfire
            transition-duration: 1000ms;
            transition-timing-function: $easeInOutQuint;
        }

        img {
            transition: transform 500ms $easeOutCubic;
        }
    }

    .text {
        padding: 120px;

        @media screen and (max-width: 1300px) { padding: 120px 100px; }
        @media screen and (max-width: 1200px) { padding: 120px 90px; }
        @media screen and (max-width: 1024px) { padding: 100px 50px; }
        @media screen and (max-width: 768px)  { padding: 100px 30px; }
        @media screen and (max-width: 600px)  { padding: 30px 0 0; }

        .subtitle {
            display: inline-block;
            position: relative;
            font: 500 rem(18)/1 $font1;
            color: $color1;
            z-index: 1;

            @media screen and (max-width: 768px) { font-size: rem(16); }

            .bigWord {
                position: absolute;
                left: 80%;
                bottom: -40px;
                font: 700 rem(205)/1 $font1;
                color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: rgba($color1, 0.4);
                transition: color 300ms, -webkit-text-stroke-color 300ms;
                z-index: -1;

                @media screen and (max-width: 1300px) { font-size: rem(180); bottom: -30px; }
                @media screen and (max-width: 1100px) { font-size: rem(160); bottom: -25px; }
                @media screen and (max-width: 1024px) { font-size: rem(140); bottom: -20px; }
                @media screen and (max-width: 850px)  { font-size: rem(120); bottom: -15px; }
                @media screen and (max-width: 700px)  { font-size: rem(100); bottom: -10px; left: 70%; }
                @media screen and (max-width: 600px)  { display: none; }
            }
        }

        h2 {
            margin: 30px 0 60px;
            font-family: $font1;
            line-height: 1.1;
            color: $color1;
            @include responsiveFontSize(40, 28);

            @media screen and (max-width: 1200px) { margin-bottom: 50px; }
            @media screen and (max-width: 1024px) { margin: 25px 0 40px; }
            @media screen and (max-width: 768px)  { margin: 15px 0 30px; }
            @media screen and (max-width: 600px)  { margin: 10px 0 20px; }
        }

        .info {
            font: 500 rem(18)/1 $font1;
            color: $color3;
            text-transform: uppercase;
            margin-top: 10px;

            @media screen and (max-width: 768px) { font-size: rem(16); }
        }

        h2 + .info{
            margin-top: 0;
        }
    }
}
