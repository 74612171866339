.m_btnFastLinks {
    position: fixed;
    width: 120px;
    padding-bottom: 30px;
    bottom: 25px;
    left: 40px;
    opacity: 1;
    z-index: 10;
    transition: opacity 225ms, padding 225ms $easeOutCubic;

    @media screen and (max-width: 1024px), screen and (max-height: 750px) { width: 90px; padding-bottom: 0; left: 30px; }
    @media screen and (max-width: 850px), screen and (max-height: 599px)  { width: 60px; }
    @media screen and (max-width: 600px)  { width: 50px; bottom: 20px; left: 25px; }
    @media screen and (max-width: 500px)  { right: 25px; left: auto; }

    &:hover div:before { transform: scale(1.1); }

    div {
        position: relative;
        width: 90px;
        height: 90px;
        margin: 0 auto;

        @media screen and (max-width: 850px), screen and (max-height: 599px) { width: 60px; height: 60px; }
        @media screen and (max-width: 600px) { width: 50px; height: 50px; }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $color3;
            border-radius: 50%;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            transition: transform 300ms $easeOutCubic, background 600ms;
        }

        .btnX {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            opacity: 0;
            transform: translate(-50%, -50%);
            transition: opacity 600ms;

            &:before, &:after {
                content: '';
                position: absolute;
                width: 26px;
                height: 2px;
                top: 50%;
                left: 50%;
                background: $color3;

                @media screen and (max-width: 850px), screen and (max-height: 599px) { width: 22px; }
            }
            &:before { transform: translate(-50%, -50%) rotate(45deg); }
            &:after { transform: translate(-50%, -50%) rotate(-45deg); }
        }

        svg {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 50%;
            left: 50%;
            fill: #fff;
            stroke: #fff;
            opacity: 1;
            transform: translate(-50%, -50%);
            transition: opacity 600ms;

            @media screen and (max-width: 600px) { width: 20px; height: 20px; }
        }
    }

    > span {
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        left: 0;
        white-space: nowrap;
        font: 500 rem(15)/1 $font2;
        text-transform: uppercase;
        text-align: center;
        transition: opacity 600ms;

        @media screen and (max-width: 1024px), screen and (max-height: 750px) { display: none; }

        &.state1 { color: $color1; opacity: 1; }
        &.state2 { color: #fff; opacity: 0; }
    }
}
