/*** Grid ***/
.grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    flex-flow: row wrap;
    width: 100%;

    &.gaps {
        width: calc(100% + #{$gaps} + #{$gaps});
        margin-left: -$gaps;
        margin-right: -$gaps;

        .column {
            padding-right: $gaps;
            padding-left: $gaps;
        }
    }
}

// Création des colonnes de base
@for $i from 1 through $colnb {
	.col#{$i} { width: ($i / $colnb * 100%); }
}

// Media query 1024
@media screen and (max-width: 1024px) {
	@for $i from 1 through $colnb {
		.col#{$i}_1024 { width: ($i / $colnb * 100%); }
	}
}

// Media query 768
@media screen and (max-width: 768px) {
	@for $i from 1 through $colnb {
		.col#{$i}_768 { width: ($i / $colnb * 100%); }
	}
}

// Media query 425
@media screen and (max-width: 425px) {
	@for $i from 1 through $colnb {
		.col#{$i}_425 { width: ($i / $colnb * 100%); }
	}
}

// Media query 375
@media screen and (max-width: 375px) {
	@for $i from 1 through $colnb {
		.col#{$i}_375 { width: ($i / $colnb * 100%); }
	}
}

// Media query 320
@media screen and (max-width: 320px) {
	@for $i from 1 through $colnb {
		.col#{$i}_320 { width: ($i / $colnb * 100%); }
	}
}

// Média query de 500 à 1800
$k: 500;
@while $k <= 1800 {
    $pixel: $k + px;
    @media screen and (max-width: $pixel) {
        @for $i from 1 through $colnb {
            .col#{$i}_#{$k} { width: ($i / $colnb * 100%); }
        }
    }
    $k: $k + 100;
}
