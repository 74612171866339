/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayDropdown {
    #overlayDropdown {
        visibility: visible;
        pointer-events: all;
        z-index: 11;

        .background { opacity: 1; visibility: visible; }

        .closeDropdown {
            opacity: 1;
            transition: opacity 300ms linear 400ms;

            &:before {
                right: 0%;
                left: 0%;
                transition: right 800ms $easeInOutCubic 600ms, left 800ms $easeInOutCubic 600ms;
            }
        }

        .wrapper {
            bottom: 0%;
            transition: bottom 800ms $easeOutQuart;

            @media screen and (max-width: 1024px) and (max-height: 1366px) and (min-height: 1000px) { bottom: 40%; }

            .section {
                transition: opacity 500ms, transform 500ms $easeInCubic, visibility 0ms ease 800ms;

                h2 {
                    opacity: 1;
                    transform: none;
                    transition: opacity 800ms $easeOutQuart 300ms, transform 800ms $easeOutQuart 300ms;
                }

                .grid > div {
                    opacity: 1;
                    transform: none;
                    transition: opacity 800ms $easeOutQuart, transform 800ms $easeOutQuart;

                    &:nth-child(1) { transition-delay: 300ms; }
                    &:nth-child(2) { transition-delay: 350ms; }
                    &:nth-child(3) { transition-delay: 400ms; }
                    &:nth-child(4) { transition-delay: 450ms; }
                    &:nth-child(5) { transition-delay: 500ms; }
                    &:nth-child(6) { transition-delay: 550ms; }
                }
            }
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- ACTIVE SECTION ------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayDropdown {
    &[data-section="municipalite"] {
        header .content .itemMain nav div:first-child:before { height: 5px; }
        #overlayDropdown [data-section="municipalite"] {
            opacity: 1;
            visibility: visible;
            transform: none;
            transition: opacity 500ms, transform 500ms $easeOutCubic;
        }
    }
    &[data-section="services"] {
        header .content .itemMain nav div:nth-child(2):before { height: 5px; }
        #overlayDropdown [data-section="services"] {
            opacity: 1;
            visibility: visible;
            transform: none;
            transition: opacity 500ms, transform 500ms $easeOutCubic;
        }
    }
    &[data-section="loisirs"] {
        header .content .itemMain nav div:last-child:before { height: 5px; }
        #overlayDropdown .wrapper [data-section="loisirs"] {
            opacity: 1;
            visibility: visible;
            transform: none;
            transition: opacity 500ms, transform 500ms $easeOutCubic;
        }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- IS OPENING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayDropdown.openingOverlayDropdown header {
    .itemLogo, form {
        pointer-events: none; // Empêcher de refermer le dropdown immédiatement
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayDropdown.closingOverlayDropdown {
    #overlayDropdown {
        pointer-events: none;

        .background { opacity: 0; }
        .wrapper {
            bottom: 100%;
        }
        .btnCloseDropdown {
            opacity: 0;
            transition: opacity 300ms;
        }
    }
}
