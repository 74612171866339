#overlayMenu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	overflow: hidden;
    visibility: hidden;
    pointer-events: none;
    z-index: 10;

    .wrapper {
        position: absolute;
        width: 100%;
        top: 0;
        right: -100%;
        bottom: 0;
		background: #fff;
		overflow: hidden;
        z-index: 99;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 110px;
            background: linear-gradient(360deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 30%, rgba(#fff, 1) 100%);
            z-index: 1;
        }

        .linksContainer {
            position: absolute;
            width: 100%;
            padding: 110px 0;
            top: 0;
            left: 0;
            bottom: 0;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: none;
            opacity: 0;
            transform: translateX(-100px);

            &::-webkit-scrollbar { display: none; }

            > div:not(:first-child) { margin-top: 10px; }
        }
    }

    h2 {
        padding: 15px 60px;
        font-family: $font1;
        line-height: 1.3;
        color: $color1;
        @include responsiveFontSize(40, 26, 850, 320);

        @media screen and (max-width: 768px) { padding: 10px 30px; }
        @media screen and (max-width: 600px) { padding: 10px 25px; }
    }

    .groundFloor {
        margin-bottom: 30px;

        @media screen and (max-width: 768px) { margin-bottom: 20px; }
        @media screen and (max-width: 600px) { margin-bottom: 10px; }

        > li {
            overflow: hidden;
            border-bottom: 1px solid rgba($color1, 0);
            transition: border 300ms, margin 300ms;

            > a {
                position: relative;
                width: 100%;
                max-width: 600px;
                padding: 20px 80px 10px 60px;
                font: 500 rem(18)/1.1 $font2;
                color: $color1;
                text-transform: uppercase;
                overflow: hidden;
                transition: color 300ms;

                @media screen and (max-width: 768px) { padding: 20px 55px 5px 30px; font-size: rem(16); }
                @media screen and (max-width: 600px) { padding: 20px 50px 5px 25px; font-size: rem(15); }

                span {
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    top: 20px;
                    right: 60px;

                    @media screen and (max-width: 768px) { right: 30px; }
                    @media screen and (max-width: 600px) { right: 25px; }

                    &:before, &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: $color1;
                    }

                    &:before { width: 2px; height: 14px; transition: height 300ms $easeOutCubic; }
                    &:after { width: 14px; height: 2px; }
                }
            }

            .btnSubLvl1 { position: relative; }
        }
    }

    .other {
        margin-top: 30px;

        a {
            position: relative;
            padding: 5px 60px;
            transition: opacity 300ms, padding 300ms;
            @include paragraph();

            @media screen and (max-width: 768px) { padding: 5px 30px; }
            @media screen and (max-width: 600px) { padding: 5px 25px; }
        }
    }

    .subLvl1 {
        display: none;
        opacity: 0;
        overflow: hidden;
        transform: translateY(30px);
        transition: opacity 300ms, transform 300ms $easeIn;

        li {
            &:first-child a { padding-top: 10px; }
            &:last-child a  { padding-bottom: 20px; }

            a {
                padding: 5px 60px;
                font: rem(18)/1.5 $font1;
                color: $color1;
                transition: color 300ms;

                @media screen and (max-width: 768px) { padding: 5px 30px; }
                @media screen and (max-width: 600px) { padding: 5px 25px; }

                &:hover { color: $color1; }
            }
        }
    }
}


