form {
    .title {
        margin-bottom: 30px;
        font-family: $font1;
        font-weight: 500;
        line-height: 1.3;
        color: $color1;
        @include fontsize(24, 0, 0, 22, 20, 19, 18);

        @media screen and (max-width: 1024px) { margin-bottom: 20px; }
        @media screen and (max-width: 768px)  { margin-bottom: 15px; }
    }

    .errorMessage {
        //display: none;
        margin-top: 5px;
        font-family: $font1;
        line-height: 1.5;
        color: $color6;
        @include fontsize(17, 0, 0, 16, 0, 0, 0);
    }

    .note {
        margin-top: 5px;
        font-family: $font1;
        line-height: 1.5;
        color: $color4;
        @include fontsize(17, 0, 0, 16, 0, 0, 0);
    }

    label {
        display: block;
        padding-bottom: 5px;
        font: rem(17)/1.5 $font1;
        color: $color1;

        @media screen and (max-width: 768px) { font-size: rem(16); }
    }
}


.inputField, .textField, .checkboxesWrapper, .radioField, .selectField, .fileField, .g-recaptcha {
    position: relative;
    margin-bottom: 50px;

    @media screen and (max-width: 1024px) { margin-bottom: 40px; }
    @media screen and (max-width: 768px)  { margin-bottom: 30px; }

    &.error {
        input {
            color: $color6;
            border-color: $color6;
        }
        .errorMessage {
            display: block;
        }
    }
}

.form-group{
    &:last-child{
        margin-bottom: 0;
    }
}

.alert.alert-success{
    display: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 60px white inset !important;
}

header,
footer{
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 60px $color1 inset !important;
        -webkit-text-fill-color: white;
    }
}