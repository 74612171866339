.m_news {
    position: relative;
    width: 440px;
    height: 575px;
    padding: 30px 45px;
    max-width: 440px;

    @media screen and (max-width: 1300px) { width: 400px; height: 525px; padding: 30px 35px; }
    @media screen and (max-width: 1100px) { width: 360px; height: 480px; padding: 30px 25px; }
    @media screen and (max-width: 1024px) { width: 340px; height: 460px; padding: 30px 20px; }
    @media screen and (max-width: 768px)  { width: 320px; height: 440px; }
    @media screen and (max-width: 600px)  { width: 300px; height: 420px; padding: 20px; max-width: 350px; }
    @media screen and (max-width: 425px)  { width: 280px; height: 400px; }
    @media screen and (max-width: 375px)  { width: 260px; height: 380px; }

    &:hover picture img { transform: scale(1.05); }

    picture {
        overflow: hidden;
        will-change: transform;

        img { transition: transform 500ms $easeOutCubic; }
    }

    h2 {
        font-family: $font1;
        line-height: 1;
    }
}
