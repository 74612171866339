/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayShare {
    #overlayShare {
        visibility: visible;

        .background {
            opacity: 1;
            visibility: visible;
        }

        .wrapper {
            opacity: 1;
            pointer-events: all;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 600px) { transform: translateY(-50%); }
        }
    }
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayShare.closingOverlayShare {
    #overlayShare {
        .background { opacity: 0; }
        .wrapper {
            opacity: 0;
            transform: translate(-50%, -25%);

            @media screen and (max-width: 600px) { transform: translateY(-25%); }
        }
    }
}
