.l_contact {
    .content {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }

    .side {
        width: 400px;
        order: 2;
        background: $color3;

        @media screen and (max-width: 1400px) { width: 380px; }
        @media screen and (max-width: 1200px) { width: 350px; }
        @media screen and (max-width: 1024px) { width: 320px; }
        @media screen and (max-width: 900px)  { width: 100%; max-width: calc(100% - 120px); margin-left: auto; order: 1; }
        @media screen and (max-width: 600px)  { max-width: calc(100% - 100px); }
        @media screen and (max-width: 500px)  { max-width: 100%; }

        .sideWrapper {
            position: sticky;
            top: 0;

            .infoBox {
                padding: 70px 45px 0;

                @media screen and (max-width: 1400px) { padding: 60px 35px 0; }
                @media screen and (max-width: 1200px) { padding: 50px 25px 0; }
                @media screen and (max-width: 1024px) { padding: 40px 25px 0; }

                &:last-child {
                    padding-bottom: 70px;

                    @media screen and (max-width: 1400px) { padding-bottom: 60px; }
                    @media screen and (max-width: 1200px) { padding-bottom: 50px; }
                    @media screen and (max-width: 1024px) { padding-bottom: 40px; }
                }

                h2, p, a {
                    color: #fff;
                }
                p {
                    margin-top: 5px;
                }
                .tel {
                    margin-top: 30px;
                    font-family: $font1;
                    line-height: 1.1;
                    @include responsiveFontSize(30, 24);
                    transition: color 300ms;

                    &:hover { color: $color1; }
                }
            }
        }
    }
    .form {
        width: calc(100% - 400px);
        order: 1;
        padding: 70px 200px 0 250px;

        @media screen and (max-width: 1600px) { padding: 70px 150px 0 200px; }
        @media screen and (max-width: 1400px) { padding: 60px 100px 0 200px; width: calc(100% - 380px);  }
        @media screen and (max-width: 1200px) { width: calc(100% - 350px); }
        @media screen and (max-width: 1024px) { padding: 50px 50px  0 150px; width: calc(100% - 320px); }
        @media screen and (max-width: 900px)  { width: 100%; order: 2; }
        @media screen and (max-width: 850px)  { padding: 50px 50px  0 120px; }
        @media screen and (max-width: 768px)  { padding: 50px 30px  0 120px; }
        @media screen and (max-width: 600px)  { padding: 50px 25px  0 100px; }
        @media screen and (max-width: 500px)  { padding: 50px 25px  0; }

        h2 {
            margin-bottom: 30px;
        }

        .textRight { text-align: right; }
    }
}
