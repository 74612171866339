header {
    position: relative;
    z-index: 12;

    .content {
        .grid {
            height: 130px;

            @media screen and (max-width: 1200px) { flex-flow: inherit; }
            @media screen and (max-width: 1024px), screen and (max-height: 750px) { height: 100px; }
            @media screen and (max-width: 850px),  screen and (max-height: 599px) { height: 80px; }

            .itemLogo {
                display: flex;
                width: 400px;
                align-items: center;

                @media screen and (max-width: 1400px) { width: 300px; }
                @media screen and (max-width: 1300px) { width: 250px; }
                @media screen and (max-width: 1200px) { width: 200px; }
                @media screen and (max-width: 1024px), screen and (max-height: 750px) { width: 150px; }
                @media screen and (max-width: 850px),  screen and (max-height: 599px) { width: 120px; }

                a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    padding: 0 35px;

                    @media screen and (max-width: 1024px), screen and (max-height: 750px) { padding: 0 25px; }
                    @media screen and (max-width: 850px),  screen and (max-height: 599px) { padding: 0 15px; }

                    picture {
                        width: 130px;

                        @media screen and (max-width: 1300px) { width: 115px; }
                        @media screen and (max-width: 1200px), screen and (max-height: 750px) { width: 100%; }
                    }
                }
            }

            .itemMain {
                display: flex;
                width: calc(100% - 400px);
                padding-right: 50px;
                margin-left: auto;
                align-items: center;
                background: $color1;

                @media screen and (max-width: 1400px) { width: calc(100% - 300px); }
                @media screen and (max-width: 1300px) { width: calc(100% - 250px); padding-right: 35px; }
                @media screen and (max-width: 1200px) { width: calc(100% - 200px); }
                @media screen and (max-width: 1024px) { width: calc(100% - 150px); padding-right: 30px; }
                @media screen and (max-width: 850px)  { width: calc(100% - 120px); padding-right: 30px; }
                @media screen and (max-width: 768px)  { padding-right: 25px; }

                nav {
                    display: flex;
                    position: relative;
                    height: 100%;
                    padding: 0 75px;

                    @media screen and (max-width: 1600px) { padding: 0 60px; }
                    @media screen and (max-width: 1400px) { padding: 0 50px; }
                    @media screen and (max-width: 1200px) { padding: 0 40px; }
                    @media screen and (max-width: 1100px) { padding: 0 35px; }
                    @media screen and (max-width: 850px), screen and (max-height: 599px)  { display: none; }

                    div {
                        display: flex;
                        align-items: center;
                        position: relative;
                        height: 100%;
                        font: rem(23)/1 $font1;
                        color: #fff;
                        white-space: nowrap;

                        @media screen and (max-width: 1200px) { font-size: rem(21); }

                        &:before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            top: 0;
                            left: 0;
                            height: 0px;
                            background: $color2;
                            transition: height 300ms;
                        }

                        a {display: block; }

                        &:not(:first-child) {
                            margin-left: 50px;

                            @media screen and (max-width: 1600px) { margin-left: 40px; }
                            @media screen and (max-width: 1400px) { margin-left: 35px; }
                            @media screen and (max-width: 1200px) { margin-left: 25px; }
                        }
                    }
                }

                form {
                    width: 100%;

                    @media screen and (max-width: 1024px), screen and (max-height: 599px) { display: none; }
                }

                .mobile {
                    display: flex;
                    align-items: center;
                    margin-left: auto;

                    .btnMenu {
                        display: none;
                        position: relative;
                        margin-right: 30px;
                        font: 300 rem(12)/1 $font1;
                        color: #fff;
                        text-transform: uppercase;

                        @media screen and (max-width: 850px), screen and (max-height: 599px) { display: inline-flex; }

                        > span {
                            position: relative;
                            margin-right: 10px;
                            top: 3px;
                        }
                    }

                    .btnSearch {
                        display: none;
                        position: relative;

                        @media screen and (max-width: 1024px), screen and (max-height: 599px) { display: inline-flex; }

                        svg {
                            width: 22px;
                            height: 22px;
                            stroke: #fff;

                            //@media screen and (max-width: 850px), screen and (max-height: 599px) { width: 20px; height: 20px; }
                        }
                        .btnX {
                            @include create_x(20px, 2px, 20px, #fff);
                            position: absolute;
                            top: 50%;
                            right: auto;
                            left: 50%;
                            opacity: 0;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    }
}
