/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_rubricsList.t_normal {
    & > li {
        .clickable {
            padding: 50px 120px 50px 45px;

            @media screen and (max-width: 1200px) { padding: 40px 120px 40px 45px; }
            @media screen and (max-width: 1024px) { padding: 35px 100px 35px 35px; }
            @media screen and (max-width: 768px)  { padding: 30px  90px 30px 25px; }
            @media screen and (max-width: 600px)  { padding: 25px  65px 25px 15px; }
        }

        .drawer {
            padding-right: 45px;
            padding-left: 45px;

            @media screen and (max-width: 1024px) { padding-right: 35px; padding-left: 35px; }
            @media screen and (max-width: 768px)  { padding-right: 25px; padding-left: 25px; }
            @media screen and (max-width: 600px)  { padding-right: 15px; padding-left: 15px; }
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- PLUS ----------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_rubricsList.t_plus {
    & > li {
        .clickable {
            padding: 20px 120px 20px 30px;

            @media screen and (max-width: 1024px) { padding: 20px 100px 20px 20px; }
            @media screen and (max-width: 768px)  { padding: 15px  90px 15px 20px; }
            @media screen and (max-width: 600px)  { padding: 25px  65px 25px 15px; }

            .flex {
                min-height: 105px;

                picture {
                    width: 150px;
                    height: 150px;
                    margin-right: 30px;
                    border-radius: 50%;
                    overflow: hidden;

                    @media screen and (max-width: 1200px) { width: 125px; height: 125px; }
                    @media screen and (max-width: 1024px) { width: 100px; height: 100px; margin-right: 25px; }
                    @media screen and (max-width: 768px)  { width: 75px;  height: 75px; margin-right: 20px; }
                    @media screen and (max-width: 600px)  { display: none; }
                }

                div {
                    width: calc(100% - 180px);

                    @media screen and (max-width: 1200px) { width: calc(100% - 155px); }
                    @media screen and (max-width: 1024px) { width: calc(100% - 125px); }
                    @media screen and (max-width: 768px)  { width: calc(100% - 95px);  }
                    @media screen and (max-width: 600px)  { width: 100%; }

                    h6 { margin-bottom: 5px; }
                }
            }
        }

        .drawer {
            padding-right: 45px;
            padding-left: 30px;

            @media screen and (max-width: 1024px) { padding-left: 20px; padding-right: 35px; }
            @media screen and (max-width: 768px)  { padding-left: 25px; }
            @media screen and (max-width: 600px)  { padding-right: 15px; padding-left: 15px; }

            .dynamic {
                margin-top: 20px;

                @media screen and (max-width: 600px) { margin-top: 0; }
            }
        }
    }
}
