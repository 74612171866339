.l_component {
    a,
    span.noLink{
        display: block;
        position: relative;
        padding: 100px;
        margin-top: 110px;

        @media screen and (max-width: 1100px) { padding: 75px; margin-top: 95px; }
        @media screen and (max-width: 850px)  { margin-top: 70px; }
        @media screen and (max-width: 768px)  { padding: 60px 50px; }
        @media screen and (max-width: 700px)  { margin-top: 60px; }
        @media screen and (max-width: 600px)  { padding: 60px 30px; margin-top: 50px; }
        @media screen and (max-width: 500px)  { padding: 50px 25px; margin-top: 40px; }

        &:before {
            content: '';
            position: absolute;
            top: 15px;
            right: 15px;
            bottom: 0;
            left: 0;
            background: $color2;

            @media screen and (max-width: 768px) { top: 10px; right: 10px; }
            @media screen and (max-width: 600px) { display: none; }
        }

        &:hover {
            picture img { transform: scale(1.05); }
            .bigWord {
                color: $color2;
                -webkit-text-stroke-color: rgba(255, 255, 255, 0);
            }
            .btn span:before { right: 0%; }
            .btn span:after  { background: url('../images/content/icons/whitePointer.svg') no-repeat center / contain; }
        }

        .bigWord {
            position: absolute;
            top: -50px;
            left: 50%;
            font: 700 rem(205)/1 $font1;
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: rgba($color1, 0.4);
            transition: color 300ms, -webkit-text-stroke-color 300ms;
            transform: translate(-50%, -50%);
            z-index: -1;

            @media screen and (max-width: 1300px) { font-size: rem(180); }
            @media screen and (max-width: 1100px) { font-size: rem(160); top: -45px; }
            @media screen and (max-width: 1024px) { font-size: rem(140); top: -40px; }
            @media screen and (max-width: 850px)  { font-size: rem(120); top: -35px; }
            @media screen and (max-width: 700px)  { font-size: rem(100); top: -30px; }
            @media screen and (max-width: 600px)  { font-size: rem(80);  top: -25px; }
            @media screen and (max-width: 500px)  { font-size: rem(60);  top: -20px; }
            @media screen and (max-width: 400px)  { font-size: rem(50);  top: -15px; }
        }

        picture {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 15px;
            left: 15px;
            overflow: hidden;

            @media screen and (max-width: 768px) { bottom: 10px; left: 10px; }
            @media screen and (max-width: 600px) { bottom: 0; left: 0; }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba($color1, 0.7);
                z-index: 1;
            }

            &[data-picture]:after {
                transition-duration: 1500ms;
                transition-timing-function: $easeInOutQuint;
            }

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transition: transform 500ms $easeOutCubic;
            }
        }

        .text {
            position: relative;
            z-index: 1;

            h2, p {
                color: #fff;
            }

            h2 {
                font-family: $font1;
                font-weight: 500;
                @include responsiveFontSize(45, 26);
            }

            p {
                margin-top: 10px;
            }

            .btn {
                margin-top: 50px;

                @media screen and (max-width: 1024px) { margin-top: 40px; }
                @media screen and (max-width: 768px)  { margin-top: 30px; }
            }
        }
    }
}
