/*** |- ---------------------------------------- -| ***/
/*** |- DRAWERS IS OPEN ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
#overlayMenu .wrapper .linksContainer .groundFloor > li.open {
    border-bottom: 1px solid rgba($color1, 0.2);

    > a {
        color: $color3;

        span {
            &:before { height: 0px; }
            &:before, &:after { background: $color3; }
        }
    }

    .subLvl1 {
        opacity: 1;
        transform: none;
        transition: opacity 300ms, transform 300ms;
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- MENU IS OPEN --------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayMenu {
    #overlayMenu {
        visibility: visible;
        z-index: 11;
        pointer-events: all;

        .background {
            opacity: 1;
            visibility: visible;
        }

        .wrapper {
            right: 0%;
            transition: right 800ms $easeOutQuart;
            overflow-y: auto;

            .linksContainer {
                opacity: 1;
                transform: none;
                transition: opacity 600ms ease 300ms, transform 600ms $easeOutCubic 300ms;
            }
        }
    }
}

/*** |- ---------------------------------------- -| ***/
/*** |- MENU IS CLOSING ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayMenu.closingOverlayMenu {
    .hamburger span {
        width: 100%;
        opacity: 1;
        left: 0%;
        transform: rotate(0deg);
        transition: 250ms;

        &:nth-child(1) { top: 0px; }
        &:nth-child(2), &:nth-child(3) { top: calc(50% - 0.5px); width: 80%; }
        &:nth-child(4) { bottom: 0px; }
    }
    #overlayMenu {
        pointer-events: none;

        .background { opacity: 0; }
        .wrapper {
            right: -100%;
            transition: right 800ms $easeOutQuart;

            .linksContainer {
                opacity: 0;
                transition: opacity 300ms;
            }
        }
    }
}

