/*** |- ---------------------------------------- -| ***/
/*** |- NO TEXT -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_btnFastLinks.noText {
    padding-bottom: 0px;

    > span.state1, > span.state2 {
        opacity: 0;
        visibility: hidden;
        transition: opacity 225ms $easeOutCubic, visibility 0ms ease 225ms;
    }
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS HIDDEN ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.m_btnFastLinks.isHidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 225ms, visibility 0ms ease 225ms;
}


/*** |- ---------------------------------------- -| ***/
/*** |- IS ACTIVE ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayFastLinks .m_btnFastLinks { z-index: 14; }
.showOverlayFastLinks:not(.closingOverlayFastLinks) {
    .m_btnFastLinks {
        &.isHidden {
            opacity: 1;
            visibility: visible;
            transition: opacity 225ms;
        }
        div {
            &:before { background: #fff; }
            .btnX { opacity: 1; }
            svg  { opacity: 0; }
        }
        > span {
            &.state1 { opacity: 0; }
            &.state2 { opacity: 1; }
        }
    }
}
