.slickNav {
    .slideNumber {
        font-family: $font1;
        font-weight: 300;
        font-size: rem(16);
        text-align: center;

        .current {
            font-size: rem(52);

            @media screen and (max-width: 1024px) { font-size: rem(45); }
            @media screen and (max-width: 768px)  { font-size: rem(40); }
        }
        .total {
            position: relative;
            top: -20px;

            @media screen and (max-width: 1024px) { top: -18px; }
            @media screen and (max-width: 768px)  { top: -15px; }

            &:before { content: '/ '; }
        }
    }

    .slick-dots {
        position: relative;

        li button:before { display: none; }
    }
}
