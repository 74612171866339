.l_pageTitle {
    position: relative;

    .content {
        padding-top: 40px;

        @media screen and (max-width: 500px)  { padding-top: 25px; }
        @media screen and (max-width: 425px)  { padding-top: 10px; }

        picture {
            position: relative;
            height: 450px;
            margin-top: 30px;
            margin-bottom: 30px;

            @media screen and (max-width: 1200px) { height: 400px; }
            @media screen and (max-width: 1024px) { height: 350px; }
            @media screen and (max-width: 768px)  { height: 300px; margin-top: 20px; }
            @media screen and (max-width: 600px)  { height: 250px; }
            @media screen and (max-width: 425px)  { height: 200px; }

            &[data-scrollfire="fire"] .m_credit { opacity: 1; }

            .m_credit {
                position: absolute;
                top: 0;
                right: 0;
                opacity: 0;
                z-index: 1;
                transition: opacity 300ms linear 1900ms;
            }
        }

        h1 {
            span {
                display: block;
                color: $color3;
            }
        }

        .message { // 404 et 500
            margin-top: 20px;
        }

        .btn { // 404 et 500
            margin-top: 50px;

            @media screen and (max-width: 1024px) { margin-top: 40px; }
            @media screen and (max-width: 768px)  { margin-top: 30px; }
        }

        .m_category { // news
            margin-bottom: 20px;
        }

        .date { // news
            margin-top: 30px;
            font: 500 rem(18)/1 $font1;
            color: $color3;
        }

        .subtitle { // event
            margin-bottom: 20px;
            display: inline-block;
            position: relative;
            font: 500 rem(18)/1 $font1;
            color: $color1;
            z-index: 1;

            @media screen and (max-width: 768px) { font-size: rem(16); }
        }

        .info { // event
            margin-top: 10px;
            font: 500 rem(18)/1 $font1;
            color: $color3;
            text-transform: uppercase;

            @media screen and (max-width: 768px) { margin-top: 20px; font-size: rem(16); }

            //&:first-child { margin-top: 30px; @media screen and (max-width: 600px) { margin-top: 5px; }}
        }

        h1.hugeTitle + .info{
            margin-top: 30px;
        }
    }
}
