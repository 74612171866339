.searchField {
    display: flex;
    position: relative;

    input {
        width: 100%;
        padding: 30px 80px 30px 25px;
        font: rem(23)/1 $font1;
        border: 1px solid;
        background: transparent;

        @media screen and (max-width: 1200px) { padding: 25px 60px 25px 20px; font-size: rem(21); }
        @media screen and (max-height: 750px) { padding: 20px 60px 20px 20px; }
    }

    a {
        transition: opacity 300ms;

        &:hover { opacity: 0.7; }

        svg {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
        }
    }

    .btnX {
        @include create_x(18px, 2px);
        position: absolute;
        top: 50%;
        right: 55px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-50%);
        transition: opacity 300ms, visibility 0ms ease 300ms;
    }

    &:not([data-inputvalue=""]) {
        .btnX {
            opacity: 1;
            visibility: visible;
            transition: opacity 300ms;
        }
    }
}
