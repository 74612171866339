/*** |- ---------------------------------------- -| ***/
/*** |- COMMON STYLES -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
@mixin largeTitle() {
    font-family: $font1;
    font-weight: 500;
    line-height: 1;
    color: $color3;
    @include responsiveFontSize(55, 30);
}

@mixin mediumTitle() {
    font-family: $font1;
    font-weight: 500;
    line-height: 1.1;
    color: $color1;
    @include responsiveFontSize(30, 24);
}

@mixin smallTitle() {
    font-family: $font1;
    font-weight: 500;
    line-height: 1.5;
    color: $color1;
    @include responsiveFontSize(24, 20);
}

@mixin paragraph() {
    font-family: $font1;
    line-height: 1.5;
    color: $color1;
    @include fontsize(21, 0, 0, 18, 0, 0, 0);
}



/*** |- ---------------------------------------- -| ***/
/*** |- CREATE SHAPES -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
@mixin create_x($width: 18px, $height: 2px, $container: 20px, $color: none) {
    position: relative;
    width: $width;
    height: $width;
    cursor: pointer;

    &:before, &:after {
        content: '';
        position: absolute;
        width: $width;
        height: $height;
        top: 50%;
        left: 50%;
        @if $color != none {
            background: $color;
        }
        transition: background 300ms;
    }
    &:before { transform: translate(-50%, -50%) rotate(45deg); }
    &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
}

@mixin create_triangle($width, $height, $color, $direction) {
    display: inline-block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;

    @if $direction == up {
        border-width: 0 ($width / 2) $height ($width / 2);
        border-bottom-color: $color;
    } @else if $direction == right {
        border-width: ($height / 2) 0 ($height / 2) $width;
        border-left-color: $color;
    } @else if $direction == down {
        border-width: $height ($width / 2) 0 ($width / 2);
        border-top-color: $color;
    } @else if $direction == left {
        border-width: ($height / 2) $width ($height / 2) 0;
        border-right-color: $color;
    }
}
/*** |- ---------------------------------------- -| ***/
/*** |- MIXINS --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
// Ajoute un margin top sur tout les éléments de la deuxième rangé et plus
@mixin setMarginTop($value, $nbCol) {
	&:nth-child(-n + #{$nbCol}) { margin-top: 0; }
	&:not(:nth-child(-n + #{$nbCol})) { margin-top: $value; }
}

// Gestion du responsive des font-size
@mixin fontsize($base, $fs1440: 0, $fs1024: 0, $fs768: 0, $fs425: 0, $fs375: 0, $fs320: 0) {
	font-size: rem($base);

	@if $fs1440 != 0 {
		@media screen and (max-width: 1440px) { font-size: rem($fs1440); }
	}

	@if $fs1024 != 0 {
		@media screen and (max-width: 1024px) { font-size: rem($fs1024); }
	}

	@if $fs768 != 0 {
		@media screen and (max-width: 768px)  { font-size: rem($fs768);  }
	}

	@if $fs425 != 0 {
		@media screen and (max-width: 425px)  { font-size: rem($fs425);  }
	}

	@if $fs375 != 0 {
		@media screen and (max-width: 375px)  { font-size: rem($fs375);  }
	}

	@if $fs320 != 0 {
		@media screen and (max-width: 320px)  { font-size: rem($fs320);  }
	}
}



@mixin responsiveFontSize($maxSize, $minSize, $maxViewport: 1440, $minViewport: 320) {
    $maxViewportPx: $maxViewport + px;
    $minViewportPx: $minViewport + px;

    $minSizeRem:     ($minSize     / $browser-context) + rem;
    $minViewportRem: ($minViewport / $browser-context) + rem;

    @media screen and (min-width: $maxViewportPx) {
        font-size: rem($maxSize);
    }

    @media screen and (min-width: $minViewportPx) and (max-width: $maxViewportPx) {
        font-size: calc(#{$minSizeRem} + (#{$maxSize} - #{$minSize}) * ((100vw - #{$minViewportRem}) / (#{$maxViewport} - #{$minViewport})));
    }

    @media screen and (max-width: $minViewportPx) {
        font-size: rem($minSize);
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- FUNCTIONS ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
// Converti les pixels en Rem
@function rem($pixels, $context: $browser-context) {
    @return #{$pixels/$context}rem;
}

// Équivalent de RGBA
@function alpha($color, $opacity){
	$color: str-replace($color, 'var(');
	$color: str-replace($color, ')');
	@return rgba(var($color), $opacity);
}

@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}
