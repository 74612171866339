#overlayFastLinks {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	overflow: hidden;
    visibility: hidden;
    pointer-events: none;

    .wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
		background: $color2;
		overflow: hidden;
        scrollbar-width: none;
        z-index: 99;

        @media screen and (max-height: 550px) { display: block; overflow: auto; }
        @media screen and (max-width: 1024px) and (min-width: 901px) and (max-height: 1100px) { display: block; overflow: auto; }
        @media screen and (max-width: 900px)  and (min-width: 769px) and (max-height: 1000px) { display: block; overflow: auto; }
        @media screen and (max-width: 768px)  and (min-width: 600px) and (max-height: 900px)  { display: block; overflow: auto; }
        @media screen and (max-width: 600px) and (max-height: 650px) { display: block; overflow: auto; }
        @media screen and (max-width: 500px) { overflow: auto; padding-right: 25px; padding-left: 25px; }
        @media screen and (max-width: 375px) { padding-right: 10px; padding-left: 10px; }

        &::-webkit-scrollbar { display: none; }

        .sideWords {
            position: fixed;
            top: 50%;
            right: -165px;
            font: 700 rem(165)/1 $font1;
            color: $color2;
            -webkit-text-fill-color: $color2;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
            white-space: nowrap;
            writing-mode: vertical-lr;
            transform: translateY(-50%) rotate(180deg);

            @media screen and (max-width: 1300px), screen and (max-height: 750px) { font-size: rem(140); }
            @media screen and (max-width: 1100px), screen and (max-height: 700px) { font-size: rem(120); }
            @media screen and (max-width: 768px)  { font-size: rem(100); }
            @media screen and (max-width: 600px), screen and (max-height: 600px)  { display: none; }
        }

        > div {
            width: 100%;
            max-width: 1100px;
            padding: 50px 0;
            margin: 0 auto;

            @media screen and (max-width: 1300px), screen and (max-height: 750px) { max-width: 850px; }
            @media screen and (max-width: 1100px), screen and (max-height: 700px) { max-width: 750px; }
            @media screen and (max-width: 1024px) { max-width: 750px; }
            @media screen and (max-width: 900px)  { max-width: 650px; }
            @media screen and (max-width: 768px)  { max-width: 550px; padding: 30px 0; }
            @media screen and (max-width: 700px)  { max-width: 500px; }
            @media screen and (max-width: 600px)  { max-width: 400px; }


            .grid {
                @media screen and (max-width: 500px)  {
                    width: calc(100% + #{$gaps} + #{$gaps});
                    margin-left: -$gaps;
                    margin-right: -$gaps;
                }
            }
            .column {
                @include setMarginTop(40px, 3);

                @media screen and (max-width: 1024px) { @include setMarginTop(40px, 2); }
                @media screen and (max-width: 600px)  { @include setMarginTop(15px, 2); }
                @media screen and (max-width: 500px)  {
                    padding-right: $gaps;
                    padding-left: $gaps;
                }
            }

            a {
                position: relative;
                width: 280px;
                height: 280px;
                opacity: 0;
                transform: scale(0);

                @media screen and (max-width: 1300px), screen and (max-height: 750px) { width: 240px; height: 240px; }
                @media screen and (max-width: 1100px), screen and (max-height: 700px) { width: 220px; height: 220px; }
                @media screen and (max-width: 1024px) { width: 320px; height: 320px; }
                @media screen and (max-width: 900px)  { width: 280px; height: 280px; }
                @media screen and (max-width: 768px)  { width: 250px; height: 250px; }
                @media screen and (max-width: 700px)  { width: 220px; height: 220px; }
                @media screen and (max-width: 600px)  { width: 180px; height: 180px; }
                @media screen and (max-width: 500px)  { width: 100%; height: auto; padding-top: 100%; }

                &:hover {
                    picture {
                        &:before { background: rgba($color1, 0.7); }

                        img {
                            transform: scale(1.1);
                        }
                    }
                }

                picture, picture img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    overflow: hidden;
                }

                picture {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba($color1, 0.6);
                        z-index: 1;
                        transition: background 300ms;
                    }

                    img {
                        transition: transform 300ms $easeOutCubic;
                    }
                }

                span {
                    position: absolute;
                    width: 100%;
                    padding: 0 12px;
                    top: 50%;
                    left: 0;
                    font: 500 rem(35)/1.2 $font1;
                    color: #fff;
                    text-align: center;
                    transform: translateY(-50%);
                    z-index: 2;

                    @media screen and (max-width: 1300px), screen and (max-height: 750px) { font-size: rem(32); }
                    @media screen and (max-width: 1100px), screen and (max-height: 700px) { font-size: rem(29); }
                    @media screen and (max-width: 1024px) { font-size: rem(35); }
                    @media screen and (max-width: 768px)  { font-size: rem(25); }
                    @media screen and (max-width: 600px)  { font-size: rem(23); }
                    @media screen and (max-width: 500px)  { font-size: rem(21); }
                    @media screen and (max-width: 425px)  { font-size: rem(19); }

                }
            }
        }
    }
}
