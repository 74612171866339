/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL AND PLUS ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_normal, .m_documentsList.t_plus {
    & > li {
        a {
            &:hover {
                .flex div p { color: $color3; }
                .icon {
                    background: $color3;
                    border-color: $color3;

                    svg { stroke: #fff; }
                }
            }

            .flex {
                display: flex;
                align-items: center;

                div {
                    p {
                        font-family: $font1;
                        line-height: 1.3;
                        color: $color1;
                        transition: color 300ms;
                        @include responsiveFontSize(35, 22);
                    }
                }
            }

            .icon {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 50%;
                right: 45px;
                border: 1px solid rgba($color1, 0.3);
                transform: translateY(-50%);
                transition: background 300ms, border-color 300ms;

                @media screen and (max-width: 1024px) { width: 40px; height: 40px; right: 35px; }
                @media screen and (max-width: 768px)  { right: 25px; }
                @media screen and (max-width: 600px)  { right: 15px; }
                @media screen and (max-width: 425px)  { width: 34px; height: 34px; }

                svg {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 50%;
                    left: 50%;
                    stroke: $color3;
                    transform: translate(-50%, -50%);
                    transition: stroke 300ms;

                    @media screen and (max-width: 1024px) { width: 16px; height: 16px; }
                    @media screen and (max-width: 425px)  { width: 14px; height: 14px; }

                    &.external {
                        width: 14px;
                        height: 14px;

                        @media screen and (max-width: 1024px) { width: 12px; height: 12px; }
                        @media screen and (max-width: 425px)  { width: 10px; height: 10px; }
                    }
                }
            }
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_normal {
    & > li {
        a {
            padding: 50px 120px 50px 45px;

            @media screen and (max-width: 1200px) { padding: 40px 120px 40px 45px; }
            @media screen and (max-width: 1024px) { padding: 35px 100px 35px 35px; }
            @media screen and (max-width: 768px)  { padding: 30px  90px 30px 25px; }
            @media screen and (max-width: 600px)  { padding: 25px  65px 25px 15px; }

            .flex {
                div {
                    width: 100%;
                }
            }
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- PLUS ----------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_plus {
    li {
        a {
            padding: 20px 120px 20px 30px;

            @media screen and (max-width: 1024px) { padding: 20px 100px 20px 20px; }
            @media screen and (max-width: 768px)  { padding: 15px  90px 15px 20px; }
            @media screen and (max-width: 600px)  { padding: 25px  65px 25px 15px; }

            .flex {
                min-height: 50px;

                picture {
                    width: 150px;
                    height: 150px;
                    margin-right: 30px;
                    border-radius: 50%;
                    overflow: hidden;

                    @media screen and (max-width: 1200px) { width: 125px; height: 125px; }
                    @media screen and (max-width: 1024px) { width: 100px; height: 100px; margin-right: 25px; }
                    @media screen and (max-width: 768px)  { width: 75px;  height: 75px; margin-right: 20px; }
                    @media screen and (max-width: 600px)  { display: none; }
                }

                div {
                    width: calc(100% - 180px);

                    @media screen and (max-width: 1200px) { width: calc(100% - 155px); }
                    @media screen and (max-width: 1024px) { width: calc(100% - 125px); }
                    @media screen and (max-width: 768px)  { width: calc(100% - 95px);  }
                    @media screen and (max-width: 600px)  { width: 100%; }

                    h6 { margin-bottom: 5px; }
                }
            }
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL AND PLUS IN RUBRIC -------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_normal_inRubric, .m_documentsList.t_plus_inRubric {
    li {
        a {
            &:hover:before { right: 0%; }

            &:before {
                content: '';
                position: absolute;
                top: -1px;
                right: 100%;
                bottom: -1px;
                left: 0;
                background: #fff;
                transition: right 500ms $easeOutCubic;
            }

            .flex {
                position: relative;
                display: flex;
                align-items: center;
                transition: padding 300ms $easeOutCubic;

                @media screen and (max-width: 600px) { min-height: 100%; }

                p {
                    font-family: $font1;
                    line-height: 1.5;
                    color: $color1;
                    @include fontsize(18, 0, 0, 0, 0, 0, 0);
                }
            }

            svg {
                position: absolute;
                width: 18px;
                height: 18px;
                top: 50%;
                right: 30px;
                stroke: $color1;
                transform: translateY(-50%);

                @media screen and (max-width: 1024px) { right: 25px; }
                @media screen and (max-width: 768px)  { right: 20px; }
                @media screen and (max-width: 600px)  { right: 5px; }

                &.external {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL IN RUBRIC ----------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_normal_inRubric {
    li {
        a {
            padding: 20px 75px 20px 30px;

            @media screen and (max-width: 1024px) { padding: 20px 70px 20px 25px; }
            @media screen and (max-width: 768px)  { padding: 20px 65px 20px 20px; }
            @media screen and (max-width: 600px)  { padding: 20px 35px 20px 5px; }

            .flex {
                div { width: 100%; }
            }
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- PLUS IN RUBRIC ------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_plus_inRubric {
    a {
        padding: 20px 75px 20px 10px;

        @media screen and (max-width: 1024px) { padding: 20px 70px 20px 10px; }
        @media screen and (max-width: 768px)  { padding: 20px 65px 20px 10px; }
        @media screen and (max-width: 600px)  { padding: 20px 35px 20px 5px; }

        .flex {
            picture {
                width: 90px;
                height: 90px;
                margin-right: 30px;
                overflow: hidden;
                border-radius: 50%;

                @media screen and (max-width: 1024px) { width: 75px; height: 75px; margin-right: 20px; }
                @media screen and (max-width: 768px)  { width: 60px; height: 60px; }
                @media screen and (max-width: 600px)  { display: none; }
            }

            > div {
                width: calc(100% - 120px);

                @media screen and (max-width: 1024px) { width: calc(100% - 95px); }
                @media screen and (max-width: 768px)  { width: calc(100% - 80px); }
                @media screen and (max-width: 600px)  { width: 100%; }

                h6 { margin-bottom: 5px; padding-top: 5px; }
            }
        }
    }
}
