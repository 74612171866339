.l_featured {
    position: relative;
    padding: 250px 0;

    @media screen and (max-width: 1200px) { padding: 210px 0; }
    @media screen and (max-width: 1024px) { padding: 170px 0 250px; }
    @media screen and (max-width: 768px)  { padding: 140px 0 220px; }
    //@media screen and (max-width: 600px)  { padding: 100px 0 180px; }
    //@media screen and (max-width: 500px)  { padding: 80px 0 150px; }
    
    .slickFeaturedImages {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background: $color1;
        
        &,
        .slick-list,
        .slick-track,
        .slick-slide,
        .slick-slide > div,
        .slick-slide > div > div { height: 100%; }

        .credit{
            position: absolute;
            background-color: rgba(#000000, 0.4);
            color: white;
            font-size: 16px;
            padding: 10px 15px;
            top: 0;
            right: 0;
            display: inline-block;
            z-index: 2;
            font-family: $font1;
        }

        picture {
            position: relative;
            width: 100%;
            height: 100%;
            
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba($color1, 0.7);
                z-index: 1;
            }

            &:after, img { // Transition scrollfire
                transition-duration: 1500ms;
                transition-timing-function: $easeInOutQuint;
            }
        }
    }

    .fiveReasonsWhy {
        position: absolute;
        top: 150px;
        font-family: $font1;
        font-weight: 500;
        font-size: 25px;
        line-height: 1.25;
        color: $color3;
        text-transform: uppercase;
        width: 100%;
        text-align: center;

        @media screen and (max-width: 1024px) {
            top: 100px;
        }

        @media screen and (max-width: 900px) {
            font-size: 20px;
        }

        @media screen and (max-width: 700px) {
            font-size: 18px;
        }

        /*@media screen and (max-width: 600px) {
            top: 50px;
        }

        @media screen and (max-width: 500px) {
            top: 30px;
        }*/
    }

    .slickFeaturedTexts {
        position: relative;
        z-index: 1;

        .slick-list {
            padding: 0 20vw;

            @media screen and (max-width: 1024px) { padding: 0; }
        }
        .slick-track { display: flex; align-items: center; }
        .slick-current .text, .slickAnimate .text {
            h2 {
                color: #fff;
                -webkit-text-stroke-color: rgba(255, 255, 255, 0);
            }
        }
        .text {
            padding-left: 50px;
            padding-right: 50px;
            margin: 0 auto;
            text-align: center;

            @media screen and (max-width: 768px) { padding-right: 30px; padding-left: 30px; }
            @media screen and (max-width: 600px) { padding-right: 25px; padding-left: 25px; }

            h2 {
                font-family: $font1;
                font-weight: 700;
                color: transparent;
                @include responsiveFontSize(110, 40);
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #fff;
                transition: color 300ms, -webkit-text-stroke-color 300ms;
            }

            .link {
                margin-top: 50px;

                @media screen and (max-width: 1024px) { margin-top: 40px; }
                @media screen and (max-width: 768px)  { margin-top: 30px; }
            }
        }
    }

    .slickNavWrapper {
        position: absolute;
        padding: 30px 50px;
        right: 0;
        bottom: 0;
        background: #fff;

        @media screen and (max-width: 1200px) { padding: 25px 40px; }
        @media screen and (max-width: 1024px) { padding: 15px 30px; }
        @media screen and (max-width: 768px)  { padding: 15px 25px; }
    }
}
