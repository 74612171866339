#overlayShare {
    position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	overflow-y: auto;
	visibility: hidden;
    z-index: 2001;

    &::-webkit-scrollbar { display: none; }

	.wrapper {
        position: absolute;
        padding: 85px;
        top: 50%;
        left: 50%;
		background: #fff;
        pointer-events: none;
        text-align: center;
        z-index: 151;
        opacity: 0;
        transform: translate(-50%, -25%);
        transition: opacity 300ms, transform 300ms;

        @media screen and (max-width: 1024px) { padding: 50px; }
        @media screen and (max-width: 600px)  {
            width: auto;
            right: 20px;
            left: 20px;
            padding: 50px 20px;
            transform: translateY(-25%);
        }

        h3 { margin-bottom: 20px; }

        .close {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 20px;
            right: 20px;
            cursor: pointer;

            &:hover { &:before, &:after { background: $color3; }}

            &:before, &:after {
                content: '';
                position: absolute;
                width: 16px;
                height: 2px;
                top: 50%;
                left: 50%;
                background: $color1;
                transition: background 300ms;
            }
            &:before { transform: translate(-50%, -50%) rotate(45deg) ; }
            &:after { transform: translate(-50%, -50%) rotate(-45deg) ; }
        }

        ul {
            position: relative;
            margin-top: 16px;

            li {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 20px;

                    @media screen and (max-width: 600px) { margin-right: 10px; }
                }

                a {
                    position: relative;
                    width: 45px;
                    height: 45px;
                    background: $color1;
                    border-radius: 50%;
                    transition: background 300ms;

                    svg {
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        top: 50%;
                        left: 50%;
                        fill: #fff;
                        transform: translate(-50%, -50%);

                        use { fill: #fff; }
                    }

                    &:hover { background: $color3; }
                }
            }
        }
	}
}
