/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlaySearch {
    header  {
        .content .grid .itemMain .mobile .btnSearch {
            .btnX {
                opacity: 1;
                transition: opacity 300ms linear 150ms;
            }
            svg {
                opacity: 0;
                transition: opacity 300ms;
            }
        }
    }

    #overlaySearch {
        visibility: visible;
        pointer-events: all;
        z-index: 11;

        .background {
            opacity: 1;
            visibility: visible;
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlaySearch.closingOverlaySearch {
    #overlaySearch {
        pointer-events: none;

        .background { opacity: 0; }
    }

    header .content .grid .itemMain .mobile .btnSearch {
        .btnX {
            opacity: 0;
            transition: opacity 300ms;
        }
        svg {
            opacity: 1;
            transition: opacity 300ms linear 150ms;
        }
    }
}
