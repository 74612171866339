.m_jobsList {
    list-style-type: none;

    & > li {
        border-top: 1px solid #bec2c8;
        border-bottom: 1px solid #bec2c8;

        &:not(:first-child) { margin-top: -1px; }

        a {
            display: block;
            position: relative;
            padding: 50px 120px 50px 45px;

            @media screen and (max-width: 1200px) { padding: 40px 120px 40px 45px; }
            @media screen and (max-width: 1024px) { padding: 35px 100px 35px 35px; }
            @media screen and (max-width: 768px)  { padding: 30px  90px 30px 25px; }
            @media screen and (max-width: 600px)  { padding: 25px  65px 25px 15px; }

            &:hover {
                .flex div p { color: $color3; }
                .icon {
                    background: $color3;
                    border-color: $color3;

                    svg { stroke: #fff; }
                }
            }

            .flex {
                display: flex;
                align-items: center;

                div {
                    p {
                        font-family: $font1;
                        line-height: 1.3;
                        color: $color1;
                        transition: color 300ms;
                        @include responsiveFontSize(35, 22);
                    }
                }
            }

            .icon {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 50%;
                right: 45px;
                border: 1px solid rgba($color1, 0.3);
                transform: translateY(-50%);
                transition: background 300ms, border-color 300ms;

                @media screen and (max-width: 1024px) { width: 40px; height: 40px; right: 35px; }
                @media screen and (max-width: 768px)  { right: 25px; }
                @media screen and (max-width: 600px)  { right: 15px; }
                @media screen and (max-width: 425px)  { width: 34px; height: 34px; }

                svg {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 50%;
                    left: 50%;
                    stroke: $color3;
                    transform: translate(-50%, -50%);
                    transition: stroke 300ms;

                    @media screen and (max-width: 1024px) { width: 16px; height: 16px; }
                    @media screen and (max-width: 425px)  { width: 14px; height: 14px; }
                }
            }
        }
    }
}
