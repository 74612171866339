.l_homeBanner {
    position: relative;
    height: 100vh;
    padding-left: 200px;
    margin-top: -130px;
    min-height: 530px;
    max-height: 1080px;
    transition: height 300ms $easeOutCubic; // synchro avec alerts.scss ligne 9

    @media screen and (max-width: 1024px), screen and (max-height: 750px) { padding-left: 150px; margin-top: -100px; max-height: 700px; }
    @media screen and (max-width: 850px), screen and (max-height: 599px)  { padding-left: 120px; margin-top: -80px; max-height: 850px; }
    @media screen and (max-width: 768px) { padding-left: 0px; margin-top: -80px; }

    &:before {
        @media screen and (max-width: 768px) {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            background: #fff;
            z-index: 1;
        }
    }

    .content {
        padding-right: 385px;
        background: $color2;

        @media screen and (max-width: 1300px) { padding-right: 325px; }
        @media screen and (max-width: 1200px) { padding-right: 300px; }
        @media screen and (max-width: 1100px) { padding-right: 275px; }
        @media screen and (max-width: 1024px) { padding-right: 250px; }
        @media screen and (max-width: 850px)  { padding-right: 125px; }
        @media screen and (max-width: 768px)  { padding-right: 0; }

        &,
        .slickBannerImagesWrapper, .slickBannerTextWrapper,
        .slickBannerImages, .slickBannerTexts,
        .slick-list,
        .slick-track,
        .slick-slide,
        .slick-slide > div,
        .slick-slide > div > div { height: 100%; }

        .slickBannerImagesWrapper {
            position: relative;
            background: #fff;

            @media screen and (max-width: 768px) { height: 80%; }

            .slickBannerImages {
                picture {
                    height: 100%;

                    @media screen and (max-width: 768px) {
                        &:after {
                            top: 80px;
                            left: 0;
                            transition: top 1500ms $easeInOutQuint;
                        }

                        #{$fire} { &:after { top: 100%; }}
                    }

                    &:after, img { // Transition scrollfire
                        transition-duration: 1500ms;
                        transition-timing-function: $easeInOutQuint;
                    }
                }
            }
        }

        .slickBannerTextsWrapper {
            position: absolute;
            width: 100%;
            top: 130px;
            bottom: 0;
            left: 0;

            @media screen and (max-width: 1024px), screen and (max-height: 750px) { top: 100px; }
            @media screen and (max-width: 850px), screen and (max-height: 599px) { top: 80px; }

            .follow {
                position: absolute;
                bottom: 25px;
                right: 175px;
                @include paragraph();

                @media screen and (max-width: 1300px) { right: 125px; }
                @media screen and (max-width: 1200px) { right: 100px; }
                @media screen and (max-width: 1100px) { right: 50px; }
                @media screen and (max-width: 850px)  { right: 75px; }
                @media screen and (max-width: 768px), screen and (max-height: 700px) { display: none; }

                &:hover svg {
                    fill: #fff;
                }

                span {
                    @media screen and (max-width: 850px) { display: none; }
                }

                svg {
                    width: 24px;
                    height: 24px;
                    margin-left: 10px;
                    fill: $color1;
                    vertical-align: middle;
                    transition: fill 300ms;
                }
            }

            .container {
                position: absolute;
                width: 580px;
                top: 80px;
                right: 100px;
                bottom: 80px;
                background: #fff;

                @media screen and (max-height: 700px) { top: 30px; bottom: 30px; }
                @media screen and (max-width: 1300px) { width: 530px; right: 50px; }
                @media screen and (max-width: 1200px) { width: 500px; }
                @media screen and (max-width: 1100px) { width: 470px; }
                @media screen and (max-width: 1024px) { width: 430px; }
                @media screen and (max-width: 850px)  { width: 400px; }
                @media screen and (max-width: 768px)  {
                    width: auto;
                    height: auto;
                    top: auto;
                    right: 30px;
                    bottom: 50px;
                    left: 30px;
                }
                @media screen and (max-width: 600px) { right: 25px; left: 25px; }
                @media screen and (max-width: 500px) { bottom: 30px; }

                .slickBannerTexts {
                    .text {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        padding: 30px 65px;

                        @media screen and (max-width: 1300px) { padding: 30px 40px; }
                        @media screen and (max-width: 1100px) { padding: 25px 30px; }
                        @media screen and (max-width: 768px)  { padding: 75px; }
                        @media screen and (max-width: 500px), screen and (max-width: 700px)  { padding: 50px; }
                        @media screen and (max-width: 425px) { padding: 50px 30px; }
                        @media screen and (max-width: 375px) { padding: 40px 25px; }
                        @media screen and (max-width: 350px) { padding: 30px 20px; }

                        h1, h2 {
                            font-family: $font1;
                            font-weight: 500;
                            color: $color1;
                            @include responsiveFontSize(72, 40);

                            @media screen and (max-height: 750px) { @include responsiveFontSize(65, 40); }
                            @media screen and (max-height: 700px) { @include responsiveFontSize(60, 40); }

                            &.smaller {
                                @include responsiveFontSize(55, 35);
                            }

                            span { color: $color3; }
                        }

                        .btn {
                            margin-top: 50px;

                            @media screen and (max-width: 1024px), screen and (max-height: 750px) { margin-top: 40px; }
                            @media screen and (max-width: 768px), screen and (max-height: 700px)  { margin-top: 30px; }
                        }
                    }
                }
            }
        }
    }

    #slickBannerNav.slickNav {
        position: absolute;
        width: 200px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @media screen and (max-width: 1024px), screen and (max-height: 750px) { width: 150px; }
        @media screen and (max-width: 850px),  screen and (max-height: 599px) { width: 120px; }
        @media screen and (max-width: 768px)  { display: none; }
    }
}


.showTransition, .showLanding .l_homeBanner [data-picture]:after { top: 80px !important; }
