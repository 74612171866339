/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayCalendar #overlayCalendar {
    pointer-events: all;
    visibility: visible;

    .background {
        @media screen and (max-width: 600px) { opacity: 1; visibility: visible; }
    }

    .wrapper {
        opacity: 1;
        pointer-events: all;
        transform: none;

        @media screen and (max-width: 600px) { transform: translateY(-50%); }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayCalendar.closingOverlayCalendar #overlayCalendar {
    .background {
        @media screen and (max-width: 600px) { opacity: 0; }
    }
    .wrapper {
        opacity: 0;
        transform: translateY(50px);
    }
}


