.l_pageInfos {
    .content {
        width: calc(100% - 400px);
        margin-left: auto;

        @media screen and (max-width: 1400px) { width: calc(100% - 300px); }
        @media screen and (max-width: 1300px) { width: calc(100% - 250px); }
        @media screen and (max-width: 1200px) { width: calc(100% - 200px); }
        @media screen and (max-width: 1024px) { width: calc(100% - 160px); }
        @media screen and (max-width: 850px)  { width: 100%; padding: 0 30px; }
        @media screen and (max-width: 600px)  { padding: 0 25px; }

        .wrapper {
            display: flex;
            flex-flow: row wrap;
            height: 80px;
            padding-right: 80px;
            align-items: center;

            @media screen and (max-width: 1024px), screen and (max-height: 750px) { height: 70px; padding-right: 50px; }
            @media screen and (max-height: 599px) { height: 60px; }
            @media screen and (max-width: 850px) { height: 60px; padding: 0; }

            .m_breadcrumb {
                width: calc(100% - 110px);
                padding-right: 50px;

                @media screen and (max-width: 850px) { width: 100%; padding-right: 0; }
            }

            .m_btnShare {
                width: 110px;

                @media screen and (max-width: 850px) { display: none; }
            }
        }
    }
}
