/*** |- ---------------------------------------- -| ***/
/*** |- TITLES --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.hugeTitle {
    font-family: $font1;
    font-size: rem(110);
    font-weight: 500;
    line-height: 0.9;
    color: $color1;
    @include responsiveFontSize(110, 40);

    &.smaller {
        @include responsiveFontSize(72, 40);
    }
}

.largeTitle {
    @include largeTitle();
}

.mediumTitle {
    @include mediumTitle();
}

.smallTitle {
    @include smallTitle();
}

.tinyTitle {
    font-family: $font2;
    font-size: rem(20);
    font-weight: 700;
    line-height: 1.5;
    color: $color2;
}

.minusculeTitle {
    font-family: $font1;
    font-size: rem(18);
    font-weight: 500;
    line-height: 1.3;
    color: $color3;
    text-transform: uppercase;

    @media screen and (max-width: 768px) { font-size: rem(16); }
    @media screen and (max-width: 600px) { font-size: rem(14); }
}



/*** |- ---------------------------------------- -| ***/
/*** |- CONTENT -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
p {
    @include paragraph();
}

a {
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
}

.link {
    position: relative;
    padding-bottom: 6px;
    font-family: $font1;
    color: $color1;
    transition: color 300ms;
    @include fontsize(21, 0, 0, 18, 17, 0, 0);

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background: $color1;
        transition: background 300ms, width 300ms $easeOutQuart;
    }

    &:hover {
        color: $color3;
        &:before { width: 50%; background: $color3; }
    }

    &.t_white {
        color: #fff;
        &:before { background: #fff; }
    }
}

.btn {
    display: inline-block;
    position: relative;
    padding: 22px 90px 22px 30px;
    font: 500 rem(14)/1.3 $font2;
    color: $color1;
    text-transform: uppercase;
    border: 1px solid $color1;
    background-color: white;
    cursor: pointer;
    outline: 0;

    @media screen and (max-width: 425px)  { padding: 14px 61px 14px 20px; font-size: rem(13); }

    &:hover span:before { right: 0%; }

    span {
        position: absolute;
        width: 60px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        background: $color1;
        overflow: hidden;

        @media screen and (max-width: 425px)  { width: 41px; }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 100%;
            bottom: 0;
            left: 0;
            background: $color3;
            transition: right 500ms $easeOutQuart;
        }

        &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: url('../images/content/icons/whitePointer.svg') no-repeat center / contain;
            transition: 300ms;

            @media screen and (max-width: 425px) { width: 11px; height: 14px; }
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- WRAPPERS ------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.w1440 { max-width: 1440px; margin: 0 auto; @media screen and (max-width: 1440px) { margin: 0; }}
.w1000 { max-width: 1000px; margin: 0 auto; @media screen and (max-width: 1000px) { margin: 0; }}
.w900  { max-width:  900px; margin: 0 auto; @media screen and (max-width:  900px) { margin: 0; }}



/*** |- ---------------------------------------- -| ***/
/*** |- X PADDING ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.xp120 { padding-right: 120px; padding-left: 120px;
    @media screen and (max-width: 1200px) { padding-right: 100px; padding-left: 100px; }
    @media screen and (max-width: 1024px) { padding-right: 50px;  padding-left: 50px;  }
    @media screen and (max-width: 768px)  { padding-right: 30px;  padding-left: 30px;  }
    @media screen and (max-width: 600px)  { padding-right: 25px;  padding-left: 25px;  }
}
.rp120 { padding-right: 120px;
    @media screen and (max-width: 1200px) { padding-right: 100px; }
    @media screen and (max-width: 1024px) { padding-right: 50px;  }
    @media screen and (max-width: 768px)  { padding-right: 30px;  }
    @media screen and (max-width: 600px)  { padding-right: 25px;  }
}
.lp120 { padding-left: 120px;
    @media screen and (max-width: 1200px) { padding-left: 100px; }
    @media screen and (max-width: 1024px) { padding-left: 50px;  }
    @media screen and (max-width: 768px)  { padding-left: 30px;  }
    @media screen and (max-width: 600px)  { padding-left: 25px;  }
}



/*** |- ---------------------------------------- -| ***/
/*** |- Y PADDING ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.yp120 { padding-top: 120px; padding-bottom: 120px;
    @media screen and (max-width: 1400px) { padding-top: 110px; padding-bottom: 110px; }
    @media screen and (max-width: 1200px) { padding-top: 90px;  padding-bottom: 90px;  }
    @media screen and (max-width: 1024px) { padding-top: 80px;  padding-bottom: 80px;  }
    @media screen and (max-width: 768px)  { padding-top: 70px;  padding-bottom: 70px;  }
    @media screen and (max-width: 500px)  { padding-top: 50px;  padding-bottom: 50px;  }
}
.tp120 { padding-top: 120px;
    @media screen and (max-width: 1400px) { padding-top: 110px; }
    @media screen and (max-width: 1200px) { padding-top: 90px;  }
    @media screen and (max-width: 1024px) { padding-top: 80px;  }
    @media screen and (max-width: 768px)  { padding-top: 70px;  }
    @media screen and (max-width: 500px)  { padding-top: 50px;  }
}
.bp120 { padding-bottom: 120px;
    @media screen and (max-width: 1400px) { padding-bottom: 110px; }
    @media screen and (max-width: 1200px) { padding-bottom: 90px;  }
    @media screen and (max-width: 1024px) { padding-bottom: 80px;  }
    @media screen and (max-width: 768px)  { padding-bottom: 70px;  }
    @media screen and (max-width: 500px)  { padding-bottom: 50px;  }
}

.yp80 { padding-top: 80px; padding-bottom: 80px;
    @media screen and (max-width: 768px)  { padding-top: 70px;  padding-bottom: 70px;  }
    @media screen and (max-width: 500px)  { padding-top: 50px;  padding-bottom: 50px;  }
}
.tp80 { padding-top: 80px;
    @media screen and (max-width: 768px)  { padding-top: 70px;  }
    @media screen and (max-width: 500px)  { padding-top: 50px;  }
}
.bp80 { padding-bottom: 80px;
    @media screen and (max-width: 768px)  { padding-bottom: 70px;  }
    @media screen and (max-width: 500px)  { padding-bottom: 50px;  }
}

.yp50 { padding-top: 50px; padding-bottom: 50px; }
.tp50 { padding-top: 50px; }
.bp50 { padding-bottom: 50px; }



/*** |- ---------------------------------------- -| ***/
/*** |- MARGIN --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.bm50 {
    margin-bottom: 50px;

    @media screen and (max-width: 1200px) { margin-bottom: 40px; }
    @media screen and (max-width: 1024px) { margin-bottom: 30px; }
    @media screen and (max-width: 768px)  { margin-bottom: 20px; }
}

.tm50 {
    margin-top: 50px;

    @media screen and (max-width: 1200px) { margin-top: 40px; }
    @media screen and (max-width: 1024px) { margin-top: 30px; }
    @media screen and (max-width: 768px)  { margin-top: 20px; }
}

.bm60 {
    margin-bottom: 60px;

    @media screen and (max-width: 1200px) { margin-bottom: 50px; }
    @media screen and (max-width: 1024px) { margin-bottom: 40px; }
    @media screen and (max-width: 768px)  { margin-bottom: 30px; }
}



/*** |- ---------------------------------------- -| ***/
/*** |- IMAGES --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
picture {
    display: block;
    font-size: 0;

    img { width: 100%; height: 100%; }

    &.adaptive {
        position: relative;
        width: 100%;
        height: 100%;

        img { position: absolute; top: 0; left: 0; }
    }

    [data-object-fit='cover'] {
        object-fit: cover;
        object-position: center center;
        font-family: 'object-fit: cover;';
    }

    [data-focus='0'] {
        object-position: left center;
    }
    [data-focus='1'] {
        object-position: center center;
    }
    [data-focus='2'] {
        object-position: right center;
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- LANDING -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
#landing {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	background: #fff;
	visibility: hidden;
	z-index: 9998;
	transition: opacity 500ms, visibility 0ms ease 500ms;
}


/*** |- ---------------------------------------- -| ***/
/*** |- TRANSITION ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
#transition {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
    opacity: 0;
	background: #fff;
    visibility: hidden;
    z-index: 9998;
    transition: opacity 500ms, visibility 0ms ease 500ms;
}


/*** |- ---------------------------------------- -| ***/
/*** |- MISCELLANEOUS -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.noselect { user-select: none; }
.clearfix { clear: both; display: block; }


