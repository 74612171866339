/*** ----- TECHNICAL ----- ***/
$browser-context: 16; // La taille initiale de la typo
$colnb: 12; // Nombre de colonnes pour la grille
$gaps: 1%;  // Padding de chaque côté d'une colonne

/*** ----- SELECTORS ----- ***/
$scrollfire: '[data-scrollfire], [data-multiscrollfire]';
$fire: '&[data-scrollfire="fire"], &[data-multiscrollfire="fire"]';

/*** ----- EASE ----- ***/
$easeInOutCustom: cubic-bezier(0.84, -0.01, 0.48, 0.995);
$easeInCubic: cubic-bezier(0.32, 0, 0.67, 0);
$easeInQuart: cubic-bezier(0.5, 0, 0.75, 0);
$easeInExpo: cubic-bezier(0.7, 0, 0.84, 0);
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
$easeOutQuint: cubic-bezier(0.22, 1, 0.36, 1);
$easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
$easeInOutCubic: cubic-bezier(0.65, 0, 0.35, 1);
$easeInOutQuart: cubic-bezier(0.76, 0, 0.24, 1);
$easeInOutQuint: cubic-bezier(0.83, 0, 0.17, 1);
$easeInOutExpo: cubic-bezier(0.87, 0, 0.13, 1);
$easeIn: cubic-bezier(0.55, 0.055, 0.675, 0.19);

/*** ----- COLORS ----- ***/
$color1: #293548;
$color2: #c5dc76;
$color3: #a4b665;
$color4: #bdbfc3;
$color5: #f4f5f6;
$color6: #de0524;

/*** ----- FONTS ----- ***/
$font1: 'Founders Grotesk', sans-serif;
$font2: 'Founders Grotesk Mono', sans-serif;
