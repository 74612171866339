.t_events {
    .content {
        @media screen and (min-width: 1441px) { padding-right: 50px; padding-left: 50px; }

        > .w1440 {
            > h2 {
                margin-bottom: 90px;
                font-family: $font1;
                font-weight: 700;
                color: $color3;
                text-align: center;
                @include responsiveFontSize(129, 40);

                @media screen and (max-width: 1400px) { margin-bottom: 70px; }
                @media screen and (max-width: 1200px) { margin-bottom: 50px; }
                @media screen and (max-width: 1024px) { margin-bottom: 40px; }
                @media screen and (max-width: 768px)  { margin-bottom: 30px; }
            }


            .eventsList > :not(:first-child) {
                margin-top: 60px;

                @media screen and (max-width: 1200px) { margin-top: 50px; }
                @media screen and (max-width: 1024px) { margin-top: 40px; }
                @media screen and (max-width: 768px)  { margin-top: 30px; }
            }

            .btnWrapper {
                text-align: center;
                margin-top: 70px;

                @media screen and (max-width: 1200px) { margin-top: 50px; }
                @media screen and (max-width: 1024px) { margin-top: 40px; }
                @media screen and (max-width: 768px)  { margin-top: 30px; }
            }
        }
    }
}
