html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

body {
    line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

nav ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}

input, select {
    vertical-align: middle;
}



/*** Blanko ***/
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

h1, h2, h3, h4, h5, h6 { font-weight: normal; }
ol, ul { list-style: none; }
input:focus { outline: none; }
a { -webkit-tap-highlight-color: transparent; }
.slick-slide, .slick-slide a, .slick-slide:focus { outline: none !important; }




/*
html { overflow-x: hidden; }

strong, b { font-weight: bold; }
blockquote, q { quotes: none; }

table { border-collapse: collapse; border-spacing: 0; }



textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active,
button:hover, label:focus, .btn:active, .btn.active { outline: 0px !important; -webkit-appearance: none; }

textarea, input, input[type='text'], input[type='button'], input[type='submit'] { outline: none;
    -webkit-appearance: none !important; border-radius: 0px !important; outline: none !important; }

button, input[type="submit"], input[type="reset"] { background: none; color: inherit; border: none; padding: 0;
    font: inherit; cursor: pointer; outline: inherit; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill,
textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    border-bottom: 1px solid $color5;
    -webkit-text-fill-color: $color2;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}



fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
}
*/
