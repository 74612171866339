.l_alerts {
    position: relative;
    width: 100%;
    height: 0px;
    left: 0;
    background: $color6;
    overflow: hidden;
    z-index: 12;
    transition: height 300ms $easeOutCubic; // synchro avec pageTitle.scss ligne 5

    .slickAlerts {
        width: auto;
        height: 50px;
        margin: 0px 56px 0 69px;
        padding: 15px 20px;

        @media screen and (max-width: 768px) { margin: 0px 46px 0 59px; }
        @media screen and (max-width: 600px) { margin: 0px 41px 0 54px; }
        @media screen and (max-width: 500px) { margin: 0px 26px 0 39px; height: 44px; }

        .slick-track, .slick-slide { font-size: 0; }

        a, p {
            position: relative;
            top: 1px;
            display: block;
            padding-bottom: 3px;
            margin-bottom: 0;
            width: 100%;
            font: rem(16)/1 $font1;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media screen and (max-width: 500px) { font-size: rem(13); padding-bottom: 0; }
        }

        a {
            opacity: 1;
            transition: opacity 300ms;

            &:hover { opacity: 0.6; }
        }

        div {
            text-align: center;
        }
    }

    .slickNav {
        position: absolute;
        top: 50%;
        left: 40px;
        font: rem(13)/1 $font1;
        color: #fff;
        transform: translateY(-50%);
        z-index: 1;

        @media screen and (max-width: 768px) { left: 30px; }
        @media screen and (max-width: 600px) { left: 25px; }
        @media screen and (max-width: 500px) { left: 10px; }
    }

    .close {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        right: 40px;
        z-index: 1;
        transform: translateY(-50%);
        transition: opacity 300ms;

        @media screen and (max-width: 768px) { right: 30px; }
        @media screen and (max-width: 600px) { right: 25px; }
        @media screen and (max-width: 500px) { right: 10px; }

        &:before, &:after {
            content: '';
            position: absolute;
            width: 2px;
            height: 14px;
            top: 50%;
            left: 50%;
            background: #fff;
        }

        &:before { transform: translate(-50%, -50%) rotate(45deg);  }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover  { opacity: 0.6; }
    }
}


.showAlerts, .showTransition.showAlerts {
    .l_alerts {
        height: 50px;

        @media screen and (max-width: 500px) { height: 44px; }
    }
}


.showOverlayDropdown:not(.closingOverlayDropdown),
.showOverlaySearch:not(.closingOverlaySearch),
.showOverlayMenu:not(.closingOverlayMenu) {
    &.showAlerts .l_alerts {
        height: 0px;
    }
}
