.m_breadcrumb {
    display: inline-block;
    position: relative;
    max-width: 100%;
    z-index: 2;

    p {
        position: relative;
        font: 500 rem(18)/1 $font1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 1;

        .home {
            &:hover svg { stroke: $color3; }

            svg {
                width: 14px;
                height: 14px;
                stroke: $color1;
                transition: stroke 300ms;
            }
        }

        a, span {
            position: relative;
            display: inline;
            font: rem(18)/1 $font1;
            font-weight: 500;
            color: $color1;

            @media screen and (max-width: 768px) { font-size: rem(16); }
            @media screen and (max-width: 500px) { font-size: rem(15); }

            &:last-child { color: $color1; }
            &:not(:last-child) {
                margin-right: 15px;

                @media screen and (max-width: 768px) { margin-right: 10px; }
                @media screen and (max-width: 500px) { margin-right: 5px; }
            }
        }

        a {
            transition: color 300ms;

            &:hover { color: $color3; }
        }
    }
}
