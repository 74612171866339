.m_rubricsList {
    list-style-type: none;

    & > li {
        position: relative;
        border-bottom: 1px solid rgba($color1, 0.2);
        overflow: hidden;
        transition: background 300ms;

        &:first-child { border-top: 1px solid rgba($color1, 0.2); }

        .clickable {
            display: block;
            position: relative;
            cursor: pointer;

            &:hover {
                .flex div p { color: $color3; }
                .icon {
                    background: $color3;
                    border-color: $color3;

                    svg { stroke: #fff; }
                }
            }

            .flex {
                display: flex;
                align-items: center;

                div {
                    width: 100%;

                    p {
                        font-family: $font1;
                        line-height: 1.3;
                        color: $color1;
                        transition: color 300ms;
                        @include responsiveFontSize(35, 22);
                    }
                }
            }

            .icon {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 50%;
                right: 45px;
                border: 1px solid rgba($color1, 0.3);
                transform: translateY(-50%);
                transition: background 300ms, border-color 300ms;

                @media screen and (max-width: 1024px) { width: 40px; height: 40px; right: 35px; }
                @media screen and (max-width: 768px)  { right: 25px; }
                @media screen and (max-width: 600px)  { right: 15px; }
                @media screen and (max-width: 425px)  { width: 34px; height: 34px; }

                svg {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 50%;
                    left: 50%;
                    stroke: $color3;
                    transform: translate(-50%, -50%) rotate(90deg);
                    transition: transform 300ms $easeOutCubic, stroke 300ms;

                    @media screen and (max-width: 1024px) { width: 16px; height: 16px; }
                    @media screen and (max-width: 425px)  { width: 14px; height: 14px; }
                }
            }
        }

        .drawer {
            position: relative;
            opacity: 0;
            z-index: 1;
            transform: translateY(100px);
            transition: opacity 300ms, transform 300ms;

            > div {
                padding-bottom: 50px;

                @media screen and (max-width: 1200px) { padding-bottom: 40px; }
                @media screen and (max-width: 1024px) { padding-bottom: 30px; }

                .dynamic {
                    h2 { @include fontsize(40, 37, 34, 31, 29, 28, 27); margin: 25px 0 15px; }
                    h3 { @include fontsize(26,  0, 24, 23, 22, 21, 20); margin: 20px 0 10px; }
                    h4 { @include fontsize(22,  0, 20, 18,  0,  0,  0); margin: 15px 0 5px; }
                    p { margin: 5px 0 15px; }
                    blockquote {
                        @include fontsize(23, 0, 22, 21, 20, 19, 18);
                        p { @include fontsize(23, 0, 22, 21, 20, 19, 18); }
                    }
                }

                .m_gallery, .m_documentsList, .btnShare {
                    margin-top: 50px;

                    @media screen and (max-width: 1024px) { margin-top: 40px; }
                    @media screen and (max-width: 768px)  { margin-top: 30px; }
                }
            }

        }
    }
}
