.datepickerWrapper {
    position: relative;
    padding: 0 30px 30px;
    width: 100%;
    background: #fff;
    border: 1px solid $color1;

    @media screen and (max-width: 1024px) {
        padding: 0 20px 30px;
    }

    &:before {
        content: '';
        position: absolute;
        height: 70px;
        top: 0;
        left: 0;
        right: 0;
        background: $color1;

        @media screen and (max-width: 600px) { height: 60px; }
    }
}

.datepicker {
    position: relative;
    width: 100%;
    direction: ltr;
}

.datepicker-rtl {
    direction: rtl;
}

.datepicker-rtl.dropdown-menu {
    left: auto;
}

.datepicker-rtl table tr td span {
    float: right;
}

.datepicker-dropdown {
    top: 0;
    left: 0;
}

.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #999;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.datepicker {

    // GLOBAL --------
    table {
        width: 100%;
        table-layout: fixed;
        word-wrap: break-word;
    }
    // ---------------

    // HEAD ----------
    .datepicker-switch, .prev, .next {
        height: 70px;
        transition: opacity 300ms;
        cursor: pointer;

        @media screen and (max-width: 600px) {
            height: 60px;
        }

        &:hover {
            opacity: 0.6;
        }
    }

    .prev, .next {
        width: 25px;
        font-size: 0;
        background-image: url('../images/content/icons/whitePointer.svg');
        background-repeat: no-repeat;
        background-size: 15px 12px;

        &.disabled {
            visibility: hidden;
        }
    }

    .datepicker-switch {
        width: calc(100% - 50px);
        font: 500 rem(18)/1 $font1;
        text-transform: uppercase;
        color: #fff;
        vertical-align: middle;
    }

    .prev {
        transform: rotate(180deg);
        background-position: center 50%;
    }

    .next {
        background-position: center 50%;
    }
    // ---------------

    // DAYS ----------
    .dow {
        width: 30px;
        height: 30px;
        padding-top: 40px;
        padding-bottom: 15px;
        font: rem(14)/1 $font1;
        font-weight: 700;
        color: $color1;
        text-align: center;
        vertical-align: middle;
        cursor: default;
    }
    // ---------------

    // DATES ---------
    .day {
        position: relative;
        width: 34px;
        height: 34px;
        font: 500 rem(14)/1 $font1;
        color: $color1;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            width: 32px;
            height: 32px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            transition: background 300ms;
        }

        // TODO: À ajouter à la classe voulu dans le calendrier lorsqu'il sera dynamisé
        &:after {
            content: '';
            position: absolute;
            width: 5px;
            height: 5px;
            top: 25%;
            left: 75%;
            transform: translate(-50%, -50%);
            background-color: $color1;
            z-index: 1;
            border-radius: 50%;
        }

        &.disabled, &.disabled:hover {
            background: none;
            cursor: default;

            &:after{
                display: none;
            }
        }

        &:hover {

            &:before {
                background: #bec2c8;
            }
        }
    }

    .old, .new {
        color: #738487 !important;

        &.day.active {
            color: #fff !important;
            &:before {
                background: rgba($color1, 0.5) !important;
            }
        }
        &.day.today {
            color: #fff !important;
            &:before {
                background: rgba($color2, 0.5) !important;
            }
        }
    }

    .day.today {
        color: #fff;

        &:before {
            background: $color1;
        }

        &:hover {
            color: #fff;

            &:before {
                background: $color1;
            }
        }
    }

    .day.active {
        color: $color1;
        cursor: default;

        &:before {
            background: $color2;
        }

        &:hover {
            color: $color1;

            &:before {
                background: $color2;
            }
        }
    }
    // ---------------

    // MONTHS --------
    .month, .year, .decade, .century {
        float: left;
        width: 25%;
        height: 30px;
        font: rem(12)/30px $font2;
        color: $color2;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;

        &:hover {
            background: #e9eaeb;
        }

        &.focused {
            color: #fff;
            background: $color1;
        }
    }
    // ---------------
}
