/*** |- ---------------------------------------- -| ***/
/*** |- LANDING IS ACTIVE ---------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showLanding {
    overflow-x: hidden;

	#landing {
        opacity: 1;
		visibility: visible;
	}
}


/*** |- ---------------------------------------- -| ***/
/*** |- TRANSITION IS ACTIVE ------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showTransition {
    overflow-x: hidden;

	#transition {
		opacity: 1;
        visibility: visible;
        transition: opacity 500ms;
	}
}


/*** |- ---------------------------------------- -| ***/
/*** |- CATÉGORY IS ACTIVE --------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_category.is_active {
    background: $color2;
    border-color: $color2;
}


/*** |- ---------------------------------------- -| ***/
/*** |- PAGE ACTIVE ---------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_pagination a.is_active { color: $color3; }


/*** |- ---------------------------------------- -| ***/
/*** |- BOUTON CALENDRIER ACTIF ---------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayCalendar:not(.closingOverlayCalendar) {
    .btnCalendar {
        svg { opacity: 0; }
        .btnX { opacity: 1; }
    }
}
