.l_pagination {
    text-align: center;

    a {
        padding: 10px;
        margin: 0 10px;
        font: 500 rem(18)/1 $font1;
        color: $color1;
        transition: color 300ms;
        vertical-align: middle;

        @media screen and (max-width: 500px) { padding: 10px; margin: 0 5px; }
        @media screen and (max-width: 320px) { padding: 10px; margin: 0; }

        &:hover {
            color: #aaadb2;
            svg { stroke: #aaadb2; }
        }

        &:first-child {
            margin-right: 20px;

            @media screen and (max-width: 500px) { margin-right: 10px; }

            svg {
                transform: rotate(180deg);
                top: 1px;
            }
        }

        &:last-child {
            margin-left: 20px;

            @media screen and (max-width: 500px) { margin-left: 10px; }

            svg { top: 3px; }
        }

        svg {
            position: relative;
            width: 12px;
            height: 15px;
            stroke: $color1;
            transition: stroke 300ms;
        }
    }
}
