@charset "UTF-8";
/*** ----- IMPORTS  ----- ***/
@import '../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import '../scripts/classes/overlay/overlay.min.css';
/*** ----- TECHNICAL ----- ***/
/*** ----- SELECTORS ----- ***/
/*** ----- EASE ----- ***/
/*** ----- COLORS ----- ***/
/*** ----- FONTS ----- ***/
/*** |- ---------------------------------------- -| ***/
/*** |- COMMON STYLES -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
/*** |- CREATE SHAPES -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
/*** |- MIXINS --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
/*** |- FUNCTIONS ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*** Blanko ***/
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

input:focus {
  outline: none;
}

a {
  -webkit-tap-highlight-color: transparent;
}

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important;
}

/*
html { overflow-x: hidden; }

strong, b { font-weight: bold; }
blockquote, q { quotes: none; }

table { border-collapse: collapse; border-spacing: 0; }



textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active,
button:hover, label:focus, .btn:active, .btn.active { outline: 0px !important; -webkit-appearance: none; }

textarea, input, input[type='text'], input[type='button'], input[type='submit'] { outline: none;
    -webkit-appearance: none !important; border-radius: 0px !important; outline: none !important; }

button, input[type="submit"], input[type="reset"] { background: none; color: inherit; border: none; padding: 0;
    font: inherit; cursor: pointer; outline: inherit; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill,
textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    border-bottom: 1px solid $color5;
    -webkit-text-fill-color: $color2;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}



fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
}
*/
/*** Grid ***/
.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.grid.gaps {
  width: calc(100% + 1% + 1%);
  margin-left: -1%;
  margin-right: -1%;
}
.grid.gaps .column {
  padding-right: 1%;
  padding-left: 1%;
}

.col1 {
  width: 8.3333333333%;
}

.col2 {
  width: 16.6666666667%;
}

.col3 {
  width: 25%;
}

.col4 {
  width: 33.3333333333%;
}

.col5 {
  width: 41.6666666667%;
}

.col6 {
  width: 50%;
}

.col7 {
  width: 58.3333333333%;
}

.col8 {
  width: 66.6666666667%;
}

.col9 {
  width: 75%;
}

.col10 {
  width: 83.3333333333%;
}

.col11 {
  width: 91.6666666667%;
}

.col12 {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 8.3333333333%;
  }
  .col2_1024 {
    width: 16.6666666667%;
  }
  .col3_1024 {
    width: 25%;
  }
  .col4_1024 {
    width: 33.3333333333%;
  }
  .col5_1024 {
    width: 41.6666666667%;
  }
  .col6_1024 {
    width: 50%;
  }
  .col7_1024 {
    width: 58.3333333333%;
  }
  .col8_1024 {
    width: 66.6666666667%;
  }
  .col9_1024 {
    width: 75%;
  }
  .col10_1024 {
    width: 83.3333333333%;
  }
  .col11_1024 {
    width: 91.6666666667%;
  }
  .col12_1024 {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .col1_768 {
    width: 8.3333333333%;
  }
  .col2_768 {
    width: 16.6666666667%;
  }
  .col3_768 {
    width: 25%;
  }
  .col4_768 {
    width: 33.3333333333%;
  }
  .col5_768 {
    width: 41.6666666667%;
  }
  .col6_768 {
    width: 50%;
  }
  .col7_768 {
    width: 58.3333333333%;
  }
  .col8_768 {
    width: 66.6666666667%;
  }
  .col9_768 {
    width: 75%;
  }
  .col10_768 {
    width: 83.3333333333%;
  }
  .col11_768 {
    width: 91.6666666667%;
  }
  .col12_768 {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .col1_425 {
    width: 8.3333333333%;
  }
  .col2_425 {
    width: 16.6666666667%;
  }
  .col3_425 {
    width: 25%;
  }
  .col4_425 {
    width: 33.3333333333%;
  }
  .col5_425 {
    width: 41.6666666667%;
  }
  .col6_425 {
    width: 50%;
  }
  .col7_425 {
    width: 58.3333333333%;
  }
  .col8_425 {
    width: 66.6666666667%;
  }
  .col9_425 {
    width: 75%;
  }
  .col10_425 {
    width: 83.3333333333%;
  }
  .col11_425 {
    width: 91.6666666667%;
  }
  .col12_425 {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .col1_375 {
    width: 8.3333333333%;
  }
  .col2_375 {
    width: 16.6666666667%;
  }
  .col3_375 {
    width: 25%;
  }
  .col4_375 {
    width: 33.3333333333%;
  }
  .col5_375 {
    width: 41.6666666667%;
  }
  .col6_375 {
    width: 50%;
  }
  .col7_375 {
    width: 58.3333333333%;
  }
  .col8_375 {
    width: 66.6666666667%;
  }
  .col9_375 {
    width: 75%;
  }
  .col10_375 {
    width: 83.3333333333%;
  }
  .col11_375 {
    width: 91.6666666667%;
  }
  .col12_375 {
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
  .col1_320 {
    width: 8.3333333333%;
  }
  .col2_320 {
    width: 16.6666666667%;
  }
  .col3_320 {
    width: 25%;
  }
  .col4_320 {
    width: 33.3333333333%;
  }
  .col5_320 {
    width: 41.6666666667%;
  }
  .col6_320 {
    width: 50%;
  }
  .col7_320 {
    width: 58.3333333333%;
  }
  .col8_320 {
    width: 66.6666666667%;
  }
  .col9_320 {
    width: 75%;
  }
  .col10_320 {
    width: 83.3333333333%;
  }
  .col11_320 {
    width: 91.6666666667%;
  }
  .col12_320 {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .col1_500 {
    width: 8.3333333333%;
  }
  .col2_500 {
    width: 16.6666666667%;
  }
  .col3_500 {
    width: 25%;
  }
  .col4_500 {
    width: 33.3333333333%;
  }
  .col5_500 {
    width: 41.6666666667%;
  }
  .col6_500 {
    width: 50%;
  }
  .col7_500 {
    width: 58.3333333333%;
  }
  .col8_500 {
    width: 66.6666666667%;
  }
  .col9_500 {
    width: 75%;
  }
  .col10_500 {
    width: 83.3333333333%;
  }
  .col11_500 {
    width: 91.6666666667%;
  }
  .col12_500 {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .col1_600 {
    width: 8.3333333333%;
  }
  .col2_600 {
    width: 16.6666666667%;
  }
  .col3_600 {
    width: 25%;
  }
  .col4_600 {
    width: 33.3333333333%;
  }
  .col5_600 {
    width: 41.6666666667%;
  }
  .col6_600 {
    width: 50%;
  }
  .col7_600 {
    width: 58.3333333333%;
  }
  .col8_600 {
    width: 66.6666666667%;
  }
  .col9_600 {
    width: 75%;
  }
  .col10_600 {
    width: 83.3333333333%;
  }
  .col11_600 {
    width: 91.6666666667%;
  }
  .col12_600 {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .col1_700 {
    width: 8.3333333333%;
  }
  .col2_700 {
    width: 16.6666666667%;
  }
  .col3_700 {
    width: 25%;
  }
  .col4_700 {
    width: 33.3333333333%;
  }
  .col5_700 {
    width: 41.6666666667%;
  }
  .col6_700 {
    width: 50%;
  }
  .col7_700 {
    width: 58.3333333333%;
  }
  .col8_700 {
    width: 66.6666666667%;
  }
  .col9_700 {
    width: 75%;
  }
  .col10_700 {
    width: 83.3333333333%;
  }
  .col11_700 {
    width: 91.6666666667%;
  }
  .col12_700 {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .col1_800 {
    width: 8.3333333333%;
  }
  .col2_800 {
    width: 16.6666666667%;
  }
  .col3_800 {
    width: 25%;
  }
  .col4_800 {
    width: 33.3333333333%;
  }
  .col5_800 {
    width: 41.6666666667%;
  }
  .col6_800 {
    width: 50%;
  }
  .col7_800 {
    width: 58.3333333333%;
  }
  .col8_800 {
    width: 66.6666666667%;
  }
  .col9_800 {
    width: 75%;
  }
  .col10_800 {
    width: 83.3333333333%;
  }
  .col11_800 {
    width: 91.6666666667%;
  }
  .col12_800 {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .col1_900 {
    width: 8.3333333333%;
  }
  .col2_900 {
    width: 16.6666666667%;
  }
  .col3_900 {
    width: 25%;
  }
  .col4_900 {
    width: 33.3333333333%;
  }
  .col5_900 {
    width: 41.6666666667%;
  }
  .col6_900 {
    width: 50%;
  }
  .col7_900 {
    width: 58.3333333333%;
  }
  .col8_900 {
    width: 66.6666666667%;
  }
  .col9_900 {
    width: 75%;
  }
  .col10_900 {
    width: 83.3333333333%;
  }
  .col11_900 {
    width: 91.6666666667%;
  }
  .col12_900 {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .col1_1000 {
    width: 8.3333333333%;
  }
  .col2_1000 {
    width: 16.6666666667%;
  }
  .col3_1000 {
    width: 25%;
  }
  .col4_1000 {
    width: 33.3333333333%;
  }
  .col5_1000 {
    width: 41.6666666667%;
  }
  .col6_1000 {
    width: 50%;
  }
  .col7_1000 {
    width: 58.3333333333%;
  }
  .col8_1000 {
    width: 66.6666666667%;
  }
  .col9_1000 {
    width: 75%;
  }
  .col10_1000 {
    width: 83.3333333333%;
  }
  .col11_1000 {
    width: 91.6666666667%;
  }
  .col12_1000 {
    width: 100%;
  }
}
@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 8.3333333333%;
  }
  .col2_1100 {
    width: 16.6666666667%;
  }
  .col3_1100 {
    width: 25%;
  }
  .col4_1100 {
    width: 33.3333333333%;
  }
  .col5_1100 {
    width: 41.6666666667%;
  }
  .col6_1100 {
    width: 50%;
  }
  .col7_1100 {
    width: 58.3333333333%;
  }
  .col8_1100 {
    width: 66.6666666667%;
  }
  .col9_1100 {
    width: 75%;
  }
  .col10_1100 {
    width: 83.3333333333%;
  }
  .col11_1100 {
    width: 91.6666666667%;
  }
  .col12_1100 {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 8.3333333333%;
  }
  .col2_1200 {
    width: 16.6666666667%;
  }
  .col3_1200 {
    width: 25%;
  }
  .col4_1200 {
    width: 33.3333333333%;
  }
  .col5_1200 {
    width: 41.6666666667%;
  }
  .col6_1200 {
    width: 50%;
  }
  .col7_1200 {
    width: 58.3333333333%;
  }
  .col8_1200 {
    width: 66.6666666667%;
  }
  .col9_1200 {
    width: 75%;
  }
  .col10_1200 {
    width: 83.3333333333%;
  }
  .col11_1200 {
    width: 91.6666666667%;
  }
  .col12_1200 {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 8.3333333333%;
  }
  .col2_1300 {
    width: 16.6666666667%;
  }
  .col3_1300 {
    width: 25%;
  }
  .col4_1300 {
    width: 33.3333333333%;
  }
  .col5_1300 {
    width: 41.6666666667%;
  }
  .col6_1300 {
    width: 50%;
  }
  .col7_1300 {
    width: 58.3333333333%;
  }
  .col8_1300 {
    width: 66.6666666667%;
  }
  .col9_1300 {
    width: 75%;
  }
  .col10_1300 {
    width: 83.3333333333%;
  }
  .col11_1300 {
    width: 91.6666666667%;
  }
  .col12_1300 {
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 8.3333333333%;
  }
  .col2_1400 {
    width: 16.6666666667%;
  }
  .col3_1400 {
    width: 25%;
  }
  .col4_1400 {
    width: 33.3333333333%;
  }
  .col5_1400 {
    width: 41.6666666667%;
  }
  .col6_1400 {
    width: 50%;
  }
  .col7_1400 {
    width: 58.3333333333%;
  }
  .col8_1400 {
    width: 66.6666666667%;
  }
  .col9_1400 {
    width: 75%;
  }
  .col10_1400 {
    width: 83.3333333333%;
  }
  .col11_1400 {
    width: 91.6666666667%;
  }
  .col12_1400 {
    width: 100%;
  }
}
@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 8.3333333333%;
  }
  .col2_1500 {
    width: 16.6666666667%;
  }
  .col3_1500 {
    width: 25%;
  }
  .col4_1500 {
    width: 33.3333333333%;
  }
  .col5_1500 {
    width: 41.6666666667%;
  }
  .col6_1500 {
    width: 50%;
  }
  .col7_1500 {
    width: 58.3333333333%;
  }
  .col8_1500 {
    width: 66.6666666667%;
  }
  .col9_1500 {
    width: 75%;
  }
  .col10_1500 {
    width: 83.3333333333%;
  }
  .col11_1500 {
    width: 91.6666666667%;
  }
  .col12_1500 {
    width: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 8.3333333333%;
  }
  .col2_1600 {
    width: 16.6666666667%;
  }
  .col3_1600 {
    width: 25%;
  }
  .col4_1600 {
    width: 33.3333333333%;
  }
  .col5_1600 {
    width: 41.6666666667%;
  }
  .col6_1600 {
    width: 50%;
  }
  .col7_1600 {
    width: 58.3333333333%;
  }
  .col8_1600 {
    width: 66.6666666667%;
  }
  .col9_1600 {
    width: 75%;
  }
  .col10_1600 {
    width: 83.3333333333%;
  }
  .col11_1600 {
    width: 91.6666666667%;
  }
  .col12_1600 {
    width: 100%;
  }
}
@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 8.3333333333%;
  }
  .col2_1700 {
    width: 16.6666666667%;
  }
  .col3_1700 {
    width: 25%;
  }
  .col4_1700 {
    width: 33.3333333333%;
  }
  .col5_1700 {
    width: 41.6666666667%;
  }
  .col6_1700 {
    width: 50%;
  }
  .col7_1700 {
    width: 58.3333333333%;
  }
  .col8_1700 {
    width: 66.6666666667%;
  }
  .col9_1700 {
    width: 75%;
  }
  .col10_1700 {
    width: 83.3333333333%;
  }
  .col11_1700 {
    width: 91.6666666667%;
  }
  .col12_1700 {
    width: 100%;
  }
}
@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 8.3333333333%;
  }
  .col2_1800 {
    width: 16.6666666667%;
  }
  .col3_1800 {
    width: 25%;
  }
  .col4_1800 {
    width: 33.3333333333%;
  }
  .col5_1800 {
    width: 41.6666666667%;
  }
  .col6_1800 {
    width: 50%;
  }
  .col7_1800 {
    width: 58.3333333333%;
  }
  .col8_1800 {
    width: 66.6666666667%;
  }
  .col9_1800 {
    width: 75%;
  }
  .col10_1800 {
    width: 83.3333333333%;
  }
  .col11_1800 {
    width: 91.6666666667%;
  }
  .col12_1800 {
    width: 100%;
  }
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("../fonts/FoundersGroteskLight.otf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("../fonts/FoundersGroteskRegular.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("../fonts/FoundersGroteskRegularItalic.otf");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("../fonts/FoundersGroteskMedium.otf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("../fonts/FoundersGroteskBold.woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Founders Grotesk Mono";
  src: url("../fonts/FoundersGroteskMonoMedium.woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/*** |- ---------------------------------------- -| ***/
/*** |- TITLES --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.hugeTitle {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 6.875rem;
  font-weight: 500;
  line-height: 0.9;
  color: #293548;
}
@media screen and (min-width: 1440px) {
  .hugeTitle {
    font-size: 6.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .hugeTitle {
    font-size: calc(2.5rem + (110 - 40) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .hugeTitle {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 1440px) {
  .hugeTitle.smaller {
    font-size: 4.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .hugeTitle.smaller {
    font-size: calc(2.5rem + (72 - 40) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .hugeTitle.smaller {
    font-size: 2.5rem;
  }
}

.largeTitle {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  line-height: 1;
  color: #a4b665;
}
@media screen and (min-width: 1440px) {
  .largeTitle {
    font-size: 3.4375rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .largeTitle {
    font-size: calc(1.875rem + (55 - 30) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .largeTitle {
    font-size: 1.875rem;
  }
}

.mediumTitle {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: #293548;
}
@media screen and (min-width: 1440px) {
  .mediumTitle {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .mediumTitle {
    font-size: calc(1.5rem + (30 - 24) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .mediumTitle {
    font-size: 1.5rem;
  }
}

.smallTitle {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  color: #293548;
}
@media screen and (min-width: 1440px) {
  .smallTitle {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .smallTitle {
    font-size: calc(1.25rem + (24 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .smallTitle {
    font-size: 1.25rem;
  }
}

.tinyTitle {
  font-family: "Founders Grotesk Mono", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  color: #c5dc76;
}

.minusculeTitle {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.3;
  color: #a4b665;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .minusculeTitle {
    font-size: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .minusculeTitle {
    font-size: 0.875rem;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- CONTENT -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
p {
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.5;
  color: #293548;
  font-size: 1.3125rem;
}
@media screen and (max-width: 768px) {
  p {
    font-size: 1.125rem;
  }
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.link {
  position: relative;
  padding-bottom: 6px;
  font-family: "Founders Grotesk", sans-serif;
  color: #293548;
  transition: color 300ms;
  font-size: 1.3125rem;
}
@media screen and (max-width: 768px) {
  .link {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 425px) {
  .link {
    font-size: 1.0625rem;
  }
}
.link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #293548;
  transition: background 300ms, width 300ms cubic-bezier(0.25, 1, 0.5, 1);
}
.link:hover {
  color: #a4b665;
}
.link:hover:before {
  width: 50%;
  background: #a4b665;
}
.link.t_white {
  color: #fff;
}
.link.t_white:before {
  background: #fff;
}

.btn {
  display: inline-block;
  position: relative;
  padding: 22px 90px 22px 30px;
  font: 500 0.875rem/1.3 "Founders Grotesk Mono", sans-serif;
  color: #293548;
  text-transform: uppercase;
  border: 1px solid #293548;
  background-color: white;
  cursor: pointer;
  outline: 0;
}
@media screen and (max-width: 425px) {
  .btn {
    padding: 14px 61px 14px 20px;
    font-size: 0.8125rem;
  }
}
.btn:hover span:before {
  right: 0%;
}
.btn span {
  position: absolute;
  width: 60px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background: #293548;
  overflow: hidden;
}
@media screen and (max-width: 425px) {
  .btn span {
    width: 41px;
  }
}
.btn span:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: #a4b665;
  transition: right 500ms cubic-bezier(0.25, 1, 0.5, 1);
}
.btn span:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../images/content/icons/whitePointer.svg") no-repeat center/contain;
  transition: 300ms;
}
@media screen and (max-width: 425px) {
  .btn span:after {
    width: 11px;
    height: 14px;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- WRAPPERS ------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.w1440 {
  max-width: 1440px;
  margin: 0 auto;
}
@media screen and (max-width: 1440px) {
  .w1440 {
    margin: 0;
  }
}

.w1000 {
  max-width: 1000px;
  margin: 0 auto;
}
@media screen and (max-width: 1000px) {
  .w1000 {
    margin: 0;
  }
}

.w900 {
  max-width: 900px;
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  .w900 {
    margin: 0;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- X PADDING ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.xp120 {
  padding-right: 120px;
  padding-left: 120px;
}
@media screen and (max-width: 1200px) {
  .xp120 {
    padding-right: 100px;
    padding-left: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .xp120 {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .xp120 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .xp120 {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.rp120 {
  padding-right: 120px;
}
@media screen and (max-width: 1200px) {
  .rp120 {
    padding-right: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .rp120 {
    padding-right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .rp120 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .rp120 {
    padding-right: 25px;
  }
}

.lp120 {
  padding-left: 120px;
}
@media screen and (max-width: 1200px) {
  .lp120 {
    padding-left: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .lp120 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .lp120 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .lp120 {
    padding-left: 25px;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- Y PADDING ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.yp120 {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media screen and (max-width: 1400px) {
  .yp120 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .yp120 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yp120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yp120 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .yp120 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tp120 {
  padding-top: 120px;
}
@media screen and (max-width: 1400px) {
  .tp120 {
    padding-top: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .tp120 {
    padding-top: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .tp120 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .tp120 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 500px) {
  .tp120 {
    padding-top: 50px;
  }
}

.bp120 {
  padding-bottom: 120px;
}
@media screen and (max-width: 1400px) {
  .bp120 {
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .bp120 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .bp120 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .bp120 {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .bp120 {
    padding-bottom: 50px;
  }
}

.yp80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .yp80 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .yp80 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tp80 {
  padding-top: 80px;
}
@media screen and (max-width: 768px) {
  .tp80 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 500px) {
  .tp80 {
    padding-top: 50px;
  }
}

.bp80 {
  padding-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .bp80 {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .bp80 {
    padding-bottom: 50px;
  }
}

.yp50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.tp50 {
  padding-top: 50px;
}

.bp50 {
  padding-bottom: 50px;
}

/*** |- ---------------------------------------- -| ***/
/*** |- MARGIN --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.bm50 {
  margin-bottom: 50px;
}
@media screen and (max-width: 1200px) {
  .bm50 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .bm50 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .bm50 {
    margin-bottom: 20px;
  }
}

.tm50 {
  margin-top: 50px;
}
@media screen and (max-width: 1200px) {
  .tm50 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .tm50 {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .tm50 {
    margin-top: 20px;
  }
}

.bm60 {
  margin-bottom: 60px;
}
@media screen and (max-width: 1200px) {
  .bm60 {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .bm60 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .bm60 {
    margin-bottom: 30px;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- IMAGES --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
picture {
  display: block;
  font-size: 0;
}
picture img {
  width: 100%;
  height: 100%;
}
picture.adaptive {
  position: relative;
  width: 100%;
  height: 100%;
}
picture.adaptive img {
  position: absolute;
  top: 0;
  left: 0;
}
picture [data-object-fit=cover] {
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover;";
}
picture [data-focus="0"] {
  object-position: left center;
}
picture [data-focus="1"] {
  object-position: center center;
}
picture [data-focus="2"] {
  object-position: right center;
}

/*** |- ---------------------------------------- -| ***/
/*** |- LANDING -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
#landing {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: #fff;
  visibility: hidden;
  z-index: 9998;
  transition: opacity 500ms, visibility 0ms ease 500ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- TRANSITION ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
#transition {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: #fff;
  visibility: hidden;
  z-index: 9998;
  transition: opacity 500ms, visibility 0ms ease 500ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- MISCELLANEOUS -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.noselect {
  user-select: none;
}

.clearfix {
  clear: both;
  display: block;
}

/*** |- ---------------------------------------- -| ***/
/*** |- MARQUEE -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_marquee {
  padding: 90px 0;
  background: #fff;
}
@media screen and (max-width: 1200px) {
  .l_marquee {
    padding: 70px 0;
  }
}
@media screen and (max-width: 1024px) {
  .l_marquee {
    padding: 50px 0;
  }
}
@media screen and (max-width: 768px) {
  .l_marquee {
    padding: 40px 0;
  }
}
.l_marquee h2 {
  display: block;
  width: 100%;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1;
  font-weight: 700;
  color: #a4b665;
  overflow: hidden;
}
@media screen and (min-width: 1440px) {
  .l_marquee h2 {
    font-size: 4.625rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .l_marquee h2 {
    font-size: calc(1.875rem + (74 - 30) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .l_marquee h2 {
    font-size: 1.875rem;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- NEWS ----------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_news {
  position: relative;
}
.l_news .content {
  position: relative;
}

/*** |- ---------------------------------------- -| ***/
/*** |- NEWS NAVIGATION ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.l_navigationNews .wrapper {
  display: flex;
}
.l_navigationNews .wrapper a {
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  transition: color 300ms;
  vertical-align: middle;
}
.l_navigationNews .wrapper a:hover {
  color: #a4b665;
}
.l_navigationNews .wrapper a:hover svg {
  stroke: #a4b665;
}
.l_navigationNews .wrapper a.prev svg {
  margin-right: 5px;
  transform: rotate(180deg);
}
.l_navigationNews .wrapper a.next {
  margin-left: auto;
}
.l_navigationNews .wrapper a.next svg {
  margin-left: 5px;
}
.l_navigationNews .wrapper a svg {
  position: relative;
  top: -1px;
  width: 14px;
  height: 14px;
  stroke: #293548;
  vertical-align: middle;
  transition: stroke 225ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- SEARCH RESULTS ------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_searchResults .result {
  width: 100%;
  padding: 40px 20px;
  border-bottom: 1px solid rgba(41, 53, 72, 0.3);
}
@media screen and (max-width: 1200px) {
  .l_searchResults .result {
    padding: 60px 60px;
  }
}
@media screen and (max-width: 1024px) {
  .l_searchResults .result {
    padding: 50px 50px;
  }
}
@media screen and (max-width: 768px) {
  .l_searchResults .result {
    padding: 40px 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_searchResults .result {
    padding: 30px 25px;
  }
}
.l_searchResults .result:first-child {
  border-top: 1px solid rgba(41, 53, 72, 0.3);
}
.l_searchResults .result mark {
  background: #c5dc76;
  font-style: normal;
  font-weight: normal;
}
.l_searchResults .result h2 {
  margin: 10px 0 0;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.2;
  color: #293548;
}
@media screen and (min-width: 1440px) {
  .l_searchResults .result h2 {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .l_searchResults .result h2 {
    font-size: calc(1.5625rem + (35 - 25) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .l_searchResults .result h2 {
    font-size: 1.5625rem;
  }
}
.l_searchResults .result p:not(.minusculeTitle) {
  margin: 20px 0 0;
}
.l_searchResults .result .link {
  display: inline-block;
  margin: 30px 0 0;
}
@media screen and (max-width: 768px) {
  .l_searchResults .result .link {
    margin-top: 25px;
  }
}
@media screen and (max-width: 600px) {
  .l_searchResults .result .link {
    margin-top: 20px;
  }
}
.l_searchResults .result:hover .link {
  color: #a4b665;
}
.l_searchResults .result:hover .link:before {
  width: 50%;
  background: #a4b665;
}

header {
  position: relative;
  z-index: 12;
}
header .content .grid {
  height: 130px;
}
@media screen and (max-width: 1200px) {
  header .content .grid {
    flex-flow: inherit;
  }
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  header .content .grid {
    height: 100px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  header .content .grid {
    height: 80px;
  }
}
header .content .grid .itemLogo {
  display: flex;
  width: 400px;
  align-items: center;
}
@media screen and (max-width: 1400px) {
  header .content .grid .itemLogo {
    width: 300px;
  }
}
@media screen and (max-width: 1300px) {
  header .content .grid .itemLogo {
    width: 250px;
  }
}
@media screen and (max-width: 1200px) {
  header .content .grid .itemLogo {
    width: 200px;
  }
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  header .content .grid .itemLogo {
    width: 150px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  header .content .grid .itemLogo {
    width: 120px;
  }
}
header .content .grid .itemLogo a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 35px;
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  header .content .grid .itemLogo a {
    padding: 0 25px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  header .content .grid .itemLogo a {
    padding: 0 15px;
  }
}
header .content .grid .itemLogo a picture {
  width: 130px;
}
@media screen and (max-width: 1300px) {
  header .content .grid .itemLogo a picture {
    width: 115px;
  }
}
@media screen and (max-width: 1200px), screen and (max-height: 750px) {
  header .content .grid .itemLogo a picture {
    width: 100%;
  }
}
header .content .grid .itemMain {
  display: flex;
  width: calc(100% - 400px);
  padding-right: 50px;
  margin-left: auto;
  align-items: center;
  background: #293548;
}
@media screen and (max-width: 1400px) {
  header .content .grid .itemMain {
    width: calc(100% - 300px);
  }
}
@media screen and (max-width: 1300px) {
  header .content .grid .itemMain {
    width: calc(100% - 250px);
    padding-right: 35px;
  }
}
@media screen and (max-width: 1200px) {
  header .content .grid .itemMain {
    width: calc(100% - 200px);
  }
}
@media screen and (max-width: 1024px) {
  header .content .grid .itemMain {
    width: calc(100% - 150px);
    padding-right: 30px;
  }
}
@media screen and (max-width: 850px) {
  header .content .grid .itemMain {
    width: calc(100% - 120px);
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  header .content .grid .itemMain {
    padding-right: 25px;
  }
}
header .content .grid .itemMain nav {
  display: flex;
  position: relative;
  height: 100%;
  padding: 0 75px;
}
@media screen and (max-width: 1600px) {
  header .content .grid .itemMain nav {
    padding: 0 60px;
  }
}
@media screen and (max-width: 1400px) {
  header .content .grid .itemMain nav {
    padding: 0 50px;
  }
}
@media screen and (max-width: 1200px) {
  header .content .grid .itemMain nav {
    padding: 0 40px;
  }
}
@media screen and (max-width: 1100px) {
  header .content .grid .itemMain nav {
    padding: 0 35px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  header .content .grid .itemMain nav {
    display: none;
  }
}
header .content .grid .itemMain nav div {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  font: 1.4375rem/1 "Founders Grotesk", sans-serif;
  color: #fff;
  white-space: nowrap;
}
@media screen and (max-width: 1200px) {
  header .content .grid .itemMain nav div {
    font-size: 1.3125rem;
  }
}
header .content .grid .itemMain nav div:before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 0px;
  background: #c5dc76;
  transition: height 300ms;
}
header .content .grid .itemMain nav div a {
  display: block;
}
header .content .grid .itemMain nav div:not(:first-child) {
  margin-left: 50px;
}
@media screen and (max-width: 1600px) {
  header .content .grid .itemMain nav div:not(:first-child) {
    margin-left: 40px;
  }
}
@media screen and (max-width: 1400px) {
  header .content .grid .itemMain nav div:not(:first-child) {
    margin-left: 35px;
  }
}
@media screen and (max-width: 1200px) {
  header .content .grid .itemMain nav div:not(:first-child) {
    margin-left: 25px;
  }
}
header .content .grid .itemMain form {
  width: 100%;
}
@media screen and (max-width: 1024px), screen and (max-height: 599px) {
  header .content .grid .itemMain form {
    display: none;
  }
}
header .content .grid .itemMain .mobile {
  display: flex;
  align-items: center;
  margin-left: auto;
}
header .content .grid .itemMain .mobile .btnMenu {
  display: none;
  position: relative;
  margin-right: 30px;
  font: 300 0.75rem/1 "Founders Grotesk", sans-serif;
  color: #fff;
  text-transform: uppercase;
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  header .content .grid .itemMain .mobile .btnMenu {
    display: inline-flex;
  }
}
header .content .grid .itemMain .mobile .btnMenu > span {
  position: relative;
  margin-right: 10px;
  top: 3px;
}
header .content .grid .itemMain .mobile .btnSearch {
  display: none;
  position: relative;
}
@media screen and (max-width: 1024px), screen and (max-height: 599px) {
  header .content .grid .itemMain .mobile .btnSearch {
    display: inline-flex;
  }
}
header .content .grid .itemMain .mobile .btnSearch svg {
  width: 22px;
  height: 22px;
  stroke: #fff;
}
header .content .grid .itemMain .mobile .btnSearch .btnX {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: auto;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
header .content .grid .itemMain .mobile .btnSearch .btnX:before, header .content .grid .itemMain .mobile .btnSearch .btnX:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #fff;
  transition: background 300ms;
}
header .content .grid .itemMain .mobile .btnSearch .btnX:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
header .content .grid .itemMain .mobile .btnSearch .btnX:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

footer .content {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
footer .content .backtoTopWrapper {
  position: relative;
  width: 200px;
}
@media screen and (max-width: 1024px) {
  footer .content .backtoTopWrapper {
    width: 150px;
  }
}
@media screen and (max-width: 850px) {
  footer .content .backtoTopWrapper {
    width: 120px;
  }
}
@media screen and (max-width: 600px) {
  footer .content .backtoTopWrapper {
    display: none;
  }
}
footer .content .backtoTopWrapper .backToTop {
  position: absolute;
  width: 90px;
  height: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1024px) {
  footer .content .backtoTopWrapper .backToTop {
    width: 70px;
  }
}
@media screen and (max-width: 850px) {
  footer .content .backtoTopWrapper .backToTop {
    width: 60px;
  }
}
footer .content .backtoTopWrapper .backToTop:hover div:before {
  transform: scale(1.1);
}
footer .content .backtoTopWrapper .backToTop div {
  position: relative;
  width: 90px;
  height: 90px;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  footer .content .backtoTopWrapper .backToTop div {
    width: 70px;
    height: 70px;
  }
}
@media screen and (max-width: 850px) {
  footer .content .backtoTopWrapper .backToTop div {
    width: 60px;
    height: 60px;
  }
}
footer .content .backtoTopWrapper .backToTop div:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #a4b665;
  border-radius: 50%;
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1), background 600ms;
}
footer .content .backtoTopWrapper .backToTop div svg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  opacity: 1;
  fill: #fff;
  stroke: #fff;
  transform: translate(-50%, -50%) rotate(-90deg);
  transition: opacity 600ms;
}
footer .content .backtoTopWrapper .backToTop span {
  display: block;
  width: 158px;
  height: 162px;
  font: 500 0.9375rem/90px "Founders Grotesk", sans-serif;
  color: #293548;
  text-transform: uppercase;
  white-space: nowrap;
  word-spacing: 6px;
  transform: rotate(-90deg);
}
@media screen and (max-width: 1024px) {
  footer .content .backtoTopWrapper .backToTop span {
    line-height: 70px;
  }
}
@media screen and (max-width: 850px) {
  footer .content .backtoTopWrapper .backToTop span {
    line-height: 60px;
  }
}
footer .content .wrapper {
  width: calc(100% - 200px);
  padding-right: 200px;
  padding-left: 200px;
  background: #293548;
}
@media screen and (max-width: 1400px) {
  footer .content .wrapper {
    padding-right: 100px;
    padding-left: 100px;
  }
}
@media screen and (max-width: 1200px) {
  footer .content .wrapper {
    padding-right: 90px;
    padding-left: 90px;
  }
}
@media screen and (max-width: 1024px) {
  footer .content .wrapper {
    width: calc(100% - 150px);
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 850px) {
  footer .content .wrapper {
    width: calc(100% - 120px);
  }
}
@media screen and (max-width: 768px) {
  footer .content .wrapper {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  footer .content .wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  footer .content .wrapper .infos {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  footer .content .wrapper .links {
    display: none;
  }
}
footer .content .wrapper .links li:not(:last-child) {
  margin-bottom: 30px;
}
@media screen and (max-width: 1200px) {
  footer .content .wrapper .links li:not(:last-child) {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 1024px) {
  footer .content .wrapper .links li:not(:last-child) {
    margin-bottom: 20px;
  }
}
footer .content .wrapper .links li a {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 2.1875rem;
  line-height: 1.3;
  color: #fff;
  transition: color 300ms;
}
@media screen and (max-width: 1200px) {
  footer .content .wrapper .links li a {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  footer .content .wrapper .links li a {
    font-size: 1.8125rem;
  }
}
@media screen and (max-width: 850px) {
  footer .content .wrapper .links li a {
    font-size: 1.6875rem;
  }
}
@media screen and (max-width: 768px) {
  footer .content .wrapper .links li a {
    font-size: 1.5625rem;
  }
}
footer .content .wrapper .links li a:hover {
  color: #a4b665;
}
footer .content .wrapper h2 {
  margin-bottom: 5px;
  font: 500 1.375rem/1 "Founders Grotesk", sans-serif;
  color: #fff;
}
footer .content .wrapper p {
  font-size: 1.25rem;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  footer .content .wrapper p {
    font-size: 1.125rem;
  }
}
footer .content .wrapper .tel {
  margin: 10px 0 20px;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 2.1875rem;
  line-height: 1.3;
  color: #fff;
  transition: color 300ms;
}
@media screen and (max-width: 1200px) {
  footer .content .wrapper .tel {
    font-size: 2rem;
    margin: 10px 0 15px;
  }
}
@media screen and (max-width: 1024px) {
  footer .content .wrapper .tel {
    font-size: 1.8125rem;
  }
}
@media screen and (max-width: 850px) {
  footer .content .wrapper .tel {
    font-size: 1.6875rem;
  }
}
@media screen and (max-width: 768px) {
  footer .content .wrapper .tel {
    font-size: 1.5625rem;
    margin-bottom: 10px;
  }
}
footer .content .wrapper .tel:hover {
  color: #a4b665;
}
footer .content .wrapper form {
  margin-top: 50px;
}
@media screen and (max-width: 1200px) {
  footer .content .wrapper form {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1024px) {
  footer .content .wrapper form {
    margin-top: 30px;
  }
}
footer .content .wrapper form .title {
  margin-bottom: 15px;
}
footer .content .wrapper .follow {
  margin-top: 30px;
}
@media screen and (max-width: 1024px) {
  footer .content .wrapper .follow {
    margin-top: 25px;
  }
}
@media screen and (max-width: 768px) {
  footer .content .wrapper .follow {
    margin-top: 20px;
  }
}
footer .content .wrapper .follow span {
  position: relative;
  top: 1px;
}
footer .content .wrapper .follow .facebook {
  margin: 0 40px 0 15px;
}
@media screen and (max-width: 600px) {
  footer .content .wrapper .follow .facebook {
    margin: 0 20px 0 10px;
  }
}
footer .content .wrapper .follow .facebook:hover svg {
  fill: #a4b665;
}
footer .content .wrapper .follow .facebook svg {
  width: 24px;
  height: 24px;
  fill: #fff;
  vertical-align: middle;
  transition: fill 300ms;
}
footer .content .wrapper .follow img {
  height: 24px;
  vertical-align: middle;
}
footer .content .copyright {
  margin-top: 90px;
}
@media screen and (max-width: 1024px) {
  footer .content .copyright {
    margin-top: 70px;
  }
}
@media screen and (max-width: 768px) {
  footer .content .copyright {
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  footer .content .copyright {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  footer .content .copyright .itemCopyright {
    width: calc(100% - 97px);
  }
}
@media screen and (max-width: 768px) {
  footer .content .copyright .itemBlanko {
    width: 97px;
  }
}
@media screen and (max-width: 600px) {
  footer .content .copyright .itemBlanko {
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  footer .content .copyright .itemCopyright, footer .content .copyright .itemBlanko {
    width: 100%;
  }
}
footer .content .copyright p, footer .content .copyright a {
  font: 300 0.8125rem/1 "Founders Grotesk", sans-serif;
  color: rgba(255, 255, 255, 0.6);
}
footer .content .copyright a {
  display: block;
  transition: color 300ms;
}
footer .content .copyright a:hover {
  color: #fff;
}

.l_alerts {
  position: relative;
  width: 100%;
  height: 0px;
  left: 0;
  background: #de0524;
  overflow: hidden;
  z-index: 12;
  transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
.l_alerts .slickAlerts {
  width: auto;
  height: 50px;
  margin: 0px 56px 0 69px;
  padding: 15px 20px;
}
@media screen and (max-width: 768px) {
  .l_alerts .slickAlerts {
    margin: 0px 46px 0 59px;
  }
}
@media screen and (max-width: 600px) {
  .l_alerts .slickAlerts {
    margin: 0px 41px 0 54px;
  }
}
@media screen and (max-width: 500px) {
  .l_alerts .slickAlerts {
    margin: 0px 26px 0 39px;
    height: 44px;
  }
}
.l_alerts .slickAlerts .slick-track, .l_alerts .slickAlerts .slick-slide {
  font-size: 0;
}
.l_alerts .slickAlerts a, .l_alerts .slickAlerts p {
  position: relative;
  top: 1px;
  display: block;
  padding-bottom: 3px;
  margin-bottom: 0;
  width: 100%;
  font: 1rem/1 "Founders Grotesk", sans-serif;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (max-width: 500px) {
  .l_alerts .slickAlerts a, .l_alerts .slickAlerts p {
    font-size: 0.8125rem;
    padding-bottom: 0;
  }
}
.l_alerts .slickAlerts a {
  opacity: 1;
  transition: opacity 300ms;
}
.l_alerts .slickAlerts a:hover {
  opacity: 0.6;
}
.l_alerts .slickAlerts div {
  text-align: center;
}
.l_alerts .slickNav {
  position: absolute;
  top: 50%;
  left: 40px;
  font: 0.8125rem/1 "Founders Grotesk", sans-serif;
  color: #fff;
  transform: translateY(-50%);
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .l_alerts .slickNav {
    left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_alerts .slickNav {
    left: 25px;
  }
}
@media screen and (max-width: 500px) {
  .l_alerts .slickNav {
    left: 10px;
  }
}
.l_alerts .close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 40px;
  z-index: 1;
  transform: translateY(-50%);
  transition: opacity 300ms;
}
@media screen and (max-width: 768px) {
  .l_alerts .close {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_alerts .close {
    right: 25px;
  }
}
@media screen and (max-width: 500px) {
  .l_alerts .close {
    right: 10px;
  }
}
.l_alerts .close:before, .l_alerts .close:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 14px;
  top: 50%;
  left: 50%;
  background: #fff;
}
.l_alerts .close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.l_alerts .close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.l_alerts .close:hover {
  opacity: 0.6;
}

.showAlerts .l_alerts, .showTransition.showAlerts .l_alerts {
  height: 50px;
}
@media screen and (max-width: 500px) {
  .showAlerts .l_alerts, .showTransition.showAlerts .l_alerts {
    height: 44px;
  }
}

.showOverlayDropdown:not(.closingOverlayDropdown).showAlerts .l_alerts,
.showOverlaySearch:not(.closingOverlaySearch).showAlerts .l_alerts,
.showOverlayMenu:not(.closingOverlayMenu).showAlerts .l_alerts {
  height: 0px;
}

#overlayMenu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  z-index: 10;
}
#overlayMenu .wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  right: -100%;
  bottom: 0;
  background: #fff;
  overflow: hidden;
  z-index: 99;
}
#overlayMenu .wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, white 30%, white 100%);
  z-index: 1;
}
#overlayMenu .wrapper .linksContainer {
  position: absolute;
  width: 100%;
  padding: 110px 0;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  opacity: 0;
  transform: translateX(-100px);
}
#overlayMenu .wrapper .linksContainer::-webkit-scrollbar {
  display: none;
}
#overlayMenu .wrapper .linksContainer > div:not(:first-child) {
  margin-top: 10px;
}
#overlayMenu h2 {
  padding: 15px 60px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.3;
  color: #293548;
}
@media screen and (min-width: 850px) {
  #overlayMenu h2 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 850px) {
  #overlayMenu h2 {
    font-size: calc(1.625rem + (40 - 26) * (100vw - 20rem) / (850 - 320));
  }
}
@media screen and (max-width: 320px) {
  #overlayMenu h2 {
    font-size: 1.625rem;
  }
}
@media screen and (max-width: 768px) {
  #overlayMenu h2 {
    padding: 10px 30px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu h2 {
    padding: 10px 25px;
  }
}
#overlayMenu .groundFloor {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #overlayMenu .groundFloor {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu .groundFloor {
    margin-bottom: 10px;
  }
}
#overlayMenu .groundFloor > li {
  overflow: hidden;
  border-bottom: 1px solid rgba(41, 53, 72, 0);
  transition: border 300ms, margin 300ms;
}
#overlayMenu .groundFloor > li > a {
  position: relative;
  width: 100%;
  max-width: 600px;
  padding: 20px 80px 10px 60px;
  font: 500 1.125rem/1.1 "Founders Grotesk Mono", sans-serif;
  color: #293548;
  text-transform: uppercase;
  overflow: hidden;
  transition: color 300ms;
}
@media screen and (max-width: 768px) {
  #overlayMenu .groundFloor > li > a {
    padding: 20px 55px 5px 30px;
    font-size: 1rem;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu .groundFloor > li > a {
    padding: 20px 50px 5px 25px;
    font-size: 0.9375rem;
  }
}
#overlayMenu .groundFloor > li > a span {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 20px;
  right: 60px;
}
@media screen and (max-width: 768px) {
  #overlayMenu .groundFloor > li > a span {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu .groundFloor > li > a span {
    right: 25px;
  }
}
#overlayMenu .groundFloor > li > a span:before, #overlayMenu .groundFloor > li > a span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #293548;
}
#overlayMenu .groundFloor > li > a span:before {
  width: 2px;
  height: 14px;
  transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
#overlayMenu .groundFloor > li > a span:after {
  width: 14px;
  height: 2px;
}
#overlayMenu .groundFloor > li .btnSubLvl1 {
  position: relative;
}
#overlayMenu .other {
  margin-top: 30px;
}
#overlayMenu .other a {
  position: relative;
  padding: 5px 60px;
  transition: opacity 300ms, padding 300ms;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.5;
  color: #293548;
  font-size: 1.3125rem;
}
@media screen and (max-width: 768px) {
  #overlayMenu .other a {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 768px) {
  #overlayMenu .other a {
    padding: 5px 30px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu .other a {
    padding: 5px 25px;
  }
}
#overlayMenu .subLvl1 {
  display: none;
  opacity: 0;
  overflow: hidden;
  transform: translateY(30px);
  transition: opacity 300ms, transform 300ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
#overlayMenu .subLvl1 li:first-child a {
  padding-top: 10px;
}
#overlayMenu .subLvl1 li:last-child a {
  padding-bottom: 20px;
}
#overlayMenu .subLvl1 li a {
  padding: 5px 60px;
  font: 1.125rem/1.5 "Founders Grotesk", sans-serif;
  color: #293548;
  transition: color 300ms;
}
@media screen and (max-width: 768px) {
  #overlayMenu .subLvl1 li a {
    padding: 5px 30px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu .subLvl1 li a {
    padding: 5px 25px;
  }
}
#overlayMenu .subLvl1 li a:hover {
  color: #293548;
}

#overlayShare {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  visibility: hidden;
  z-index: 2001;
}
#overlayShare::-webkit-scrollbar {
  display: none;
}
#overlayShare .wrapper {
  position: absolute;
  padding: 85px;
  top: 50%;
  left: 50%;
  background: #fff;
  pointer-events: none;
  text-align: center;
  z-index: 151;
  opacity: 0;
  transform: translate(-50%, -25%);
  transition: opacity 300ms, transform 300ms;
}
@media screen and (max-width: 1024px) {
  #overlayShare .wrapper {
    padding: 50px;
  }
}
@media screen and (max-width: 600px) {
  #overlayShare .wrapper {
    width: auto;
    right: 20px;
    left: 20px;
    padding: 50px 20px;
    transform: translateY(-25%);
  }
}
#overlayShare .wrapper h3 {
  margin-bottom: 20px;
}
#overlayShare .wrapper .close {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
#overlayShare .wrapper .close:hover:before, #overlayShare .wrapper .close:hover:after {
  background: #a4b665;
}
#overlayShare .wrapper .close:before, #overlayShare .wrapper .close:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #293548;
  transition: background 300ms;
}
#overlayShare .wrapper .close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
#overlayShare .wrapper .close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
#overlayShare .wrapper ul {
  position: relative;
  margin-top: 16px;
}
#overlayShare .wrapper ul li {
  display: inline-block;
}
#overlayShare .wrapper ul li:not(:last-child) {
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  #overlayShare .wrapper ul li:not(:last-child) {
    margin-right: 10px;
  }
}
#overlayShare .wrapper ul li a {
  position: relative;
  width: 45px;
  height: 45px;
  background: #293548;
  border-radius: 50%;
  transition: background 300ms;
}
#overlayShare .wrapper ul li a svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  fill: #fff;
  transform: translate(-50%, -50%);
}
#overlayShare .wrapper ul li a svg use {
  fill: #fff;
}
#overlayShare .wrapper ul li a:hover {
  background: #a4b665;
}

#overlaySearch {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  z-index: 10;
}
#overlaySearch .wrapper {
  position: relative;
  width: 100%;
  height: 0px;
  margin-left: auto;
  background: #fff;
  overflow: hidden;
  z-index: 81;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
#overlaySearch .wrapper .f_search {
  padding: 130px 50px 30px;
}
@media screen and (max-width: 850px) {
  #overlaySearch .wrapper .f_search {
    padding-top: 110px;
  }
}
@media screen and (max-width: 768px) {
  #overlaySearch .wrapper .f_search {
    padding: 100px 30px 20px;
  }
}

#overlayDropdown {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 10;
  pointer-events: none;
}
@media screen and (max-height: 599px), screen and (max-width: 850px) {
  #overlayDropdown {
    display: none;
  }
}
#overlayDropdown .btnCloseDropdown {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 45px 0 30px;
  background: #fff;
  opacity: 0;
  text-align: center;
  z-index: 1;
}
@media screen and (max-height: 800px) {
  #overlayDropdown .btnCloseDropdown {
    padding: 35px 0 20px;
  }
}
#overlayDropdown .btnCloseDropdown:hover svg {
  top: 15px;
}
@media screen and (max-height: 800px) {
  #overlayDropdown .btnCloseDropdown:hover svg {
    top: 10px;
  }
}
#overlayDropdown .btnCloseDropdown:before {
  content: "";
  position: absolute;
  top: 53px;
  left: calc(50% - 56px);
  right: calc(50% - 56px);
  height: 1px;
  background: rgba(41, 53, 72, 0.2);
}
@media screen and (max-height: 800px) {
  #overlayDropdown .btnCloseDropdown:before {
    top: 43px;
  }
}
#overlayDropdown .btnCloseDropdown span {
  position: relative;
  padding: 0 20px;
  font: 500 0.9375rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  background: #fff;
  text-transform: uppercase;
  z-index: 2;
}
#overlayDropdown .btnCloseDropdown svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 25px;
  left: 50%;
  stroke: #293548;
  transform: translateX(-50%) rotate(-90deg);
  transition: top 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
@media screen and (max-height: 800px) {
  #overlayDropdown .btnCloseDropdown svg {
    top: 16px;
  }
}
#overlayDropdown .wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 100%;
  left: 0;
  background: #f4f5f6;
  z-index: 92;
  overflow: hidden;
}
#overlayDropdown .wrapper .section {
  position: absolute;
  width: 100%;
  padding: 200px 100px 100px;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  transform: translateY(50px);
}
@media screen and (max-height: 800px) {
  #overlayDropdown .wrapper .section {
    padding-top: 175px;
  }
}
@media screen and (max-width: 1300px) {
  #overlayDropdown .wrapper .section {
    padding-right: 75px;
    padding-left: 75px;
  }
}
@media screen and (max-width: 1200px) {
  #overlayDropdown .wrapper .section {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 1100px) {
  #overlayDropdown .wrapper .section {
    padding-right: 35px;
    padding-left: 35px;
  }
}
@media screen and (max-width: 1024px) {
  #overlayDropdown .wrapper .section {
    padding-top: 150px;
  }
}
#overlayDropdown .wrapper .section::-webkit-scrollbar {
  display: none;
}
#overlayDropdown .wrapper .section > div {
  max-width: 1300px;
  margin: 0 auto;
}
#overlayDropdown .wrapper .section h2 {
  margin-bottom: 50px;
  font: 2.4375rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  opacity: 0;
  transform: translateY(50px);
}
@media screen and (max-height: 800px), screen and (max-width: 1300px) {
  #overlayDropdown .wrapper .section h2 {
    margin-bottom: 40px;
  }
}
@media screen and (max-height: 750px), screen and (max-width: 1200px) {
  #overlayDropdown .wrapper .section h2 {
    margin-bottom: 30px;
  }
}
@media screen and (max-height: 700px), screen and (max-width: 1024px) {
  #overlayDropdown .wrapper .section h2 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 1200px) {
  #overlayDropdown .wrapper .section h2 {
    font-size: 2.125rem;
  }
}
@media screen and (max-width: 1024px) {
  #overlayDropdown .wrapper .section h2 {
    font-size: 1.875rem;
  }
}
#overlayDropdown .wrapper .section .grid {
  flex-flow: nowrap;
}
#overlayDropdown .wrapper .section .grid > div {
  width: 100%;
  opacity: 0;
  transform: translateY(50px);
}
#overlayDropdown .wrapper .section .grid > div.itemLinks {
  padding-right: 20px;
}
#overlayDropdown .wrapper .section .grid > div.itemLinks h3 {
  min-height: 38px;
  font: 500 0.9375rem/1.3 "Founders Grotesk Mono", sans-serif;
  color: #a4b665;
  text-transform: uppercase;
}
#overlayDropdown .wrapper .section .grid > div.itemLinks li {
  margin-top: 20px;
}
@media screen and (max-height: 800px), screen and (max-width: 1200px) {
  #overlayDropdown .wrapper .section .grid > div.itemLinks li {
    margin-top: 15px;
  }
}
@media screen and (max-height: 700px), screen and (max-width: 1024px) {
  #overlayDropdown .wrapper .section .grid > div.itemLinks li {
    margin-top: 10px;
  }
}
#overlayDropdown .wrapper .section .grid > div.itemLinks li:first-child {
  margin-top: 10px;
}
#overlayDropdown .wrapper .section .grid > div.itemLinks li a {
  font: 1.0625rem/1.2 "Founders Grotesk", sans-serif;
  color: #293548;
  transition: color 300ms;
}
#overlayDropdown .wrapper .section .grid > div.itemLinks li a:hover {
  color: rgba(41, 53, 72, 0.5);
}
@media screen and (max-width: 1024px) {
  #overlayDropdown .wrapper .section .grid > div.itemLinks li a {
    font-size: 1rem;
  }
}
#overlayDropdown .wrapper .section .grid > div.itemFeatured {
  width: 100%;
  max-width: 200px;
  margin-top: -90px;
}
@media screen and (max-height: 800px), screen and (max-width: 1300px) {
  #overlayDropdown .wrapper .section .grid > div.itemFeatured {
    margin-top: -60px;
  }
}
@media screen and (max-height: 750px), screen and (max-width: 1200px) {
  #overlayDropdown .wrapper .section .grid > div.itemFeatured {
    margin-top: -50px;
  }
}
@media screen and (max-height: 700px) {
  #overlayDropdown .wrapper .section .grid > div.itemFeatured {
    margin-top: 0;
  }
}
@media screen and (max-width: 1024px) {
  #overlayDropdown .wrapper .section .grid > div.itemFeatured {
    display: none;
  }
}
#overlayDropdown .wrapper .section .grid > div.itemFeatured a {
  position: relative;
  display: block;
}
#overlayDropdown .wrapper .section .grid > div.itemFeatured a:hover picture img {
  transform: scale(1.1);
}
#overlayDropdown .wrapper .section .grid > div.itemFeatured a picture {
  position: relative;
  height: 350px;
  overflow: hidden;
}
@media screen and (max-height: 800px), screen and (max-width: 1300px) {
  #overlayDropdown .wrapper .section .grid > div.itemFeatured a picture {
    height: 300px;
  }
}
#overlayDropdown .wrapper .section .grid > div.itemFeatured a picture:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(41, 53, 72, 0.7);
}
#overlayDropdown .wrapper .section .grid > div.itemFeatured a picture img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
#overlayDropdown .wrapper .section .grid > div.itemFeatured a span {
  position: absolute;
  top: 50%;
  left: 50%;
  font: 500 1.875rem/1.2 "Founders Grotesk", sans-serif;
  color: #fff;
  text-align: center;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1200px) {
  #overlayDropdown .wrapper .section .grid > div.itemFeatured a span {
    font-size: 1.6875rem;
  }
}
@media screen and (max-width: 1024px) {
  #overlayDropdown .wrapper .section .grid > div.itemFeatured a span {
    font-size: 1.5rem;
  }
}
#overlayDropdown .wrapper .section .grid > div.itemFeatured a div {
  position: absolute;
  width: 66px;
  height: 66px;
  right: 0;
  bottom: 0;
  background: #c5dc76;
}
@media screen and (max-height: 800px), screen and (max-width: 1300px) {
  #overlayDropdown .wrapper .section .grid > div.itemFeatured a div {
    width: 56px;
    height: 56px;
  }
}
@media screen and (max-height: 750px), screen and (max-width: 1200px) {
  #overlayDropdown .wrapper .section .grid > div.itemFeatured a div {
    width: 46px;
    height: 46px;
  }
}
#overlayDropdown .wrapper .section .grid > div.itemFeatured a div svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  stroke: #fff;
  transform: translate(-50%, -50%);
}

#overlayCalendar {
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  visibility: hidden;
  z-index: 5;
  pointer-events: none;
}
#overlayCalendar::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 600px) {
  #overlayCalendar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
  }
}
#overlayCalendar .wrapper {
  position: relative;
  pointer-events: none;
  text-align: center;
  width: 350px;
  z-index: 5;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 600ms cubic-bezier(0.33, 1, 0.68, 1), transform 600ms cubic-bezier(0.33, 1, 0.68, 1);
}
@media screen and (max-width: 600px) {
  #overlayCalendar .wrapper {
    position: absolute;
    width: auto;
    padding-top: 25px;
    top: 50%;
    right: 25px;
    left: 25px;
    transform: translateY(-30%);
    z-index: 11;
  }
}

#overlayFastLinks {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}
#overlayFastLinks .wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: #c5dc76;
  overflow: hidden;
  scrollbar-width: none;
  z-index: 99;
}
@media screen and (max-height: 550px) {
  #overlayFastLinks .wrapper {
    display: block;
    overflow: auto;
  }
}
@media screen and (max-width: 1024px) and (min-width: 901px) and (max-height: 1100px) {
  #overlayFastLinks .wrapper {
    display: block;
    overflow: auto;
  }
}
@media screen and (max-width: 900px) and (min-width: 769px) and (max-height: 1000px) {
  #overlayFastLinks .wrapper {
    display: block;
    overflow: auto;
  }
}
@media screen and (max-width: 768px) and (min-width: 600px) and (max-height: 900px) {
  #overlayFastLinks .wrapper {
    display: block;
    overflow: auto;
  }
}
@media screen and (max-width: 600px) and (max-height: 650px) {
  #overlayFastLinks .wrapper {
    display: block;
    overflow: auto;
  }
}
@media screen and (max-width: 500px) {
  #overlayFastLinks .wrapper {
    overflow: auto;
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (max-width: 375px) {
  #overlayFastLinks .wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }
}
#overlayFastLinks .wrapper::-webkit-scrollbar {
  display: none;
}
#overlayFastLinks .wrapper .sideWords {
  position: fixed;
  top: 50%;
  right: -165px;
  font: 700 10.3125rem/1 "Founders Grotesk", sans-serif;
  color: #c5dc76;
  -webkit-text-fill-color: #c5dc76;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  white-space: nowrap;
  writing-mode: vertical-lr;
  transform: translateY(-50%) rotate(180deg);
}
@media screen and (max-width: 1300px), screen and (max-height: 750px) {
  #overlayFastLinks .wrapper .sideWords {
    font-size: 8.75rem;
  }
}
@media screen and (max-width: 1100px), screen and (max-height: 700px) {
  #overlayFastLinks .wrapper .sideWords {
    font-size: 7.5rem;
  }
}
@media screen and (max-width: 768px) {
  #overlayFastLinks .wrapper .sideWords {
    font-size: 6.25rem;
  }
}
@media screen and (max-width: 600px), screen and (max-height: 600px) {
  #overlayFastLinks .wrapper .sideWords {
    display: none;
  }
}
#overlayFastLinks .wrapper > div {
  width: 100%;
  max-width: 1100px;
  padding: 50px 0;
  margin: 0 auto;
}
@media screen and (max-width: 1300px), screen and (max-height: 750px) {
  #overlayFastLinks .wrapper > div {
    max-width: 850px;
  }
}
@media screen and (max-width: 1100px), screen and (max-height: 700px) {
  #overlayFastLinks .wrapper > div {
    max-width: 750px;
  }
}
@media screen and (max-width: 1024px) {
  #overlayFastLinks .wrapper > div {
    max-width: 750px;
  }
}
@media screen and (max-width: 900px) {
  #overlayFastLinks .wrapper > div {
    max-width: 650px;
  }
}
@media screen and (max-width: 768px) {
  #overlayFastLinks .wrapper > div {
    max-width: 550px;
    padding: 30px 0;
  }
}
@media screen and (max-width: 700px) {
  #overlayFastLinks .wrapper > div {
    max-width: 500px;
  }
}
@media screen and (max-width: 600px) {
  #overlayFastLinks .wrapper > div {
    max-width: 400px;
  }
}
@media screen and (max-width: 500px) {
  #overlayFastLinks .wrapper > div .grid {
    width: calc(100% + 1% + 1%);
    margin-left: -1%;
    margin-right: -1%;
  }
}
#overlayFastLinks .wrapper > div .column:nth-child(-n+3) {
  margin-top: 0;
}
#overlayFastLinks .wrapper > div .column:not(:nth-child(-n+3)) {
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  #overlayFastLinks .wrapper > div .column:nth-child(-n+2) {
    margin-top: 0;
  }
  #overlayFastLinks .wrapper > div .column:not(:nth-child(-n+2)) {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  #overlayFastLinks .wrapper > div .column:nth-child(-n+2) {
    margin-top: 0;
  }
  #overlayFastLinks .wrapper > div .column:not(:nth-child(-n+2)) {
    margin-top: 15px;
  }
}
@media screen and (max-width: 500px) {
  #overlayFastLinks .wrapper > div .column {
    padding-right: 1%;
    padding-left: 1%;
  }
}
#overlayFastLinks .wrapper > div a {
  position: relative;
  width: 280px;
  height: 280px;
  opacity: 0;
  transform: scale(0);
}
@media screen and (max-width: 1300px), screen and (max-height: 750px) {
  #overlayFastLinks .wrapper > div a {
    width: 240px;
    height: 240px;
  }
}
@media screen and (max-width: 1100px), screen and (max-height: 700px) {
  #overlayFastLinks .wrapper > div a {
    width: 220px;
    height: 220px;
  }
}
@media screen and (max-width: 1024px) {
  #overlayFastLinks .wrapper > div a {
    width: 320px;
    height: 320px;
  }
}
@media screen and (max-width: 900px) {
  #overlayFastLinks .wrapper > div a {
    width: 280px;
    height: 280px;
  }
}
@media screen and (max-width: 768px) {
  #overlayFastLinks .wrapper > div a {
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 700px) {
  #overlayFastLinks .wrapper > div a {
    width: 220px;
    height: 220px;
  }
}
@media screen and (max-width: 600px) {
  #overlayFastLinks .wrapper > div a {
    width: 180px;
    height: 180px;
  }
}
@media screen and (max-width: 500px) {
  #overlayFastLinks .wrapper > div a {
    width: 100%;
    height: auto;
    padding-top: 100%;
  }
}
#overlayFastLinks .wrapper > div a:hover picture:before {
  background: rgba(41, 53, 72, 0.7);
}
#overlayFastLinks .wrapper > div a:hover picture img {
  transform: scale(1.1);
}
#overlayFastLinks .wrapper > div a picture, #overlayFastLinks .wrapper > div a picture img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  overflow: hidden;
}
#overlayFastLinks .wrapper > div a picture:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(41, 53, 72, 0.6);
  z-index: 1;
  transition: background 300ms;
}
#overlayFastLinks .wrapper > div a picture img {
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
#overlayFastLinks .wrapper > div a span {
  position: absolute;
  width: 100%;
  padding: 0 12px;
  top: 50%;
  left: 0;
  font: 500 2.1875rem/1.2 "Founders Grotesk", sans-serif;
  color: #fff;
  text-align: center;
  transform: translateY(-50%);
  z-index: 2;
}
@media screen and (max-width: 1300px), screen and (max-height: 750px) {
  #overlayFastLinks .wrapper > div a span {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1100px), screen and (max-height: 700px) {
  #overlayFastLinks .wrapper > div a span {
    font-size: 1.8125rem;
  }
}
@media screen and (max-width: 1024px) {
  #overlayFastLinks .wrapper > div a span {
    font-size: 2.1875rem;
  }
}
@media screen and (max-width: 768px) {
  #overlayFastLinks .wrapper > div a span {
    font-size: 1.5625rem;
  }
}
@media screen and (max-width: 600px) {
  #overlayFastLinks .wrapper > div a span {
    font-size: 1.4375rem;
  }
}
@media screen and (max-width: 500px) {
  #overlayFastLinks .wrapper > div a span {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 425px) {
  #overlayFastLinks .wrapper > div a span {
    font-size: 1.1875rem;
  }
}

@media screen and (min-width: 1441px) {
  .t_events .content {
    padding-right: 50px;
    padding-left: 50px;
  }
}
.t_events .content > .w1440 > h2 {
  margin-bottom: 90px;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 700;
  color: #a4b665;
  text-align: center;
}
@media screen and (min-width: 1440px) {
  .t_events .content > .w1440 > h2 {
    font-size: 8.0625rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .t_events .content > .w1440 > h2 {
    font-size: calc(2.5rem + (129 - 40) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .t_events .content > .w1440 > h2 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 1400px) {
  .t_events .content > .w1440 > h2 {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 1200px) {
  .t_events .content > .w1440 > h2 {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .t_events .content > .w1440 > h2 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .t_events .content > .w1440 > h2 {
    margin-bottom: 30px;
  }
}
.t_events .content > .w1440 .eventsList > :not(:first-child) {
  margin-top: 60px;
}
@media screen and (max-width: 1200px) {
  .t_events .content > .w1440 .eventsList > :not(:first-child) {
    margin-top: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .t_events .content > .w1440 .eventsList > :not(:first-child) {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .t_events .content > .w1440 .eventsList > :not(:first-child) {
    margin-top: 30px;
  }
}
.t_events .content > .w1440 .btnWrapper {
  text-align: center;
  margin-top: 70px;
}
@media screen and (max-width: 1200px) {
  .t_events .content > .w1440 .btnWrapper {
    margin-top: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .t_events .content > .w1440 .btnWrapper {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .t_events .content > .w1440 .btnWrapper {
    margin-top: 30px;
  }
}

.l_pageInfos .content {
  width: calc(100% - 400px);
  margin-left: auto;
}
@media screen and (max-width: 1400px) {
  .l_pageInfos .content {
    width: calc(100% - 300px);
  }
}
@media screen and (max-width: 1300px) {
  .l_pageInfos .content {
    width: calc(100% - 250px);
  }
}
@media screen and (max-width: 1200px) {
  .l_pageInfos .content {
    width: calc(100% - 200px);
  }
}
@media screen and (max-width: 1024px) {
  .l_pageInfos .content {
    width: calc(100% - 160px);
  }
}
@media screen and (max-width: 850px) {
  .l_pageInfos .content {
    width: 100%;
    padding: 0 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_pageInfos .content {
    padding: 0 25px;
  }
}
.l_pageInfos .content .wrapper {
  display: flex;
  flex-flow: row wrap;
  height: 80px;
  padding-right: 80px;
  align-items: center;
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .l_pageInfos .content .wrapper {
    height: 70px;
    padding-right: 50px;
  }
}
@media screen and (max-height: 599px) {
  .l_pageInfos .content .wrapper {
    height: 60px;
  }
}
@media screen and (max-width: 850px) {
  .l_pageInfos .content .wrapper {
    height: 60px;
    padding: 0;
  }
}
.l_pageInfos .content .wrapper .m_breadcrumb {
  width: calc(100% - 110px);
  padding-right: 50px;
}
@media screen and (max-width: 850px) {
  .l_pageInfos .content .wrapper .m_breadcrumb {
    width: 100%;
    padding-right: 0;
  }
}
.l_pageInfos .content .wrapper .m_btnShare {
  width: 110px;
}
@media screen and (max-width: 850px) {
  .l_pageInfos .content .wrapper .m_btnShare {
    display: none;
  }
}

.l_pageBanner {
  position: relative;
  min-height: 350px;
  height: 100vh;
  transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
@media screen and (max-width: 1024px) {
  .l_pageBanner {
    max-height: 830px;
  }
}
.l_pageBanner:before {
  content: "";
  position: absolute;
  height: 50%;
  right: 0;
  bottom: 0;
  background: #c5dc76;
  transition: left 1300ms cubic-bezier(0.83, 0, 0.17, 1);
}
@media screen and (max-width: 500px) {
  .l_pageBanner:before {
    right: 100px;
  }
}
.l_pageBanner[data-scrollfire=fire]:before {
  left: 200px;
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .l_pageBanner[data-scrollfire=fire]:before {
    left: 150px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .l_pageBanner[data-scrollfire=fire]:before {
    left: 120px;
  }
}
@media screen and (max-width: 600px) {
  .l_pageBanner[data-scrollfire=fire]:before {
    left: 100px;
  }
}
@media screen and (max-width: 500px) {
  .l_pageBanner[data-scrollfire=fire]:before {
    left: 0%;
  }
}
.l_pageBanner[data-scrollfire=fire] .content .wrapper picture {
  width: 100%;
}
.l_pageBanner[data-scrollfire=fire] .content .wrapper picture img {
  transform: none;
}
.l_pageBanner[data-scrollfire=fire] .content .wrapper .m_credit {
  opacity: 1;
}
.l_pageBanner .content {
  height: calc(100% - 100px);
  padding-right: 80px;
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .l_pageBanner .content {
    height: calc(100% - 70px);
    padding-right: 50px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .l_pageBanner .content {
    height: calc(100% - 55px);
  }
}
@media screen and (max-width: 768px) {
  .l_pageBanner .content {
    padding-right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_pageBanner .content {
    height: calc(100% - 45px);
    padding-right: 25px;
  }
}
@media screen and (max-width: 500px) {
  .l_pageBanner .content {
    padding-right: 0;
  }
}
.l_pageBanner .content .wrapper {
  position: relative;
  height: 100%;
}
.l_pageBanner .content .wrapper picture {
  position: relative;
  width: 0%;
  height: 100%;
  overflow: hidden;
  transition: width 1300ms cubic-bezier(0.83, 0, 0.17, 1);
}
.l_pageBanner .content .wrapper picture:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(41, 53, 72, 0.9) 0%, rgba(41, 53, 72, 0) 100%);
  z-index: 1;
}
.l_pageBanner .content .wrapper picture img {
  width: calc(100vw - 80px);
  transition: transform 1300ms cubic-bezier(0.83, 0, 0.17, 1);
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .l_pageBanner .content .wrapper picture img {
    width: calc(100vw - 50px);
  }
}
@media screen and (max-width: 768px) {
  .l_pageBanner .content .wrapper picture img {
    width: calc(100vw - 30px);
  }
}
@media screen and (max-width: 600px) {
  .l_pageBanner .content .wrapper picture img {
    width: calc(100vw - 25px);
  }
}
@media screen and (max-width: 500px) {
  .l_pageBanner .content .wrapper picture img {
    width: 100vw;
  }
}
.l_pageBanner .content .wrapper h1 {
  position: absolute;
  padding-right: 100px;
  bottom: 70px;
  left: 250px;
  color: #fff;
  z-index: 2;
}
@media screen and (max-width: 1400px) {
  .l_pageBanner .content .wrapper h1 {
    left: 200px;
  }
}
@media screen and (max-width: 1200px) {
  .l_pageBanner .content .wrapper h1 {
    left: 180px;
  }
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .l_pageBanner .content .wrapper h1 {
    padding-right: 50px;
    left: 150px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .l_pageBanner .content .wrapper h1 {
    left: 120px;
  }
}
@media screen and (max-width: 768px) {
  .l_pageBanner .content .wrapper h1 {
    padding-right: 30px;
    left: 30px;
    bottom: 60px;
  }
}
@media screen and (max-width: 600px) {
  .l_pageBanner .content .wrapper h1 {
    padding-right: 25px;
  }
}
.l_pageBanner .content .wrapper .m_credit {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 300ms linear 1300ms;
}

html.showLanding .l_pageBanner:before, html.showTransition .l_pageBanner:before {
  left: 100%;
}
@media screen and (max-width: 500px) {
  html.showLanding .l_pageBanner:before, html.showTransition .l_pageBanner:before {
    left: calc(100% - 100px);
    right: 100px;
  }
}
html.showLanding .l_pageBanner .content .wrapper picture, html.showTransition .l_pageBanner .content .wrapper picture {
  width: 0%;
}
html.showLanding .l_pageBanner .content .wrapper picture img, html.showTransition .l_pageBanner .content .wrapper picture img {
  transform: scale(1.1);
}
html.showLanding .l_pageBanner .content .wrapper .m_credit, html.showTransition .l_pageBanner .content .wrapper .m_credit {
  opacity: 0;
}

.l_pageTitle {
  position: relative;
}
.l_pageTitle .content {
  padding-top: 40px;
}
@media screen and (max-width: 500px) {
  .l_pageTitle .content {
    padding-top: 25px;
  }
}
@media screen and (max-width: 425px) {
  .l_pageTitle .content {
    padding-top: 10px;
  }
}
.l_pageTitle .content picture {
  position: relative;
  height: 450px;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1200px) {
  .l_pageTitle .content picture {
    height: 400px;
  }
}
@media screen and (max-width: 1024px) {
  .l_pageTitle .content picture {
    height: 350px;
  }
}
@media screen and (max-width: 768px) {
  .l_pageTitle .content picture {
    height: 300px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) {
  .l_pageTitle .content picture {
    height: 250px;
  }
}
@media screen and (max-width: 425px) {
  .l_pageTitle .content picture {
    height: 200px;
  }
}
.l_pageTitle .content picture[data-scrollfire=fire] .m_credit {
  opacity: 1;
}
.l_pageTitle .content picture .m_credit {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 300ms linear 1900ms;
}
.l_pageTitle .content h1 span {
  display: block;
  color: #a4b665;
}
.l_pageTitle .content .message {
  margin-top: 20px;
}
.l_pageTitle .content .btn {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .l_pageTitle .content .btn {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .l_pageTitle .content .btn {
    margin-top: 30px;
  }
}
.l_pageTitle .content .m_category {
  margin-bottom: 20px;
}
.l_pageTitle .content .date {
  margin-top: 30px;
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  color: #a4b665;
}
.l_pageTitle .content .subtitle {
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .l_pageTitle .content .subtitle {
    font-size: 1rem;
  }
}
.l_pageTitle .content .info {
  margin-top: 10px;
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  color: #a4b665;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .l_pageTitle .content .info {
    margin-top: 20px;
    font-size: 1rem;
  }
}
.l_pageTitle .content h1.hugeTitle + .info {
  margin-top: 30px;
}

@media screen and (max-width: 1024px) {
  .l_dynamic .content {
    padding-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .l_dynamic .content {
    padding-top: 30px;
  }
}
.l_dynamic .intro {
  position: relative;
  padding-left: 40px;
  margin-bottom: 60px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.2;
  color: #293548;
}
@media screen and (min-width: 1440px) {
  .l_dynamic .intro {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .l_dynamic .intro {
    font-size: calc(1.5625rem + (35 - 25) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .l_dynamic .intro {
    font-size: 1.5625rem;
  }
}
.l_dynamic .intro:last-child {
  margin-bottom: 0px;
}
@media screen and (max-width: 1024px) {
  .l_dynamic .intro {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .l_dynamic .intro {
    padding-left: 20px;
  }
}
.l_dynamic .intro:before {
  content: "";
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(41, 53, 72, 0.3);
}

.dynamic > :first-child {
  margin-top: 0 !important;
}
.dynamic > :last-child {
  margin-bottom: 0 !important;
}
.dynamic h2 {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  line-height: 1;
  color: #a4b665;
  margin: 50px 0 30px;
}
@media screen and (min-width: 1440px) {
  .dynamic h2 {
    font-size: 3.4375rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .dynamic h2 {
    font-size: calc(1.875rem + (55 - 30) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .dynamic h2 {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h2 {
    margin: 50px 0 25px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 {
    margin: 40px 0 20px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic h2 {
    margin: 30px 0 15px;
  }
}
.dynamic h3 {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: #293548;
  margin: 40px 0 20px;
}
@media screen and (min-width: 1440px) {
  .dynamic h3 {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .dynamic h3 {
    font-size: calc(1.5rem + (30 - 24) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .dynamic h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h3 {
    margin: 30px 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h3 {
    margin: 25px 0 15px;
  }
}
.dynamic h4 {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  color: #293548;
  margin: 30px 0 15px;
}
@media screen and (min-width: 1440px) {
  .dynamic h4 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .dynamic h4 {
    font-size: calc(1.25rem + (24 - 20) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .dynamic h4 {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h4 {
    margin: 25px 0 10px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h4 {
    margin: 20px 0 10px;
  }
}
.dynamic p {
  margin: 15px 0;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.5;
  color: #293548;
  font-size: 1.3125rem;
}
@media screen and (max-width: 768px) {
  .dynamic p {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic p {
    margin: 10px 0;
  }
}
.dynamic strong {
  font-style: normal;
  font-weight: 500;
}
.dynamic em {
  font-style: italic;
  font-weight: normal;
}
.dynamic strong em,
.dynamic em strong {
  font-weight: 600;
}
.dynamic blockquote {
  position: relative;
  padding: 0 0 0 40px;
  margin: 30px 0;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.3;
  color: #293548;
}
@media screen and (min-width: 1440px) {
  .dynamic blockquote {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .dynamic blockquote {
    font-size: calc(1.125rem + (26 - 18) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .dynamic blockquote {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic blockquote {
    margin: 20px 0;
    padding: 0 0 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic blockquote {
    margin: 15px 0;
    padding: 0 0 0 25px;
  }
}
.dynamic blockquote p {
  margin-bottom: 0;
  font-family: "Founders Grotesk", sans-serif;
}
@media screen and (min-width: 1440px) {
  .dynamic blockquote p {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .dynamic blockquote p {
    font-size: calc(1.125rem + (26 - 18) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .dynamic blockquote p {
    font-size: 1.125rem;
  }
}
.dynamic blockquote:after {
  content: "";
  position: absolute;
  width: 6px;
  top: 5px;
  bottom: 0;
  left: 0;
  background: #c5dc76;
}
.dynamic a:not(.link):not(.btn) {
  display: inline;
  line-height: 1.5;
  font-family: "Founders Grotesk", sans-serif;
  color: #293548;
  box-shadow: inset 0px -2px 0 #293548;
  transition: color 300ms, box-shadow 300ms;
  font-size: 1.3125rem;
}
@media screen and (max-width: 1024px) {
  .dynamic a:not(.link):not(.btn) {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic a:not(.link):not(.btn) {
    font-size: 1rem;
  }
}
.dynamic a:not(.link):not(.btn):hover {
  color: #a4b665;
  box-shadow: inset 0px -2px 0 #a4b665;
}
.dynamic .btn {
  margin: 10px 0;
}
.dynamic ul, .dynamic ol {
  padding-left: 20px;
  margin: 30px 0;
  list-style-position: outside;
}
.dynamic ul li:not(:last-child), .dynamic ol li:not(:last-child) {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .dynamic ul, .dynamic ol {
    margin: 10px 0;
  }
}
.dynamic ul ul, .dynamic ul ol, .dynamic ol ul, .dynamic ol ol {
  margin: 0 0 0 20px;
  margin-top: 20px;
}
@media screen and (max-width: 1024px) {
  .dynamic ul ul, .dynamic ul ol, .dynamic ol ul, .dynamic ol ol {
    margin: 0 0 0 15px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic ul ul, .dynamic ul ol, .dynamic ol ul, .dynamic ol ol {
    margin: 0;
  }
}
@media screen and (max-width: 600px) {
  .dynamic ul ul, .dynamic ul ol, .dynamic ol ul, .dynamic ol ol {
    padding-left: 10px;
  }
}
.dynamic ul > li:last-child, .dynamic ol > li:last-child {
  margin-bottom: 0;
}
.dynamic ul li, .dynamic ol li {
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.5;
  color: #293548;
  font-size: 1.3125rem;
  font-weight: 500;
  color: #a4b665;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .dynamic ul li, .dynamic ol li {
    font-size: 1.125rem;
  }
}
.dynamic ul li p, .dynamic ol li p {
  margin: 0 !important;
  font-weight: normal;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.5;
  color: #293548;
  font-size: 1.3125rem;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .dynamic ul li p, .dynamic ol li p {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic ul li p, .dynamic ol li p {
    padding-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic ul li p, .dynamic ol li p {
    padding-left: 10px;
  }
}
.dynamic ul {
  list-style-type: disc;
}
.dynamic ol {
  list-style-type: decimal;
}
.dynamic .tableWrapper {
  width: 100%;
  overflow-x: auto;
  margin: 30px 0;
}
@media screen and (max-width: 1024px) {
  .dynamic .tableWrapper {
    margin: 25px 0;
  }
}
@media screen and (max-width: 768px) {
  .dynamic .tableWrapper {
    margin: 20px 0;
  }
}
@media screen and (max-width: 500px) {
  .dynamic .tableWrapper {
    margin: 15px 0;
  }
}
.dynamic .tableWrapper table {
  min-width: 600px;
}
.dynamic table {
  width: auto;
  white-space: nowrap;
  overflow-x: hidden;
  table-layout: fixed;
}
.dynamic table thead tr th, .dynamic table thead tr td {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: none;
  padding: 10px 30px;
  color: #fff;
  background: #293548;
  white-space: normal;
  text-align: left;
}
.dynamic table thead tr th, .dynamic table thead tr th p, .dynamic table thead tr th h4, .dynamic table thead tr th h3, .dynamic table thead tr th h2, .dynamic table thead tr td, .dynamic table thead tr td p, .dynamic table thead tr td h4, .dynamic table thead tr td h3, .dynamic table thead tr td h2 {
  line-height: 1.5;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  font-size: 1.3125rem;
}
@media screen and (max-width: 1024px) {
  .dynamic table thead tr th, .dynamic table thead tr th p, .dynamic table thead tr th h4, .dynamic table thead tr th h3, .dynamic table thead tr th h2, .dynamic table thead tr td, .dynamic table thead tr td p, .dynamic table thead tr td h4, .dynamic table thead tr td h3, .dynamic table thead tr td h2 {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic table thead tr th, .dynamic table thead tr th p, .dynamic table thead tr th h4, .dynamic table thead tr th h3, .dynamic table thead tr th h2, .dynamic table thead tr td, .dynamic table thead tr td p, .dynamic table thead tr td h4, .dynamic table thead tr td h3, .dynamic table thead tr td h2 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic table thead tr th, .dynamic table thead tr td {
    font-size: 1rem;
  }
}
.dynamic table tbody tr:nth-child(odd) td,
.dynamic table tbody tr:nth-child(odd) th {
  background: #f4f5f6;
}
.dynamic table tbody tr td.style1, .dynamic table tbody tr th.style1 {
  background: #a4b665;
  color: #fff;
}
.dynamic table tbody tr td:first-child, .dynamic table tbody tr th:first-child {
  border-left: 1px solid rgba(41, 53, 72, 0.2);
}
.dynamic table tbody tr:last-child td, .dynamic table tbody tr:last-child th {
  border-bottom: 1px solid rgba(41, 53, 72, 0.2);
}
.dynamic table tbody tr td, .dynamic table tbody tr th {
  padding: 10px 30px;
  margin: 0;
  font: 1.125rem/1.5 "Founders Grotesk", sans-serif;
  font-weight: normal;
  color: #293548;
  white-space: normal;
  border-right: 1px solid rgba(41, 53, 72, 0.2);
  vertical-align: middle !important;
  background: #fff;
}
@media screen and (max-width: 768px) {
  .dynamic table tbody tr td, .dynamic table tbody tr th {
    font-size: 1rem;
  }
}
.dynamic table tbody tr td > :first-child, .dynamic table tbody tr th > :first-child {
  margin-top: 0 !important;
}
.dynamic table tbody tr td > :last-child, .dynamic table tbody tr th > :last-child {
  margin-bottom: 0 !important;
}
.dynamic table tbody tr td p, .dynamic table tbody tr th p {
  font-size: 1.125rem;
  color: #293548;
}
@media screen and (max-width: 768px) {
  .dynamic table tbody tr td p, .dynamic table tbody tr th p {
    font-size: 1rem;
  }
}
.dynamic table tbody tr:only-child td:only-child {
  padding: 40px;
  border: 0;
  background: #f4f5f6;
}
@media screen and (max-width: 1024px) {
  .dynamic table tbody tr:only-child td:only-child {
    padding: 30px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic table tbody tr:only-child td:only-child {
    padding: 25px;
  }
}
.dynamic table tbody:first-child {
  border-top: 1px solid rgba(41, 53, 72, 0.2);
}
.dynamic pre {
  margin: 10px 0;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.3;
  color: #293548;
  white-space: normal;
  font-size: 0.9375rem;
}
@media screen and (max-width: 425px) {
  .dynamic pre {
    font-size: 0.875rem;
  }
}
.dynamic .tableWrapper + pre {
  margin-top: -10px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .dynamic .tableWrapper + pre {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic .tableWrapper + pre {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic .tableWrapper + pre {
    margin-bottom: 15px;
  }
}

.s_documents .text > p {
  font-weight: 500;
}

.l_homeBanner {
  position: relative;
  height: 100vh;
  padding-left: 200px;
  margin-top: -130px;
  min-height: 530px;
  max-height: 1080px;
  transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .l_homeBanner {
    padding-left: 150px;
    margin-top: -100px;
    max-height: 700px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .l_homeBanner {
    padding-left: 120px;
    margin-top: -80px;
    max-height: 850px;
  }
}
@media screen and (max-width: 768px) {
  .l_homeBanner {
    padding-left: 0px;
    margin-top: -80px;
  }
}
@media screen and (max-width: 768px) {
  .l_homeBanner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    z-index: 1;
  }
}
.l_homeBanner .content {
  padding-right: 385px;
  background: #c5dc76;
}
@media screen and (max-width: 1300px) {
  .l_homeBanner .content {
    padding-right: 325px;
  }
}
@media screen and (max-width: 1200px) {
  .l_homeBanner .content {
    padding-right: 300px;
  }
}
@media screen and (max-width: 1100px) {
  .l_homeBanner .content {
    padding-right: 275px;
  }
}
@media screen and (max-width: 1024px) {
  .l_homeBanner .content {
    padding-right: 250px;
  }
}
@media screen and (max-width: 850px) {
  .l_homeBanner .content {
    padding-right: 125px;
  }
}
@media screen and (max-width: 768px) {
  .l_homeBanner .content {
    padding-right: 0;
  }
}
.l_homeBanner .content,
.l_homeBanner .content .slickBannerImagesWrapper, .l_homeBanner .content .slickBannerTextWrapper,
.l_homeBanner .content .slickBannerImages, .l_homeBanner .content .slickBannerTexts,
.l_homeBanner .content .slick-list,
.l_homeBanner .content .slick-track,
.l_homeBanner .content .slick-slide,
.l_homeBanner .content .slick-slide > div,
.l_homeBanner .content .slick-slide > div > div {
  height: 100%;
}
.l_homeBanner .content .slickBannerImagesWrapper {
  position: relative;
  background: #fff;
}
@media screen and (max-width: 768px) {
  .l_homeBanner .content .slickBannerImagesWrapper {
    height: 80%;
  }
}
.l_homeBanner .content .slickBannerImagesWrapper .slickBannerImages picture {
  height: 100%;
}
@media screen and (max-width: 768px) {
  .l_homeBanner .content .slickBannerImagesWrapper .slickBannerImages picture:after {
    top: 80px;
    left: 0;
    transition: top 1500ms cubic-bezier(0.83, 0, 0.17, 1);
  }
  .l_homeBanner .content .slickBannerImagesWrapper .slickBannerImages picture[data-scrollfire=fire]:after, .l_homeBanner .content .slickBannerImagesWrapper .slickBannerImages picture[data-multiscrollfire=fire]:after {
    top: 100%;
  }
}
.l_homeBanner .content .slickBannerImagesWrapper .slickBannerImages picture:after, .l_homeBanner .content .slickBannerImagesWrapper .slickBannerImages picture img {
  transition-duration: 1500ms;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}
.l_homeBanner .content .slickBannerTextsWrapper {
  position: absolute;
  width: 100%;
  top: 130px;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .l_homeBanner .content .slickBannerTextsWrapper {
    top: 100px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .l_homeBanner .content .slickBannerTextsWrapper {
    top: 80px;
  }
}
.l_homeBanner .content .slickBannerTextsWrapper .follow {
  position: absolute;
  bottom: 25px;
  right: 175px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.5;
  color: #293548;
  font-size: 1.3125rem;
}
@media screen and (max-width: 768px) {
  .l_homeBanner .content .slickBannerTextsWrapper .follow {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 1300px) {
  .l_homeBanner .content .slickBannerTextsWrapper .follow {
    right: 125px;
  }
}
@media screen and (max-width: 1200px) {
  .l_homeBanner .content .slickBannerTextsWrapper .follow {
    right: 100px;
  }
}
@media screen and (max-width: 1100px) {
  .l_homeBanner .content .slickBannerTextsWrapper .follow {
    right: 50px;
  }
}
@media screen and (max-width: 850px) {
  .l_homeBanner .content .slickBannerTextsWrapper .follow {
    right: 75px;
  }
}
@media screen and (max-width: 768px), screen and (max-height: 700px) {
  .l_homeBanner .content .slickBannerTextsWrapper .follow {
    display: none;
  }
}
.l_homeBanner .content .slickBannerTextsWrapper .follow:hover svg {
  fill: #fff;
}
@media screen and (max-width: 850px) {
  .l_homeBanner .content .slickBannerTextsWrapper .follow span {
    display: none;
  }
}
.l_homeBanner .content .slickBannerTextsWrapper .follow svg {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  fill: #293548;
  vertical-align: middle;
  transition: fill 300ms;
}
.l_homeBanner .content .slickBannerTextsWrapper .container {
  position: absolute;
  width: 580px;
  top: 80px;
  right: 100px;
  bottom: 80px;
  background: #fff;
}
@media screen and (max-height: 700px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container {
    top: 30px;
    bottom: 30px;
  }
}
@media screen and (max-width: 1300px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container {
    width: 530px;
    right: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container {
    width: 500px;
  }
}
@media screen and (max-width: 1100px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container {
    width: 470px;
  }
}
@media screen and (max-width: 1024px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container {
    width: 430px;
  }
}
@media screen and (max-width: 850px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container {
    width: 400px;
  }
}
@media screen and (max-width: 768px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container {
    width: auto;
    height: auto;
    top: auto;
    right: 30px;
    bottom: 50px;
    left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container {
    right: 25px;
    left: 25px;
  }
}
@media screen and (max-width: 500px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container {
    bottom: 30px;
  }
}
.l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 30px 65px;
}
@media screen and (max-width: 1300px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text {
    padding: 30px 40px;
  }
}
@media screen and (max-width: 1100px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text {
    padding: 25px 30px;
  }
}
@media screen and (max-width: 768px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text {
    padding: 75px;
  }
}
@media screen and (max-width: 500px), screen and (max-width: 700px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text {
    padding: 50px;
  }
}
@media screen and (max-width: 425px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text {
    padding: 50px 30px;
  }
}
@media screen and (max-width: 375px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text {
    padding: 40px 25px;
  }
}
@media screen and (max-width: 350px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text {
    padding: 30px 20px;
  }
}
.l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  color: #293548;
}
@media screen and (min-width: 1440px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
    font-size: 4.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
    font-size: calc(2.5rem + (72 - 40) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
    font-size: 2.5rem;
  }
}
@media screen and (max-height: 750px) and (min-width: 1440px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
    font-size: 4.0625rem;
  }
}
@media screen and (max-height: 750px) and (min-width: 320px) and (max-width: 1440px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
    font-size: calc(2.5rem + (65 - 40) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-height: 750px) and (max-width: 320px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
    font-size: 2.5rem;
  }
}
@media screen and (max-height: 700px) and (min-width: 1440px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
    font-size: 3.75rem;
  }
}
@media screen and (max-height: 700px) and (min-width: 320px) and (max-width: 1440px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
    font-size: calc(2.5rem + (60 - 40) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-height: 700px) and (max-width: 320px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 1440px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1.smaller, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2.smaller {
    font-size: 3.4375rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1.smaller, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2.smaller {
    font-size: calc(2.1875rem + (55 - 35) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1.smaller, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2.smaller {
    font-size: 2.1875rem;
  }
}
.l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h1 span, .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text h2 span {
  color: #a4b665;
}
.l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text .btn {
  margin-top: 50px;
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text .btn {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px), screen and (max-height: 700px) {
  .l_homeBanner .content .slickBannerTextsWrapper .container .slickBannerTexts .text .btn {
    margin-top: 30px;
  }
}
.l_homeBanner #slickBannerNav.slickNav {
  position: absolute;
  width: 200px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .l_homeBanner #slickBannerNav.slickNav {
    width: 150px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .l_homeBanner #slickBannerNav.slickNav {
    width: 120px;
  }
}
@media screen and (max-width: 768px) {
  .l_homeBanner #slickBannerNav.slickNav {
    display: none;
  }
}

.showTransition, .showLanding .l_homeBanner [data-picture]:after {
  top: 80px !important;
}

.l_featured {
  position: relative;
  padding: 250px 0;
}
@media screen and (max-width: 1200px) {
  .l_featured {
    padding: 210px 0;
  }
}
@media screen and (max-width: 1024px) {
  .l_featured {
    padding: 170px 0 250px;
  }
}
@media screen and (max-width: 768px) {
  .l_featured {
    padding: 140px 0 220px;
  }
}
.l_featured .slickFeaturedImages {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: #293548;
}
.l_featured .slickFeaturedImages,
.l_featured .slickFeaturedImages .slick-list,
.l_featured .slickFeaturedImages .slick-track,
.l_featured .slickFeaturedImages .slick-slide,
.l_featured .slickFeaturedImages .slick-slide > div,
.l_featured .slickFeaturedImages .slick-slide > div > div {
  height: 100%;
}
.l_featured .slickFeaturedImages .credit {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  top: 0;
  right: 0;
  display: inline-block;
  z-index: 2;
  font-family: "Founders Grotesk", sans-serif;
}
.l_featured .slickFeaturedImages picture {
  position: relative;
  width: 100%;
  height: 100%;
}
.l_featured .slickFeaturedImages picture:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(41, 53, 72, 0.7);
  z-index: 1;
}
.l_featured .slickFeaturedImages picture:after, .l_featured .slickFeaturedImages picture img {
  transition-duration: 1500ms;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}
.l_featured .fiveReasonsWhy {
  position: absolute;
  top: 150px;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 1.25;
  color: #a4b665;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  /*@media screen and (max-width: 600px) {
      top: 50px;
  }

  @media screen and (max-width: 500px) {
      top: 30px;
  }*/
}
@media screen and (max-width: 1024px) {
  .l_featured .fiveReasonsWhy {
    top: 100px;
  }
}
@media screen and (max-width: 900px) {
  .l_featured .fiveReasonsWhy {
    font-size: 20px;
  }
}
@media screen and (max-width: 700px) {
  .l_featured .fiveReasonsWhy {
    font-size: 18px;
  }
}
.l_featured .slickFeaturedTexts {
  position: relative;
  z-index: 1;
}
.l_featured .slickFeaturedTexts .slick-list {
  padding: 0 20vw;
}
@media screen and (max-width: 1024px) {
  .l_featured .slickFeaturedTexts .slick-list {
    padding: 0;
  }
}
.l_featured .slickFeaturedTexts .slick-track {
  display: flex;
  align-items: center;
}
.l_featured .slickFeaturedTexts .slick-current .text h2, .l_featured .slickFeaturedTexts .slickAnimate .text h2 {
  color: #fff;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0);
}
.l_featured .slickFeaturedTexts .text {
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l_featured .slickFeaturedTexts .text {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_featured .slickFeaturedTexts .text {
    padding-right: 25px;
    padding-left: 25px;
  }
}
.l_featured .slickFeaturedTexts .text h2 {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  transition: color 300ms, -webkit-text-stroke-color 300ms;
}
@media screen and (min-width: 1440px) {
  .l_featured .slickFeaturedTexts .text h2 {
    font-size: 6.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .l_featured .slickFeaturedTexts .text h2 {
    font-size: calc(2.5rem + (110 - 40) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .l_featured .slickFeaturedTexts .text h2 {
    font-size: 2.5rem;
  }
}
.l_featured .slickFeaturedTexts .text .link {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .l_featured .slickFeaturedTexts .text .link {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .l_featured .slickFeaturedTexts .text .link {
    margin-top: 30px;
  }
}
.l_featured .slickNavWrapper {
  position: absolute;
  padding: 30px 50px;
  right: 0;
  bottom: 0;
  background: #fff;
}
@media screen and (max-width: 1200px) {
  .l_featured .slickNavWrapper {
    padding: 25px 40px;
  }
}
@media screen and (max-width: 1024px) {
  .l_featured .slickNavWrapper {
    padding: 15px 30px;
  }
}
@media screen and (max-width: 768px) {
  .l_featured .slickNavWrapper {
    padding: 15px 25px;
  }
}

.l_component a,
.l_component span.noLink {
  display: block;
  position: relative;
  padding: 100px;
  margin-top: 110px;
}
@media screen and (max-width: 1100px) {
  .l_component a,
  .l_component span.noLink {
    padding: 75px;
    margin-top: 95px;
  }
}
@media screen and (max-width: 850px) {
  .l_component a,
  .l_component span.noLink {
    margin-top: 70px;
  }
}
@media screen and (max-width: 768px) {
  .l_component a,
  .l_component span.noLink {
    padding: 60px 50px;
  }
}
@media screen and (max-width: 700px) {
  .l_component a,
  .l_component span.noLink {
    margin-top: 60px;
  }
}
@media screen and (max-width: 600px) {
  .l_component a,
  .l_component span.noLink {
    padding: 60px 30px;
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .l_component a,
  .l_component span.noLink {
    padding: 50px 25px;
    margin-top: 40px;
  }
}
.l_component a:before,
.l_component span.noLink:before {
  content: "";
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 0;
  left: 0;
  background: #c5dc76;
}
@media screen and (max-width: 768px) {
  .l_component a:before,
  .l_component span.noLink:before {
    top: 10px;
    right: 10px;
  }
}
@media screen and (max-width: 600px) {
  .l_component a:before,
  .l_component span.noLink:before {
    display: none;
  }
}
.l_component a:hover picture img,
.l_component span.noLink:hover picture img {
  transform: scale(1.05);
}
.l_component a:hover .bigWord,
.l_component span.noLink:hover .bigWord {
  color: #c5dc76;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0);
}
.l_component a:hover .btn span:before,
.l_component span.noLink:hover .btn span:before {
  right: 0%;
}
.l_component a:hover .btn span:after,
.l_component span.noLink:hover .btn span:after {
  background: url("../images/content/icons/whitePointer.svg") no-repeat center/contain;
}
.l_component a .bigWord,
.l_component span.noLink .bigWord {
  position: absolute;
  top: -50px;
  left: 50%;
  font: 700 12.8125rem/1 "Founders Grotesk", sans-serif;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(41, 53, 72, 0.4);
  transition: color 300ms, -webkit-text-stroke-color 300ms;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media screen and (max-width: 1300px) {
  .l_component a .bigWord,
  .l_component span.noLink .bigWord {
    font-size: 11.25rem;
  }
}
@media screen and (max-width: 1100px) {
  .l_component a .bigWord,
  .l_component span.noLink .bigWord {
    font-size: 10rem;
    top: -45px;
  }
}
@media screen and (max-width: 1024px) {
  .l_component a .bigWord,
  .l_component span.noLink .bigWord {
    font-size: 8.75rem;
    top: -40px;
  }
}
@media screen and (max-width: 850px) {
  .l_component a .bigWord,
  .l_component span.noLink .bigWord {
    font-size: 7.5rem;
    top: -35px;
  }
}
@media screen and (max-width: 700px) {
  .l_component a .bigWord,
  .l_component span.noLink .bigWord {
    font-size: 6.25rem;
    top: -30px;
  }
}
@media screen and (max-width: 600px) {
  .l_component a .bigWord,
  .l_component span.noLink .bigWord {
    font-size: 5rem;
    top: -25px;
  }
}
@media screen and (max-width: 500px) {
  .l_component a .bigWord,
  .l_component span.noLink .bigWord {
    font-size: 3.75rem;
    top: -20px;
  }
}
@media screen and (max-width: 400px) {
  .l_component a .bigWord,
  .l_component span.noLink .bigWord {
    font-size: 3.125rem;
    top: -15px;
  }
}
.l_component a picture,
.l_component span.noLink picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 15px;
  left: 15px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .l_component a picture,
  .l_component span.noLink picture {
    bottom: 10px;
    left: 10px;
  }
}
@media screen and (max-width: 600px) {
  .l_component a picture,
  .l_component span.noLink picture {
    bottom: 0;
    left: 0;
  }
}
.l_component a picture:before,
.l_component span.noLink picture:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(41, 53, 72, 0.7);
  z-index: 1;
}
.l_component a picture[data-picture]:after,
.l_component span.noLink picture[data-picture]:after {
  transition-duration: 1500ms;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}
.l_component a picture img,
.l_component span.noLink picture img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 500ms cubic-bezier(0.33, 1, 0.68, 1);
}
.l_component a .text,
.l_component span.noLink .text {
  position: relative;
  z-index: 1;
}
.l_component a .text h2, .l_component a .text p,
.l_component span.noLink .text h2,
.l_component span.noLink .text p {
  color: #fff;
}
.l_component a .text h2,
.l_component span.noLink .text h2 {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
}
@media screen and (min-width: 1440px) {
  .l_component a .text h2,
  .l_component span.noLink .text h2 {
    font-size: 2.8125rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .l_component a .text h2,
  .l_component span.noLink .text h2 {
    font-size: calc(1.625rem + (45 - 26) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .l_component a .text h2,
  .l_component span.noLink .text h2 {
    font-size: 1.625rem;
  }
}
.l_component a .text p,
.l_component span.noLink .text p {
  margin-top: 10px;
}
.l_component a .text .btn,
.l_component span.noLink .text .btn {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .l_component a .text .btn,
  .l_component span.noLink .text .btn {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .l_component a .text .btn,
  .l_component span.noLink .text .btn {
    margin-top: 30px;
  }
}

.l_filters {
  padding-top: 50px;
  padding-bottom: 70px;
}
@media screen and (max-width: 1024px) {
  .l_filters {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 900px) {
  .l_filters {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .l_filters {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_filters {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 900px) {
  .l_filters .content {
    padding-right: 0;
    padding-left: 0;
  }
}
.l_filters p {
  margin-bottom: 20px;
  font-size: 1.125rem;
}
@media screen and (max-width: 900px) {
  .l_filters p {
    padding-left: 50px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .l_filters p {
    padding-left: 30px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .l_filters p {
    padding-left: 25px;
  }
}
@media screen and (max-width: 900px) {
  .l_filters .dragAndSlide {
    padding-right: 50px;
    padding-left: 50px;
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    transform: scale(1);
    transition: all 0.2s;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen and (max-width: 768px) {
  .l_filters .dragAndSlide {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_filters .dragAndSlide {
    padding-right: 25px;
    padding-left: 25px;
  }
}
.l_filters .dragAndSlide::-webkit-scrollbar {
  width: 0 !important;
}
.l_filters .dragAndSlide .m_category {
  margin: 0 20px 20px 0;
}
@media screen and (max-width: 1024px) {
  .l_filters .dragAndSlide .m_category {
    margin-bottom: 0;
  }
}
.l_filters .date {
  display: flex;
  width: 100%;
  margin-top: 30px;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .l_filters .date {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .l_filters .date {
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 700px) {
  .l_filters .date {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .l_filters .date {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (max-width: 700px) {
  .l_filters .date h2 {
    margin-bottom: 20px;
  }
}
.l_filters .date .btn {
  margin-left: auto;
}
@media screen and (max-width: 700px) {
  .l_filters .date .btn {
    margin-left: 0;
  }
}

.l_pagination {
  text-align: center;
}
.l_pagination a {
  padding: 10px;
  margin: 0 10px;
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  transition: color 300ms;
  vertical-align: middle;
}
@media screen and (max-width: 500px) {
  .l_pagination a {
    padding: 10px;
    margin: 0 5px;
  }
}
@media screen and (max-width: 320px) {
  .l_pagination a {
    padding: 10px;
    margin: 0;
  }
}
.l_pagination a:hover {
  color: #aaadb2;
}
.l_pagination a:hover svg {
  stroke: #aaadb2;
}
.l_pagination a:first-child {
  margin-right: 20px;
}
@media screen and (max-width: 500px) {
  .l_pagination a:first-child {
    margin-right: 10px;
  }
}
.l_pagination a:first-child svg {
  transform: rotate(180deg);
  top: 1px;
}
.l_pagination a:last-child {
  margin-left: 20px;
}
@media screen and (max-width: 500px) {
  .l_pagination a:last-child {
    margin-left: 10px;
  }
}
.l_pagination a:last-child svg {
  top: 3px;
}
.l_pagination a svg {
  position: relative;
  width: 12px;
  height: 15px;
  stroke: #293548;
  transition: stroke 300ms;
}

.l_contact .content {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.l_contact .side {
  width: 400px;
  order: 2;
  background: #a4b665;
}
@media screen and (max-width: 1400px) {
  .l_contact .side {
    width: 380px;
  }
}
@media screen and (max-width: 1200px) {
  .l_contact .side {
    width: 350px;
  }
}
@media screen and (max-width: 1024px) {
  .l_contact .side {
    width: 320px;
  }
}
@media screen and (max-width: 900px) {
  .l_contact .side {
    width: 100%;
    max-width: calc(100% - 120px);
    margin-left: auto;
    order: 1;
  }
}
@media screen and (max-width: 600px) {
  .l_contact .side {
    max-width: calc(100% - 100px);
  }
}
@media screen and (max-width: 500px) {
  .l_contact .side {
    max-width: 100%;
  }
}
.l_contact .side .sideWrapper {
  position: sticky;
  top: 0;
}
.l_contact .side .sideWrapper .infoBox {
  padding: 70px 45px 0;
}
@media screen and (max-width: 1400px) {
  .l_contact .side .sideWrapper .infoBox {
    padding: 60px 35px 0;
  }
}
@media screen and (max-width: 1200px) {
  .l_contact .side .sideWrapper .infoBox {
    padding: 50px 25px 0;
  }
}
@media screen and (max-width: 1024px) {
  .l_contact .side .sideWrapper .infoBox {
    padding: 40px 25px 0;
  }
}
.l_contact .side .sideWrapper .infoBox:last-child {
  padding-bottom: 70px;
}
@media screen and (max-width: 1400px) {
  .l_contact .side .sideWrapper .infoBox:last-child {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .l_contact .side .sideWrapper .infoBox:last-child {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .l_contact .side .sideWrapper .infoBox:last-child {
    padding-bottom: 40px;
  }
}
.l_contact .side .sideWrapper .infoBox h2, .l_contact .side .sideWrapper .infoBox p, .l_contact .side .sideWrapper .infoBox a {
  color: #fff;
}
.l_contact .side .sideWrapper .infoBox p {
  margin-top: 5px;
}
.l_contact .side .sideWrapper .infoBox .tel {
  margin-top: 30px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.1;
  transition: color 300ms;
}
@media screen and (min-width: 1440px) {
  .l_contact .side .sideWrapper .infoBox .tel {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .l_contact .side .sideWrapper .infoBox .tel {
    font-size: calc(1.5rem + (30 - 24) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .l_contact .side .sideWrapper .infoBox .tel {
    font-size: 1.5rem;
  }
}
.l_contact .side .sideWrapper .infoBox .tel:hover {
  color: #293548;
}
.l_contact .form {
  width: calc(100% - 400px);
  order: 1;
  padding: 70px 200px 0 250px;
}
@media screen and (max-width: 1600px) {
  .l_contact .form {
    padding: 70px 150px 0 200px;
  }
}
@media screen and (max-width: 1400px) {
  .l_contact .form {
    padding: 60px 100px 0 200px;
    width: calc(100% - 380px);
  }
}
@media screen and (max-width: 1200px) {
  .l_contact .form {
    width: calc(100% - 350px);
  }
}
@media screen and (max-width: 1024px) {
  .l_contact .form {
    padding: 50px 50px 0 150px;
    width: calc(100% - 320px);
  }
}
@media screen and (max-width: 900px) {
  .l_contact .form {
    width: 100%;
    order: 2;
  }
}
@media screen and (max-width: 850px) {
  .l_contact .form {
    padding: 50px 50px 0 120px;
  }
}
@media screen and (max-width: 768px) {
  .l_contact .form {
    padding: 50px 30px 0 120px;
  }
}
@media screen and (max-width: 600px) {
  .l_contact .form {
    padding: 50px 25px 0 100px;
  }
}
@media screen and (max-width: 500px) {
  .l_contact .form {
    padding: 50px 25px 0;
  }
}
.l_contact .form h2 {
  margin-bottom: 30px;
}
.l_contact .form .textRight {
  text-align: right;
}

/*** |- ---------------------------------------- -| ***/
/*** |- BOUTON DE PARTAGE ---------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_btnShare {
  font: 500 0.9375rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color 300ms;
}
.m_btnShare:hover {
  color: #a4b665;
}
.m_btnShare:hover svg {
  fill: #a4b665;
}
.m_btnShare svg {
  position: relative;
  width: 20px;
  height: 18px;
  margin-right: 15px;
  top: -1px;
  vertical-align: middle;
  fill: #293548;
  transition: fill 300ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- MENTION CRÉDIT SUR UNE PHOTO ----------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_credit {
  padding: 14px 30px 16px;
  font: 500 0.875rem/1 "Founders Grotesk", sans-serif;
  color: #fff;
  background: #293548;
}

/*** |- ---------------------------------------- -| ***/
/*** |- UNE CATÉGORIE -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_category {
  display: inline-block;
  padding: 10px 20px;
  font: 500 0.9375rem/1.1 "Founders Grotesk", sans-serif;
  color: #293548;
  background: #fff;
  border: 1px solid #293548;
  transition: background 300ms, border-color 300ms;
}
.m_category:hover {
  background: #c5dc76;
  border-color: #c5dc76;
}
.m_category.is_active {
  background: #c5dc76;
  border-color: #c5dc76;
}

.m_btnFastLinks {
  position: fixed;
  width: 120px;
  padding-bottom: 30px;
  bottom: 25px;
  left: 40px;
  opacity: 1;
  z-index: 10;
  transition: opacity 225ms, padding 225ms cubic-bezier(0.33, 1, 0.68, 1);
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .m_btnFastLinks {
    width: 90px;
    padding-bottom: 0;
    left: 30px;
  }
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .m_btnFastLinks {
    width: 60px;
  }
}
@media screen and (max-width: 600px) {
  .m_btnFastLinks {
    width: 50px;
    bottom: 20px;
    left: 25px;
  }
}
@media screen and (max-width: 500px) {
  .m_btnFastLinks {
    right: 25px;
    left: auto;
  }
}
.m_btnFastLinks:hover div:before {
  transform: scale(1.1);
}
.m_btnFastLinks div {
  position: relative;
  width: 90px;
  height: 90px;
  margin: 0 auto;
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .m_btnFastLinks div {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 600px) {
  .m_btnFastLinks div {
    width: 50px;
    height: 50px;
  }
}
.m_btnFastLinks div:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #a4b665;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1), background 600ms;
}
.m_btnFastLinks div .btnX {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 600ms;
}
.m_btnFastLinks div .btnX:before, .m_btnFastLinks div .btnX:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #a4b665;
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .m_btnFastLinks div .btnX:before, .m_btnFastLinks div .btnX:after {
    width: 22px;
  }
}
.m_btnFastLinks div .btnX:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.m_btnFastLinks div .btnX:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.m_btnFastLinks div svg {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  fill: #fff;
  stroke: #fff;
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 600ms;
}
@media screen and (max-width: 600px) {
  .m_btnFastLinks div svg {
    width: 20px;
    height: 20px;
  }
}
.m_btnFastLinks > span {
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  font: 500 0.9375rem/1 "Founders Grotesk Mono", sans-serif;
  text-transform: uppercase;
  text-align: center;
  transition: opacity 600ms;
}
@media screen and (max-width: 1024px), screen and (max-height: 750px) {
  .m_btnFastLinks > span {
    display: none;
  }
}
.m_btnFastLinks > span.state1 {
  color: #293548;
  opacity: 1;
}
.m_btnFastLinks > span.state2 {
  color: #fff;
  opacity: 0;
}

.hamburger {
  position: relative;
  width: 24px;
  height: 18px;
  cursor: pointer;
}
.hamburger span {
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 1;
  left: 0%;
  transform: rotate(0deg);
  transition: 250ms;
}
.hamburger span:nth-child(1) {
  top: 0px;
}
.hamburger span:nth-child(2), .hamburger span:nth-child(3) {
  top: calc(50% - 0.5px);
  width: 80%;
}
.hamburger span:nth-child(4) {
  bottom: 0px;
}

.showOverlayMenu .hamburger span:nth-child(1) {
  width: 0%;
  bottom: 0px;
  left: 50%;
  opacity: 0;
}
.showOverlayMenu .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  width: 100%;
}
.showOverlayMenu .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  width: 100%;
}
.showOverlayMenu .hamburger span:nth-child(4) {
  width: 0%;
  bottom: 0px;
  left: 50%;
  opacity: 0;
}

.calendarWrapper {
  position: absolute;
  top: 0;
  right: 80px;
}
@media screen and (max-width: 1024px) {
  .calendarWrapper {
    right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .calendarWrapper {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .calendarWrapper {
    right: 25px;
  }
}

.btnCalendar {
  position: relative;
  width: 90px;
  height: 90px;
  margin: 0 auto;
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .btnCalendar {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 600px) {
  .btnCalendar {
    width: 50px;
    height: 50px;
  }
}
.btnCalendar:hover:before {
  transform: scale(1.1);
}
.btnCalendar:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #a4b665;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1), background 600ms;
}
.btnCalendar .btnX {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 600ms;
}
.btnCalendar .btnX:before, .btnCalendar .btnX:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #fff;
}
@media screen and (max-width: 850px), screen and (max-height: 599px) {
  .btnCalendar .btnX:before, .btnCalendar .btnX:after {
    width: 22px;
  }
}
.btnCalendar .btnX:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.btnCalendar .btnX:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.btnCalendar svg {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  fill: #fff;
  stroke: #fff;
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 600ms;
}
@media screen and (max-width: 600px) {
  .btnCalendar svg {
    width: 20px;
    height: 20px;
  }
}

.m_breadcrumb {
  display: inline-block;
  position: relative;
  max-width: 100%;
  z-index: 2;
}
.m_breadcrumb p {
  position: relative;
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
}
.m_breadcrumb p .home:hover svg {
  stroke: #a4b665;
}
.m_breadcrumb p .home svg {
  width: 14px;
  height: 14px;
  stroke: #293548;
  transition: stroke 300ms;
}
.m_breadcrumb p a, .m_breadcrumb p span {
  position: relative;
  display: inline;
  font: 1.125rem/1 "Founders Grotesk", sans-serif;
  font-weight: 500;
  color: #293548;
}
@media screen and (max-width: 768px) {
  .m_breadcrumb p a, .m_breadcrumb p span {
    font-size: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .m_breadcrumb p a, .m_breadcrumb p span {
    font-size: 0.9375rem;
  }
}
.m_breadcrumb p a:last-child, .m_breadcrumb p span:last-child {
  color: #293548;
}
.m_breadcrumb p a:not(:last-child), .m_breadcrumb p span:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 768px) {
  .m_breadcrumb p a:not(:last-child), .m_breadcrumb p span:not(:last-child) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 500px) {
  .m_breadcrumb p a:not(:last-child), .m_breadcrumb p span:not(:last-child) {
    margin-right: 5px;
  }
}
.m_breadcrumb p a {
  transition: color 300ms;
}
.m_breadcrumb p a:hover {
  color: #a4b665;
}

.m_rubricsList {
  list-style-type: none;
}
.m_rubricsList > li {
  position: relative;
  border-bottom: 1px solid rgba(41, 53, 72, 0.2);
  overflow: hidden;
  transition: background 300ms;
}
.m_rubricsList > li:first-child {
  border-top: 1px solid rgba(41, 53, 72, 0.2);
}
.m_rubricsList > li .clickable {
  display: block;
  position: relative;
  cursor: pointer;
}
.m_rubricsList > li .clickable:hover .flex div p {
  color: #a4b665;
}
.m_rubricsList > li .clickable:hover .icon {
  background: #a4b665;
  border-color: #a4b665;
}
.m_rubricsList > li .clickable:hover .icon svg {
  stroke: #fff;
}
.m_rubricsList > li .clickable .flex {
  display: flex;
  align-items: center;
}
.m_rubricsList > li .clickable .flex div {
  width: 100%;
}
.m_rubricsList > li .clickable .flex div p {
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.3;
  color: #293548;
  transition: color 300ms;
}
@media screen and (min-width: 1440px) {
  .m_rubricsList > li .clickable .flex div p {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .m_rubricsList > li .clickable .flex div p {
    font-size: calc(1.375rem + (35 - 22) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .m_rubricsList > li .clickable .flex div p {
    font-size: 1.375rem;
  }
}
.m_rubricsList > li .clickable .icon {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: 45px;
  border: 1px solid rgba(41, 53, 72, 0.3);
  transform: translateY(-50%);
  transition: background 300ms, border-color 300ms;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList > li .clickable .icon {
    width: 40px;
    height: 40px;
    right: 35px;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList > li .clickable .icon {
    right: 25px;
  }
}
@media screen and (max-width: 600px) {
  .m_rubricsList > li .clickable .icon {
    right: 15px;
  }
}
@media screen and (max-width: 425px) {
  .m_rubricsList > li .clickable .icon {
    width: 34px;
    height: 34px;
  }
}
.m_rubricsList > li .clickable .icon svg {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  left: 50%;
  stroke: #a4b665;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1), stroke 300ms;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList > li .clickable .icon svg {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 425px) {
  .m_rubricsList > li .clickable .icon svg {
    width: 14px;
    height: 14px;
  }
}
.m_rubricsList > li .drawer {
  position: relative;
  opacity: 0;
  z-index: 1;
  transform: translateY(100px);
  transition: opacity 300ms, transform 300ms;
}
.m_rubricsList > li .drawer > div {
  padding-bottom: 50px;
}
@media screen and (max-width: 1200px) {
  .m_rubricsList > li .drawer > div {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .m_rubricsList > li .drawer > div {
    padding-bottom: 30px;
  }
}
.m_rubricsList > li .drawer > div .dynamic h2 {
  font-size: 2.5rem;
  margin: 25px 0 15px;
}
@media screen and (max-width: 1440px) {
  .m_rubricsList > li .drawer > div .dynamic h2 {
    font-size: 2.3125rem;
  }
}
@media screen and (max-width: 1024px) {
  .m_rubricsList > li .drawer > div .dynamic h2 {
    font-size: 2.125rem;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList > li .drawer > div .dynamic h2 {
    font-size: 1.9375rem;
  }
}
@media screen and (max-width: 425px) {
  .m_rubricsList > li .drawer > div .dynamic h2 {
    font-size: 1.8125rem;
  }
}
@media screen and (max-width: 375px) {
  .m_rubricsList > li .drawer > div .dynamic h2 {
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 320px) {
  .m_rubricsList > li .drawer > div .dynamic h2 {
    font-size: 1.6875rem;
  }
}
.m_rubricsList > li .drawer > div .dynamic h3 {
  font-size: 1.625rem;
  margin: 20px 0 10px;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList > li .drawer > div .dynamic h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList > li .drawer > div .dynamic h3 {
    font-size: 1.4375rem;
  }
}
@media screen and (max-width: 425px) {
  .m_rubricsList > li .drawer > div .dynamic h3 {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 375px) {
  .m_rubricsList > li .drawer > div .dynamic h3 {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 320px) {
  .m_rubricsList > li .drawer > div .dynamic h3 {
    font-size: 1.25rem;
  }
}
.m_rubricsList > li .drawer > div .dynamic h4 {
  font-size: 1.375rem;
  margin: 15px 0 5px;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList > li .drawer > div .dynamic h4 {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList > li .drawer > div .dynamic h4 {
    font-size: 1.125rem;
  }
}
.m_rubricsList > li .drawer > div .dynamic p {
  margin: 5px 0 15px;
}
.m_rubricsList > li .drawer > div .dynamic blockquote {
  font-size: 1.4375rem;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 425px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 375px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 320px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote {
    font-size: 1.125rem;
  }
}
.m_rubricsList > li .drawer > div .dynamic blockquote p {
  font-size: 1.4375rem;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote p {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote p {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 425px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote p {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 375px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote p {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 320px) {
  .m_rubricsList > li .drawer > div .dynamic blockquote p {
    font-size: 1.125rem;
  }
}
.m_rubricsList > li .drawer > div .m_gallery, .m_rubricsList > li .drawer > div .m_documentsList, .m_rubricsList > li .drawer > div .btnShare {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList > li .drawer > div .m_gallery, .m_rubricsList > li .drawer > div .m_documentsList, .m_rubricsList > li .drawer > div .btnShare {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList > li .drawer > div .m_gallery, .m_rubricsList > li .drawer > div .m_documentsList, .m_rubricsList > li .drawer > div .btnShare {
    margin-top: 30px;
  }
}

.m_documentsList {
  list-style-type: none;
}
.m_documentsList li {
  border-top: 1px solid #bec2c8;
  border-bottom: 1px solid #bec2c8;
}
.m_documentsList li:not(:first-child) {
  margin-top: -1px;
}
.m_documentsList li a {
  display: block;
  position: relative;
}

.m_jobsList {
  list-style-type: none;
}
.m_jobsList > li {
  border-top: 1px solid #bec2c8;
  border-bottom: 1px solid #bec2c8;
}
.m_jobsList > li:not(:first-child) {
  margin-top: -1px;
}
.m_jobsList > li a {
  display: block;
  position: relative;
  padding: 50px 120px 50px 45px;
}
@media screen and (max-width: 1200px) {
  .m_jobsList > li a {
    padding: 40px 120px 40px 45px;
  }
}
@media screen and (max-width: 1024px) {
  .m_jobsList > li a {
    padding: 35px 100px 35px 35px;
  }
}
@media screen and (max-width: 768px) {
  .m_jobsList > li a {
    padding: 30px 90px 30px 25px;
  }
}
@media screen and (max-width: 600px) {
  .m_jobsList > li a {
    padding: 25px 65px 25px 15px;
  }
}
.m_jobsList > li a:hover .flex div p {
  color: #a4b665;
}
.m_jobsList > li a:hover .icon {
  background: #a4b665;
  border-color: #a4b665;
}
.m_jobsList > li a:hover .icon svg {
  stroke: #fff;
}
.m_jobsList > li a .flex {
  display: flex;
  align-items: center;
}
.m_jobsList > li a .flex div p {
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.3;
  color: #293548;
  transition: color 300ms;
}
@media screen and (min-width: 1440px) {
  .m_jobsList > li a .flex div p {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .m_jobsList > li a .flex div p {
    font-size: calc(1.375rem + (35 - 22) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .m_jobsList > li a .flex div p {
    font-size: 1.375rem;
  }
}
.m_jobsList > li a .icon {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: 45px;
  border: 1px solid rgba(41, 53, 72, 0.3);
  transform: translateY(-50%);
  transition: background 300ms, border-color 300ms;
}
@media screen and (max-width: 1024px) {
  .m_jobsList > li a .icon {
    width: 40px;
    height: 40px;
    right: 35px;
  }
}
@media screen and (max-width: 768px) {
  .m_jobsList > li a .icon {
    right: 25px;
  }
}
@media screen and (max-width: 600px) {
  .m_jobsList > li a .icon {
    right: 15px;
  }
}
@media screen and (max-width: 425px) {
  .m_jobsList > li a .icon {
    width: 34px;
    height: 34px;
  }
}
.m_jobsList > li a .icon svg {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  left: 50%;
  stroke: #a4b665;
  transform: translate(-50%, -50%);
  transition: stroke 300ms;
}
@media screen and (max-width: 1024px) {
  .m_jobsList > li a .icon svg {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 425px) {
  .m_jobsList > li a .icon svg {
    width: 14px;
    height: 14px;
  }
}

.m_gallery a {
  position: relative;
  float: left;
}
.m_gallery a:hover:before, .m_gallery a:hover:after {
  opacity: 1;
}
.m_gallery a:hover picture:after {
  background: rgba(0, 0, 0, 0.6);
}
.m_gallery a picture {
  width: 100%;
}
.m_gallery a picture:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 300ms;
}
.m_gallery .photo1 {
  float: left;
  width: calc(100% - 175px);
  margin-right: 30px;
}
@media screen and (max-width: 1024px) {
  .m_gallery .photo1 {
    margin-right: 20px;
  }
}
@media screen and (max-width: 500px) {
  .m_gallery .photo1 {
    width: 100%;
  }
}
.m_gallery .photo1 picture {
  height: 320px;
}
@media screen and (max-width: 500px) {
  .m_gallery .photo1 picture {
    height: 275px;
  }
}
@media screen and (max-width: 375px) {
  .m_gallery .photo1 picture {
    height: 225px;
  }
}
.m_gallery .photo2, .m_gallery .photo3 {
  width: 145px;
}
@media screen and (max-width: 1024px) {
  .m_gallery .photo2, .m_gallery .photo3 {
    width: 155px;
  }
}
@media screen and (max-width: 500px) {
  .m_gallery .photo2, .m_gallery .photo3 {
    width: calc(50% - 10px);
    margin-top: 20px;
  }
}
.m_gallery .photo2 picture, .m_gallery .photo3 picture {
  height: 145px;
}
@media screen and (max-width: 1024px) {
  .m_gallery .photo2 picture, .m_gallery .photo3 picture {
    height: 150px;
  }
}
@media screen and (max-width: 425px) {
  .m_gallery .photo2 picture, .m_gallery .photo3 picture {
    height: 125px;
  }
}
@media screen and (max-width: 375px) {
  .m_gallery .photo2 picture, .m_gallery .photo3 picture {
    height: 100px;
  }
}
.m_gallery .photo2 {
  margin-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .m_gallery .photo2 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 500px) {
  .m_gallery .photo2 {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.m_gallery .photo3 span {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.m_gallery .photo3 span:before, .m_gallery .photo3 span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
}
.m_gallery .photo3 span:before {
  width: 12px;
  height: 2px;
}
.m_gallery .photo3 span:after {
  width: 2px;
  height: 12px;
  transition: height 300ms;
}
.m_gallery .photo3 picture {
  position: relative;
}
.m_gallery .photo3 picture:after {
  background: rgba(0, 0, 0, 0.5);
}
.m_gallery a:not(.photo1):not(.photo2):not(.photo3) {
  display: none;
}

.m_news {
  position: relative;
  width: 440px;
  height: 575px;
  padding: 30px 45px;
  max-width: 440px;
}
@media screen and (max-width: 1300px) {
  .m_news {
    width: 400px;
    height: 525px;
    padding: 30px 35px;
  }
}
@media screen and (max-width: 1100px) {
  .m_news {
    width: 360px;
    height: 480px;
    padding: 30px 25px;
  }
}
@media screen and (max-width: 1024px) {
  .m_news {
    width: 340px;
    height: 460px;
    padding: 30px 20px;
  }
}
@media screen and (max-width: 768px) {
  .m_news {
    width: 320px;
    height: 440px;
  }
}
@media screen and (max-width: 600px) {
  .m_news {
    width: 300px;
    height: 420px;
    padding: 20px;
    max-width: 350px;
  }
}
@media screen and (max-width: 425px) {
  .m_news {
    width: 280px;
    height: 400px;
  }
}
@media screen and (max-width: 375px) {
  .m_news {
    width: 260px;
    height: 380px;
  }
}
.m_news:hover picture img {
  transform: scale(1.05);
}
.m_news picture {
  overflow: hidden;
  will-change: transform;
}
.m_news picture img {
  transition: transform 500ms cubic-bezier(0.33, 1, 0.68, 1);
}
.m_news h2 {
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1;
}

.m_event {
  display: block;
  position: relative;
  padding-left: 40%;
  padding-bottom: 60px;
  border-bottom: 1px solid rgba(41, 53, 72, 0.3);
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .m_event {
    padding-bottom: 40px;
    padding-left: 45%;
  }
}
@media screen and (max-width: 600px) {
  .m_event {
    padding: 0 25px 30px;
    border-width: 1px;
  }
}
.m_event:hover picture img {
  transform: scale(1.05);
}
.m_event:hover .text .subtitle .bigWord {
  color: #c5dc76;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0);
}
.m_event picture {
  position: absolute;
  height: auto;
  top: 0;
  width: 40%;
  bottom: 60px;
  left: 0;
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .m_event picture {
    bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .m_event picture {
    width: 45%;
    bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .m_event picture {
    position: relative;
    width: 100%;
    height: 400px;
    bottom: 0;
  }
}
@media screen and (max-width: 500px) {
  .m_event picture {
    height: 300px;
  }
}
@media screen and (max-width: 375px) {
  .m_event picture {
    height: 250px;
  }
}
.m_event picture[data-picture]:after {
  transition-duration: 1000ms;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}
.m_event picture img {
  transition: transform 500ms cubic-bezier(0.33, 1, 0.68, 1);
}
.m_event .text {
  padding: 120px;
}
@media screen and (max-width: 1300px) {
  .m_event .text {
    padding: 120px 100px;
  }
}
@media screen and (max-width: 1200px) {
  .m_event .text {
    padding: 120px 90px;
  }
}
@media screen and (max-width: 1024px) {
  .m_event .text {
    padding: 100px 50px;
  }
}
@media screen and (max-width: 768px) {
  .m_event .text {
    padding: 100px 30px;
  }
}
@media screen and (max-width: 600px) {
  .m_event .text {
    padding: 30px 0 0;
  }
}
.m_event .text .subtitle {
  display: inline-block;
  position: relative;
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .m_event .text .subtitle {
    font-size: 1rem;
  }
}
.m_event .text .subtitle .bigWord {
  position: absolute;
  left: 80%;
  bottom: -40px;
  font: 700 12.8125rem/1 "Founders Grotesk", sans-serif;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(41, 53, 72, 0.4);
  transition: color 300ms, -webkit-text-stroke-color 300ms;
  z-index: -1;
}
@media screen and (max-width: 1300px) {
  .m_event .text .subtitle .bigWord {
    font-size: 11.25rem;
    bottom: -30px;
  }
}
@media screen and (max-width: 1100px) {
  .m_event .text .subtitle .bigWord {
    font-size: 10rem;
    bottom: -25px;
  }
}
@media screen and (max-width: 1024px) {
  .m_event .text .subtitle .bigWord {
    font-size: 8.75rem;
    bottom: -20px;
  }
}
@media screen and (max-width: 850px) {
  .m_event .text .subtitle .bigWord {
    font-size: 7.5rem;
    bottom: -15px;
  }
}
@media screen and (max-width: 700px) {
  .m_event .text .subtitle .bigWord {
    font-size: 6.25rem;
    bottom: -10px;
    left: 70%;
  }
}
@media screen and (max-width: 600px) {
  .m_event .text .subtitle .bigWord {
    display: none;
  }
}
.m_event .text h2 {
  margin: 30px 0 60px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.1;
  color: #293548;
}
@media screen and (min-width: 1440px) {
  .m_event .text h2 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .m_event .text h2 {
    font-size: calc(1.75rem + (40 - 28) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .m_event .text h2 {
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 1200px) {
  .m_event .text h2 {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .m_event .text h2 {
    margin: 25px 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .m_event .text h2 {
    margin: 15px 0 30px;
  }
}
@media screen and (max-width: 600px) {
  .m_event .text h2 {
    margin: 10px 0 20px;
  }
}
.m_event .text .info {
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  color: #a4b665;
  text-transform: uppercase;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .m_event .text .info {
    font-size: 1rem;
  }
}
.m_event .text h2 + .info {
  margin-top: 0;
}

.slickNav .slideNumber {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
}
.slickNav .slideNumber .current {
  font-size: 3.25rem;
}
@media screen and (max-width: 1024px) {
  .slickNav .slideNumber .current {
    font-size: 2.8125rem;
  }
}
@media screen and (max-width: 768px) {
  .slickNav .slideNumber .current {
    font-size: 2.5rem;
  }
}
.slickNav .slideNumber .total {
  position: relative;
  top: -20px;
}
@media screen and (max-width: 1024px) {
  .slickNav .slideNumber .total {
    top: -18px;
  }
}
@media screen and (max-width: 768px) {
  .slickNav .slideNumber .total {
    top: -15px;
  }
}
.slickNav .slideNumber .total:before {
  content: "/ ";
}
.slickNav .slick-dots {
  position: relative;
}
.slickNav .slick-dots li button:before {
  display: none;
}

.componentsContainer section:not(.l_news) + section > .yp120 {
  padding-top: 0;
}

form .title {
  margin-bottom: 30px;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 500;
  line-height: 1.3;
  color: #293548;
  font-size: 1.5rem;
}
@media screen and (max-width: 768px) {
  form .title {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 425px) {
  form .title {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 375px) {
  form .title {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 320px) {
  form .title {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 1024px) {
  form .title {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  form .title {
    margin-bottom: 15px;
  }
}
form .errorMessage {
  margin-top: 5px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.5;
  color: #de0524;
  font-size: 1.0625rem;
}
@media screen and (max-width: 768px) {
  form .errorMessage {
    font-size: 1rem;
  }
}
form .note {
  margin-top: 5px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.5;
  color: #bdbfc3;
  font-size: 1.0625rem;
}
@media screen and (max-width: 768px) {
  form .note {
    font-size: 1rem;
  }
}
form label {
  display: block;
  padding-bottom: 5px;
  font: 1.0625rem/1.5 "Founders Grotesk", sans-serif;
  color: #293548;
}
@media screen and (max-width: 768px) {
  form label {
    font-size: 1rem;
  }
}

.inputField, .textField, .checkboxesWrapper, .radioField, .selectField, .fileField, .g-recaptcha {
  position: relative;
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  .inputField, .textField, .checkboxesWrapper, .radioField, .selectField, .fileField, .g-recaptcha {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .inputField, .textField, .checkboxesWrapper, .radioField, .selectField, .fileField, .g-recaptcha {
    margin-bottom: 30px;
  }
}
.inputField.error input, .textField.error input, .checkboxesWrapper.error input, .radioField.error input, .selectField.error input, .fileField.error input, .g-recaptcha.error input {
  color: #de0524;
  border-color: #de0524;
}
.inputField.error .errorMessage, .textField.error .errorMessage, .checkboxesWrapper.error .errorMessage, .radioField.error .errorMessage, .selectField.error .errorMessage, .fileField.error .errorMessage, .g-recaptcha.error .errorMessage {
  display: block;
}

.form-group:last-child {
  margin-bottom: 0;
}

.alert.alert-success {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px white inset !important;
}

header input:-webkit-autofill,
header input:-webkit-autofill:hover,
header input:-webkit-autofill:focus,
header input:-webkit-autofill:active,
footer input:-webkit-autofill,
footer input:-webkit-autofill:hover,
footer input:-webkit-autofill:focus,
footer input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #293548 inset !important;
  -webkit-text-fill-color: white;
}

.inputField input {
  width: 100%;
  padding: 20px;
  font: 1.3125rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  background: transparent;
  border: 1px solid #293548;
}
@media screen and (max-width: 768px) {
  .inputField input {
    font-size: 1.125rem;
    padding: 15px 10px;
  }
}
.inputField .dynamic pre p {
  font-size: 0.9em;
}
.inputField ::-webkit-input-placeholder {
  color: rgba(41, 53, 72, 0.5);
}
.inputField ::-moz-placeholder {
  color: rgba(41, 53, 72, 0.5);
}
.inputField :-ms-input-placeholder {
  color: rgba(41, 53, 72, 0.5);
}
.inputField :-moz-placeholder {
  color: rgba(41, 53, 72, 0.5);
}

.textField textarea {
  width: 100%;
  height: 190px;
  padding: 20px;
  font: 1.3125rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  background: transparent;
  border: 1px solid #293548;
  box-shadow: none;
  outline: none;
  resize: none;
  transition: border 300ms;
}
@media screen and (max-width: 768px) {
  .textField textarea {
    font-size: 1.125rem;
    padding: 15px 10px;
  }
}
.textField ::-webkit-input-placeholder {
  color: rgba(41, 53, 72, 0.5);
}
.textField ::-moz-placeholder {
  color: rgba(41, 53, 72, 0.5);
}
.textField :-ms-input-placeholder {
  color: rgba(41, 53, 72, 0.5);
}
.textField :-moz-placeholder {
  color: rgba(41, 53, 72, 0.5);
}

.checkboxField label {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  margin: 0 30px 20px 0;
  font: 1.3125rem/1.5 "Founders Grotesk", sans-serif;
  color: #293548;
  cursor: pointer;
  user-select: none;
}
@media screen and (max-width: 768px) {
  .checkboxField label {
    font-size: 1.125rem;
    margin: 0 20px 15px 0;
    padding-left: 40px;
  }
}
.checkboxField label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkboxField label span {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 3px;
  left: 0;
  border: 1px solid #293548;
  transition: background 225ms, border-color 225ms;
}
@media screen and (max-width: 768px) {
  .checkboxField label span {
    top: 3px;
  }
}
.checkboxField label span:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 50%;
  background: url("../images/content/icons/crochet.svg") no-repeat center/contain;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 225ms;
}
.checkboxField label input:checked ~ span {
  background: #a4b665;
  border-color: #a4b665;
}
.checkboxField label input:checked ~ span:before {
  transform: translate(-50%, -50%) scale(1);
}

.radioField label {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  margin: 0 50px 20px 0;
  font: 1.3125rem/1.5 "Founders Grotesk", sans-serif;
  color: #293548;
  cursor: pointer;
  user-select: none;
}
@media screen and (max-width: 768px) {
  .radioField label {
    font-size: 1.125rem;
    margin: 0 30px 15px 0;
    padding-left: 40px;
  }
}
.radioField label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radioField label span {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 4px;
  left: 0;
  border: 1px solid #293548;
  border-radius: 50%;
  transition: background 225ms, border-color 225ms;
}
@media screen and (max-width: 768px) {
  .radioField label span {
    top: 3px;
  }
}
.radioField label span:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 8px;
  height: 8px;
  top: 50%;
  background: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 225ms;
}
.radioField label input:checked ~ span {
  background: #a4b665;
  border-color: #a4b665;
}
.radioField label input:checked ~ span:before {
  transform: translate(-50%, -50%) scale(1);
}

.radioWrapper .radioField {
  display: inline-block;
  height: 30px;
}
.radioWrapper .radioField label {
  margin-bottom: 0;
}

.fileField .uploadBtnWrapper {
  position: relative;
  width: 100%;
  display: inline-block;
}
.fileField .uploadBtnWrapper input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.fileField .uploadBtnWrapper .btnUpload {
  display: block;
  position: relative;
  width: 100%;
  padding: 20px 80px 20px 20px;
  font: 1.3125rem/1.4 "Founders Grotesk", sans-serif;
  color: #293548;
  border: 1px solid #293548;
  cursor: pointer;
  transition: color 300ms, background 300ms;
}
@media screen and (max-width: 768px) {
  .fileField .uploadBtnWrapper .btnUpload {
    font-size: 1.125rem;
    padding: 15px 45px 15px 15px;
  }
}
.fileField .uploadBtnWrapper .btnUpload:hover:after {
  left: 0;
  right: auto;
  width: 100%;
}
.fileField .uploadBtnWrapper .btnUpload svg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 20px;
  stroke: #293548;
  transition: stroke 300ms;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .fileField .uploadBtnWrapper .btnUpload svg {
    right: 15px;
  }
}
.fileField .uploadBtnWrapper:hover .btnUpload {
  color: #293548;
}

.custom-select {
  position: relative;
  z-index: 1;
}
.custom-select select {
  display: none !important;
}
.custom-select.custom-select-active {
  transition: box-shadow 300ms;
}
.custom-select.custom-select-active .custom-select-items {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transition: opacity 300ms;
}
.custom-select .custom-select-selected {
  position: relative;
  z-index: 1;
}
.custom-select .custom-select-items {
  position: absolute;
  max-height: 200px;
  overflow: auto;
  top: 100%;
  right: 0;
  left: 0;
  opacity: 0;
  z-index: 19;
  visibility: hidden;
  pointer-events: none;
  overflow-x: hidden;
  scrollbar-width: none;
  transition: opacity 300ms, visibility 0ms ease 300ms;
}
.custom-select .custom-select-items::-webkit-scrollbar {
  display: none;
}
.custom-select .custom-select-items .custom-select-item {
  cursor: pointer;
}

.custom-select.style1 {
  max-width: 100%;
}
.custom-select.style1 .custom-select-selected {
  padding: 20px 50px 20px 30px;
  font: 1.3125rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  background: #fff;
  border: 1px solid #293548;
  cursor: pointer;
  transition: color 300ms, border-color 300ms;
}
@media screen and (max-width: 1024px) {
  .custom-select.style1 .custom-select-selected {
    padding: 20px 40px 20px 20px;
  }
}
@media screen and (max-width: 768px) {
  .custom-select.style1 .custom-select-selected {
    padding: 15px 40px 15px 20px;
    font-size: 1.125rem;
  }
}
.custom-select.style1 .custom-select-selected:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 10px;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  transition: transform 300ms;
  background: url("../images/content/icons/greenPointer.svg") no-repeat center/contain;
}
@media screen and (max-width: 1024px) {
  .custom-select.style1 .custom-select-selected:after {
    right: 20px;
  }
}
.custom-select.style1.custom-select-active {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.custom-select.style1.custom-select-active .custom-select-selected {
  border-color: #fff;
  color: rgba(41, 53, 72, 0.5);
}
.custom-select.style1.custom-select-active .custom-select-selected:after {
  transform: translateY(-50%) rotate(-180deg);
}
.custom-select.style1.custom-select-active .custom-select-items {
  transform: none;
  transition: opacity 300ms, transform 300ms;
}
.custom-select.style1 .custom-select-items {
  background: #fff;
  border-top: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transform: translateY(-30px);
  transition: opacity 300ms, transform 300ms, visibility 0ms ease 300ms;
}
.custom-select.style1 .custom-select-items .custom-select-item {
  padding: 20px 30px 20px 30px;
  font: 1.3125rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  transition: background 300ms, color 300ms;
}
@media screen and (max-width: 1024px) {
  .custom-select.style1 .custom-select-items .custom-select-item {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .custom-select.style1 .custom-select-items .custom-select-item {
    padding: 15px 20px 15px 20px;
    font-size: 1.125rem;
  }
}
.custom-select.style1 .custom-select-items .custom-select-item:hover {
  background: #f4f5f6;
}
.custom-select.style1 .custom-select-items .custom-select-item.custom-select-same {
  background: #f4f5f6;
}

.newsletterField {
  position: relative;
  max-width: 390px;
}
.newsletterField h3 {
  color: white;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.newsletterField input {
  width: 100%;
  padding: 25px 128px 25px 25px;
  font: 1.0625rem/1 "Founders Grotesk", sans-serif;
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
@media screen and (max-width: 1300px) {
  .newsletterField input {
    padding: 20px 113px 20px 20px;
  }
}
@media screen and (max-width: 768px) {
  .newsletterField input {
    padding: 20px 85px 20px 20px;
  }
}
@media screen and (max-width: 425px) {
  .newsletterField input {
    padding: 20px 93px 20px 15px;
  }
}
.newsletterField p.errorMessage {
  position: absolute;
  font-size: 0.9rem !important;
  margin-top: 0;
}
.newsletterField a {
  position: absolute;
  width: 77px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background: #fff;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .newsletterField a {
    width: 60px;
  }
}
.newsletterField a:hover:before {
  right: 0%;
}
.newsletterField a:hover svg {
  stroke: #fff;
}
.newsletterField a:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: #a4b665;
  transition: right 500ms cubic-bezier(0.25, 1, 0.5, 1);
}
.newsletterField a svg {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  left: 50%;
  stroke: #293548;
  transform: translate(-50%, -50%);
  transition: stroke 500ms;
}
.newsletterField ::-webkit-input-placeholder {
  color: #fff;
  font-weight: normal;
}
.newsletterField ::-moz-placeholder {
  color: #fff;
  font-weight: normal;
}
.newsletterField :-ms-input-placeholder {
  color: #fff;
  font-weight: normal;
}
.newsletterField :-moz-placeholder {
  color: #fff;
  font-weight: normal;
}

.searchField {
  display: flex;
  position: relative;
}
.searchField input {
  width: 100%;
  padding: 30px 80px 30px 25px;
  font: 1.4375rem/1 "Founders Grotesk", sans-serif;
  border: 1px solid;
  background: transparent;
}
@media screen and (max-width: 1200px) {
  .searchField input {
    padding: 25px 60px 25px 20px;
    font-size: 1.3125rem;
  }
}
@media screen and (max-height: 750px) {
  .searchField input {
    padding: 20px 60px 20px 20px;
  }
}
.searchField a {
  transition: opacity 300ms;
}
.searchField a:hover {
  opacity: 0.7;
}
.searchField a svg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}
.searchField .btnX {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 55px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);
  transition: opacity 300ms, visibility 0ms ease 300ms;
}
.searchField .btnX:before, .searchField .btnX:after {
  content: "";
  position: absolute;
  width: 18px;
  height: 2px;
  top: 50%;
  left: 50%;
  transition: background 300ms;
}
.searchField .btnX:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.searchField .btnX:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.searchField:not([data-inputvalue=""]) .btnX {
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms;
}

.datepickerWrapper {
  position: relative;
  padding: 0 30px 30px;
  width: 100%;
  background: #fff;
  border: 1px solid #293548;
}
@media screen and (max-width: 1024px) {
  .datepickerWrapper {
    padding: 0 20px 30px;
  }
}
.datepickerWrapper:before {
  content: "";
  position: absolute;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  background: #293548;
}
@media screen and (max-width: 600px) {
  .datepickerWrapper:before {
    height: 60px;
  }
}

.datepicker {
  position: relative;
  width: 100%;
  direction: ltr;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}
.datepicker .datepicker-switch, .datepicker .prev, .datepicker .next {
  height: 70px;
  transition: opacity 300ms;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .datepicker .datepicker-switch, .datepicker .prev, .datepicker .next {
    height: 60px;
  }
}
.datepicker .datepicker-switch:hover, .datepicker .prev:hover, .datepicker .next:hover {
  opacity: 0.6;
}
.datepicker .prev, .datepicker .next {
  width: 25px;
  font-size: 0;
  background-image: url("../images/content/icons/whitePointer.svg");
  background-repeat: no-repeat;
  background-size: 15px 12px;
}
.datepicker .prev.disabled, .datepicker .next.disabled {
  visibility: hidden;
}
.datepicker .datepicker-switch {
  width: calc(100% - 50px);
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  text-transform: uppercase;
  color: #fff;
  vertical-align: middle;
}
.datepicker .prev {
  transform: rotate(180deg);
  background-position: center 50%;
}
.datepicker .next {
  background-position: center 50%;
}
.datepicker .dow {
  width: 30px;
  height: 30px;
  padding-top: 40px;
  padding-bottom: 15px;
  font: 0.875rem/1 "Founders Grotesk", sans-serif;
  font-weight: 700;
  color: #293548;
  text-align: center;
  vertical-align: middle;
  cursor: default;
}
.datepicker .day {
  position: relative;
  width: 34px;
  height: 34px;
  font: 500 0.875rem/1 "Founders Grotesk", sans-serif;
  color: #293548;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  z-index: 1;
}
.datepicker .day:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: background 300ms;
}
.datepicker .day:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: 25%;
  left: 75%;
  transform: translate(-50%, -50%);
  background-color: #293548;
  z-index: 1;
  border-radius: 50%;
}
.datepicker .day.disabled, .datepicker .day.disabled:hover {
  background: none;
  cursor: default;
}
.datepicker .day.disabled:after, .datepicker .day.disabled:hover:after {
  display: none;
}
.datepicker .day:hover:before {
  background: #bec2c8;
}
.datepicker .old, .datepicker .new {
  color: #738487 !important;
}
.datepicker .old.day.active, .datepicker .new.day.active {
  color: #fff !important;
}
.datepicker .old.day.active:before, .datepicker .new.day.active:before {
  background: rgba(41, 53, 72, 0.5) !important;
}
.datepicker .old.day.today, .datepicker .new.day.today {
  color: #fff !important;
}
.datepicker .old.day.today:before, .datepicker .new.day.today:before {
  background: rgba(197, 220, 118, 0.5) !important;
}
.datepicker .day.today {
  color: #fff;
}
.datepicker .day.today:before {
  background: #293548;
}
.datepicker .day.today:hover {
  color: #fff;
}
.datepicker .day.today:hover:before {
  background: #293548;
}
.datepicker .day.active {
  color: #293548;
  cursor: default;
}
.datepicker .day.active:before {
  background: #c5dc76;
}
.datepicker .day.active:hover {
  color: #293548;
}
.datepicker .day.active:hover:before {
  background: #c5dc76;
}
.datepicker .month, .datepicker .year, .datepicker .decade, .datepicker .century {
  float: left;
  width: 25%;
  height: 30px;
  font: 0.75rem/30px "Founders Grotesk Mono", sans-serif;
  color: #c5dc76;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.datepicker .month:hover, .datepicker .year:hover, .datepicker .decade:hover, .datepicker .century:hover {
  background: #e9eaeb;
}
.datepicker .month.focused, .datepicker .year.focused, .datepicker .decade.focused, .datepicker .century.focused {
  color: #fff;
  background: #293548;
}

.control-multi-file-uploader {
  margin-bottom: 45px;
  position: relative;
  border: 2px dashed #bdbfc3;
  padding: 20px;
  border-radius: 5px;
}
.control-multi-file-uploader:hover {
  color: #bdbfc3;
}
.control-multi-file-uploader:hover .dz-clickable {
  background-color: rgba(189, 191, 195, 0.8);
}
.control-multi-file-uploader .dz-clickable {
  height: 100px;
  background-color: rgba(189, 191, 195, 0.3);
  cursor: pointer;
  border-radius: 5px;
  transition: color 300ms, background 300ms;
}
.control-multi-file-uploader .content .placeholder {
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  line-height: 100px;
  text-align: center;
  top: 20px;
  pointer-events: none;
  transition: color 300ms, background 300ms;
}
.control-multi-file-uploader .content .placeholder .upload-full-text {
  display: block;
}
.control-multi-file-uploader .content .placeholder .upload-truncated-text {
  display: none;
}
@media screen and (max-width: 560px) {
  .control-multi-file-uploader .content .placeholder .upload-full-text {
    display: none;
  }
  .control-multi-file-uploader .content .placeholder .upload-truncated-text {
    display: block;
  }
}
.control-multi-file-uploader .content .dz-preview {
  position: relative;
  padding-left: 120px;
  padding-right: 35px;
  min-height: 100px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: rgba(189, 191, 195, 0.3);
  font-family: "Founders Grotesk", sans-serif;
}
.control-multi-file-uploader .content .dz-preview .thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
}
.control-multi-file-uploader .content .dz-preview .dz-details {
  font-size: 1em;
  line-height: 1.5em;
  color: #293548;
  flex-wrap: wrap;
}
.control-multi-file-uploader .content .dz-preview .dz-details .dz-filename,
.control-multi-file-uploader .content .dz-preview .dz-details .dz-size {
  width: 100%;
  margin: 6px 0;
}
.control-multi-file-uploader .content .dz-preview .action-panel {
  position: absolute;
  background: #c5dc76;
  color: #293548;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 4;
  transition: color 300ms, background 300ms;
}
.control-multi-file-uploader .content .dz-preview .action-panel:hover {
  color: #293548;
  background: #a4b665;
}
.control-multi-file-uploader .content .dz-preview .action-panel a {
  position: absolute;
  color: #293548;
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%);
}
.control-multi-file-uploader .content .dz-preview .dz-error-mark {
  display: none;
}
.control-multi-file-uploader .content .dz-preview .dz-error-message {
  position: absolute;
  bottom: 5px;
  color: #a4b665;
}
.control-multi-file-uploader .content .dz-preview .action-panel {
  position: absolute;
}

/*** |- ---------------------------------------- -| ***/
/*** |- LANDING IS ACTIVE ---------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showLanding {
  overflow-x: hidden;
}
.showLanding #landing {
  opacity: 1;
  visibility: visible;
}

/*** |- ---------------------------------------- -| ***/
/*** |- TRANSITION IS ACTIVE ------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showTransition {
  overflow-x: hidden;
}
.showTransition #transition {
  opacity: 1;
  visibility: visible;
  transition: opacity 500ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- CATÉGORY IS ACTIVE --------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_category.is_active {
  background: #c5dc76;
  border-color: #c5dc76;
}

/*** |- ---------------------------------------- -| ***/
/*** |- PAGE ACTIVE ---------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_pagination a.is_active {
  color: #a4b665;
}

/*** |- ---------------------------------------- -| ***/
/*** |- BOUTON CALENDRIER ACTIF ---------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayCalendar:not(.closingOverlayCalendar) .btnCalendar svg {
  opacity: 0;
}
.showOverlayCalendar:not(.closingOverlayCalendar) .btnCalendar .btnX {
  opacity: 1;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_rubricsList > li.open {
  background: #f4f5f6;
}
.m_rubricsList > li.open .clickable .flex div p {
  color: #293548;
}
.m_rubricsList > li.open .clickable .icon {
  background: #a4b665;
  border-color: #a4b665;
}
.m_rubricsList > li.open .clickable .icon svg {
  stroke: #fff;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.m_rubricsList > li.open > .drawer {
  opacity: 1;
  overflow: hidden;
  transform: translateY(0);
  transition: transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms, opacity 600ms linear 300ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- NO TEXT -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_btnFastLinks.noText {
  padding-bottom: 0px;
}
.m_btnFastLinks.noText > span.state1, .m_btnFastLinks.noText > span.state2 {
  opacity: 0;
  visibility: hidden;
  transition: opacity 225ms cubic-bezier(0.33, 1, 0.68, 1), visibility 0ms ease 225ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS HIDDEN ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.m_btnFastLinks.isHidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 225ms, visibility 0ms ease 225ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS ACTIVE ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayFastLinks .m_btnFastLinks {
  z-index: 14;
}

.showOverlayFastLinks:not(.closingOverlayFastLinks) .m_btnFastLinks.isHidden {
  opacity: 1;
  visibility: visible;
  transition: opacity 225ms;
}
.showOverlayFastLinks:not(.closingOverlayFastLinks) .m_btnFastLinks div:before {
  background: #fff;
}
.showOverlayFastLinks:not(.closingOverlayFastLinks) .m_btnFastLinks div .btnX {
  opacity: 1;
}
.showOverlayFastLinks:not(.closingOverlayFastLinks) .m_btnFastLinks div svg {
  opacity: 0;
}
.showOverlayFastLinks:not(.closingOverlayFastLinks) .m_btnFastLinks > span.state1 {
  opacity: 0;
}
.showOverlayFastLinks:not(.closingOverlayFastLinks) .m_btnFastLinks > span.state2 {
  opacity: 1;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayDropdown #overlayDropdown {
  visibility: visible;
  pointer-events: all;
  z-index: 11;
}
.showOverlayDropdown #overlayDropdown .background {
  opacity: 1;
  visibility: visible;
}
.showOverlayDropdown #overlayDropdown .closeDropdown {
  opacity: 1;
  transition: opacity 300ms linear 400ms;
}
.showOverlayDropdown #overlayDropdown .closeDropdown:before {
  right: 0%;
  left: 0%;
  transition: right 800ms cubic-bezier(0.65, 0, 0.35, 1) 600ms, left 800ms cubic-bezier(0.65, 0, 0.35, 1) 600ms;
}
.showOverlayDropdown #overlayDropdown .wrapper {
  bottom: 0%;
  transition: bottom 800ms cubic-bezier(0.25, 1, 0.5, 1);
}
@media screen and (max-width: 1024px) and (max-height: 1366px) and (min-height: 1000px) {
  .showOverlayDropdown #overlayDropdown .wrapper {
    bottom: 40%;
  }
}
.showOverlayDropdown #overlayDropdown .wrapper .section {
  transition: opacity 500ms, transform 500ms cubic-bezier(0.32, 0, 0.67, 0), visibility 0ms ease 800ms;
}
.showOverlayDropdown #overlayDropdown .wrapper .section h2 {
  opacity: 1;
  transform: none;
  transition: opacity 800ms cubic-bezier(0.25, 1, 0.5, 1) 300ms, transform 800ms cubic-bezier(0.25, 1, 0.5, 1) 300ms;
}
.showOverlayDropdown #overlayDropdown .wrapper .section .grid > div {
  opacity: 1;
  transform: none;
  transition: opacity 800ms cubic-bezier(0.25, 1, 0.5, 1), transform 800ms cubic-bezier(0.25, 1, 0.5, 1);
}
.showOverlayDropdown #overlayDropdown .wrapper .section .grid > div:nth-child(1) {
  transition-delay: 300ms;
}
.showOverlayDropdown #overlayDropdown .wrapper .section .grid > div:nth-child(2) {
  transition-delay: 350ms;
}
.showOverlayDropdown #overlayDropdown .wrapper .section .grid > div:nth-child(3) {
  transition-delay: 400ms;
}
.showOverlayDropdown #overlayDropdown .wrapper .section .grid > div:nth-child(4) {
  transition-delay: 450ms;
}
.showOverlayDropdown #overlayDropdown .wrapper .section .grid > div:nth-child(5) {
  transition-delay: 500ms;
}
.showOverlayDropdown #overlayDropdown .wrapper .section .grid > div:nth-child(6) {
  transition-delay: 550ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- ACTIVE SECTION ------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayDropdown[data-section=municipalite] header .content .itemMain nav div:first-child:before {
  height: 5px;
}
.showOverlayDropdown[data-section=municipalite] #overlayDropdown [data-section=municipalite] {
  opacity: 1;
  visibility: visible;
  transform: none;
  transition: opacity 500ms, transform 500ms cubic-bezier(0.33, 1, 0.68, 1);
}
.showOverlayDropdown[data-section=services] header .content .itemMain nav div:nth-child(2):before {
  height: 5px;
}
.showOverlayDropdown[data-section=services] #overlayDropdown [data-section=services] {
  opacity: 1;
  visibility: visible;
  transform: none;
  transition: opacity 500ms, transform 500ms cubic-bezier(0.33, 1, 0.68, 1);
}
.showOverlayDropdown[data-section=loisirs] header .content .itemMain nav div:last-child:before {
  height: 5px;
}
.showOverlayDropdown[data-section=loisirs] #overlayDropdown .wrapper [data-section=loisirs] {
  opacity: 1;
  visibility: visible;
  transform: none;
  transition: opacity 500ms, transform 500ms cubic-bezier(0.33, 1, 0.68, 1);
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS OPENING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayDropdown.openingOverlayDropdown header .itemLogo, .showOverlayDropdown.openingOverlayDropdown header form {
  pointer-events: none;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayDropdown.closingOverlayDropdown #overlayDropdown {
  pointer-events: none;
}
.showOverlayDropdown.closingOverlayDropdown #overlayDropdown .background {
  opacity: 0;
}
.showOverlayDropdown.closingOverlayDropdown #overlayDropdown .wrapper {
  bottom: 100%;
}
.showOverlayDropdown.closingOverlayDropdown #overlayDropdown .btnCloseDropdown {
  opacity: 0;
  transition: opacity 300ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayFastLinks #overlayFastLinks {
  visibility: visible;
  pointer-events: all;
  z-index: 13;
}
.showOverlayFastLinks #overlayFastLinks .wrapper {
  opacity: 1;
  transition: opacity 600ms;
}
.showOverlayFastLinks #overlayFastLinks .wrapper .sideWords {
  right: -30px;
  transition: right 800ms cubic-bezier(0.25, 1, 0.5, 1) 800ms;
}
@media screen and (max-width: 1100px), screen and (max-height: 700px) {
  .showOverlayFastLinks #overlayFastLinks .wrapper .sideWords {
    right: -20px;
  }
}
.showOverlayFastLinks #overlayFastLinks .wrapper > div .column a {
  opacity: 1;
  transform: none;
  transition: opacity 800ms cubic-bezier(0.25, 1, 0.5, 1), transform 800ms cubic-bezier(0.25, 1, 0.5, 1);
}
.showOverlayFastLinks #overlayFastLinks .wrapper > div .column:nth-child(1) a {
  transition-delay: 200ms;
}
.showOverlayFastLinks #overlayFastLinks .wrapper > div .column:nth-child(2) a {
  transition-delay: 250ms;
}
.showOverlayFastLinks #overlayFastLinks .wrapper > div .column:nth-child(3) a {
  transition-delay: 300ms;
}
.showOverlayFastLinks #overlayFastLinks .wrapper > div .column:nth-child(4) a {
  transition-delay: 500ms;
}
.showOverlayFastLinks #overlayFastLinks .wrapper > div .column:nth-child(5) a {
  transition-delay: 550ms;
}
.showOverlayFastLinks #overlayFastLinks .wrapper > div .column:nth-child(6) a {
  transition-delay: 600ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayFastLinks.closingOverlayFastLinks #overlayFastLinks {
  pointer-events: none;
}
.showOverlayFastLinks.closingOverlayFastLinks #overlayFastLinks .wrapper {
  opacity: 0;
}
.showOverlayFastLinks.closingOverlayFastLinks #overlayFastLinks .wrapper > div .column a {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms linear 0ms, transform 0ms linear 300ms !important;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayCalendar #overlayCalendar {
  pointer-events: all;
  visibility: visible;
}
@media screen and (max-width: 600px) {
  .showOverlayCalendar #overlayCalendar .background {
    opacity: 1;
    visibility: visible;
  }
}
.showOverlayCalendar #overlayCalendar .wrapper {
  opacity: 1;
  pointer-events: all;
  transform: none;
}
@media screen and (max-width: 600px) {
  .showOverlayCalendar #overlayCalendar .wrapper {
    transform: translateY(-50%);
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
@media screen and (max-width: 600px) {
  .showOverlayCalendar.closingOverlayCalendar #overlayCalendar .background {
    opacity: 0;
  }
}
.showOverlayCalendar.closingOverlayCalendar #overlayCalendar .wrapper {
  opacity: 0;
  transform: translateY(50px);
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlaySearch header .content .grid .itemMain .mobile .btnSearch .btnX {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}
.showOverlaySearch header .content .grid .itemMain .mobile .btnSearch svg {
  opacity: 0;
  transition: opacity 300ms;
}
.showOverlaySearch #overlaySearch {
  visibility: visible;
  pointer-events: all;
  z-index: 11;
}
.showOverlaySearch #overlaySearch .background {
  opacity: 1;
  visibility: visible;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlaySearch.closingOverlaySearch #overlaySearch {
  pointer-events: none;
}
.showOverlaySearch.closingOverlaySearch #overlaySearch .background {
  opacity: 0;
}
.showOverlaySearch.closingOverlaySearch header .content .grid .itemMain .mobile .btnSearch .btnX {
  opacity: 0;
  transition: opacity 300ms;
}
.showOverlaySearch.closingOverlaySearch header .content .grid .itemMain .mobile .btnSearch svg {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayShare #overlayShare {
  visibility: visible;
}
.showOverlayShare #overlayShare .background {
  opacity: 1;
  visibility: visible;
}
.showOverlayShare #overlayShare .wrapper {
  opacity: 1;
  pointer-events: all;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
  .showOverlayShare #overlayShare .wrapper {
    transform: translateY(-50%);
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayShare.closingOverlayShare #overlayShare .background {
  opacity: 0;
}
.showOverlayShare.closingOverlayShare #overlayShare .wrapper {
  opacity: 0;
  transform: translate(-50%, -25%);
}
@media screen and (max-width: 600px) {
  .showOverlayShare.closingOverlayShare #overlayShare .wrapper {
    transform: translateY(-25%);
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- DRAWERS IS OPEN ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
#overlayMenu .wrapper .linksContainer .groundFloor > li.open {
  border-bottom: 1px solid rgba(41, 53, 72, 0.2);
}
#overlayMenu .wrapper .linksContainer .groundFloor > li.open > a {
  color: #a4b665;
}
#overlayMenu .wrapper .linksContainer .groundFloor > li.open > a span:before {
  height: 0px;
}
#overlayMenu .wrapper .linksContainer .groundFloor > li.open > a span:before, #overlayMenu .wrapper .linksContainer .groundFloor > li.open > a span:after {
  background: #a4b665;
}
#overlayMenu .wrapper .linksContainer .groundFloor > li.open .subLvl1 {
  opacity: 1;
  transform: none;
  transition: opacity 300ms, transform 300ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- MENU IS OPEN --------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayMenu #overlayMenu {
  visibility: visible;
  z-index: 11;
  pointer-events: all;
}
.showOverlayMenu #overlayMenu .background {
  opacity: 1;
  visibility: visible;
}
.showOverlayMenu #overlayMenu .wrapper {
  right: 0%;
  transition: right 800ms cubic-bezier(0.25, 1, 0.5, 1);
  overflow-y: auto;
}
.showOverlayMenu #overlayMenu .wrapper .linksContainer {
  opacity: 1;
  transform: none;
  transition: opacity 600ms ease 300ms, transform 600ms cubic-bezier(0.33, 1, 0.68, 1) 300ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- MENU IS CLOSING ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayMenu.closingOverlayMenu .hamburger span {
  width: 100%;
  opacity: 1;
  left: 0%;
  transform: rotate(0deg);
  transition: 250ms;
}
.showOverlayMenu.closingOverlayMenu .hamburger span:nth-child(1) {
  top: 0px;
}
.showOverlayMenu.closingOverlayMenu .hamburger span:nth-child(2), .showOverlayMenu.closingOverlayMenu .hamburger span:nth-child(3) {
  top: calc(50% - 0.5px);
  width: 80%;
}
.showOverlayMenu.closingOverlayMenu .hamburger span:nth-child(4) {
  bottom: 0px;
}
.showOverlayMenu.closingOverlayMenu #overlayMenu {
  pointer-events: none;
}
.showOverlayMenu.closingOverlayMenu #overlayMenu .background {
  opacity: 0;
}
.showOverlayMenu.closingOverlayMenu #overlayMenu .wrapper {
  right: -100%;
  transition: right 800ms cubic-bezier(0.25, 1, 0.5, 1);
}
.showOverlayMenu.closingOverlayMenu #overlayMenu .wrapper .linksContainer {
  opacity: 0;
  transition: opacity 300ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- BOUTON BLANC --------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.btn.t_white {
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
}
.btn.t_white span {
  background: #fff;
}
.btn.t_white span:before {
  background: #a4b665;
}
.btn.t_white span:after {
  background: url("../images/content/icons/darkBluePointer.svg") no-repeat center/contain;
}
.btn.t_white:hover span:after {
  background: url("../images/content/icons/whitePointer.svg") no-repeat center/contain;
}

/*** |- ---------------------------------------- -| ***/
/*** |- LIEN BLANC ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.link.t_white {
  color: #fff;
}
.link.t_white:before {
  background: #fff;
}

/*** |- ---------------------------------------- -| ***/
/*** |- TITRE DE PAGE ALIGNÉ AVEC LE HEADER ---- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_pageTitle.t_alignedWithHeader .content {
  width: calc(100% - 400px);
  margin-left: auto;
}
@media screen and (max-width: 1400px) {
  .l_pageTitle.t_alignedWithHeader .content {
    width: calc(100% - 300px);
  }
}
@media screen and (max-width: 1300px) {
  .l_pageTitle.t_alignedWithHeader .content {
    width: calc(100% - 250px);
  }
}
@media screen and (max-width: 1200px) {
  .l_pageTitle.t_alignedWithHeader .content {
    width: calc(100% - 200px);
  }
}
@media screen and (max-width: 1024px) {
  .l_pageTitle.t_alignedWithHeader .content {
    width: calc(100% - 150px);
  }
}
@media screen and (max-width: 850px) {
  .l_pageTitle.t_alignedWithHeader .content {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .l_pageTitle.t_alignedWithHeader .content {
    padding-right: 25px;
    padding-left: 25px;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- CATÉGORIE VERTE ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.m_category.t_green {
  background: #c5dc76;
  border-color: #c5dc76;
}

/*** |- ---------------------------------------- -| ***/
/*** |- LAYOUT SLICK NEWS ---------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_news.t_slick:before {
  content: "";
  position: absolute;
  width: 100%;
  top: 40%;
  bottom: 0%;
  left: 0;
  background: #a4b665;
}
.l_news.t_slick .slickNews, .l_news.t_slick .slickNav {
  padding-left: 200px;
}
@media screen and (max-width: 1024px) {
  .l_news.t_slick .slickNews, .l_news.t_slick .slickNav {
    padding-left: 150px;
  }
}
@media screen and (max-width: 850px) {
  .l_news.t_slick .slickNews, .l_news.t_slick .slickNav {
    padding-left: 120px;
  }
}
@media screen and (max-width: 600px) {
  .l_news.t_slick .slickNews, .l_news.t_slick .slickNav {
    padding-left: 25px;
  }
}
.l_news.t_slick .slickNews {
  margin-bottom: 0;
}
.l_news.t_slick .slickNews .m_news {
  margin-right: 75px;
}
@media screen and (max-width: 1300px) {
  .l_news.t_slick .slickNews .m_news {
    margin-right: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .l_news.t_slick .slickNews .m_news {
    margin-right: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .l_news.t_slick .slickNews .m_news {
    margin-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .l_news.t_slick .slickNews .m_news {
    margin-right: 25px;
  }
}
@media screen and (max-width: 600px) {
  .l_news.t_slick .slickNews .m_news {
    margin-right: 20px;
  }
}
@media screen and (max-width: 500px) {
  .l_news.t_slick .slickNews .m_news {
    margin-right: 15px;
  }
}
.l_news.t_slick .slickNav {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .l_news.t_slick .slickNav {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .l_news.t_slick .slickNav {
    margin-top: 30px;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- LAYOUT LIST NEWS ----------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_news.t_list .grid.gaps.gaps {
  width: calc(100% + 25px + 25px);
  margin-left: -25px;
  margin-right: -25px;
}
@media screen and (max-width: 850px) {
  .l_news.t_list .grid.gaps.gaps {
    width: calc(100% + 15px + 15px);
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media screen and (max-width: 768px) {
  .l_news.t_list .grid.gaps.gaps {
    width: calc(100% + 10px + 10px);
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media screen and (max-width: 600px) {
  .l_news.t_list .grid.gaps.gaps {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.l_news.t_list .grid.gaps.gaps .column {
  padding-right: 25px;
  padding-left: 25px;
}
.l_news.t_list .grid.gaps.gaps .column:nth-child(-n+2) {
  margin-top: 0;
}
.l_news.t_list .grid.gaps.gaps .column:not(:nth-child(-n+2)) {
  margin-top: 50px;
}
@media screen and (max-width: 850px) {
  .l_news.t_list .grid.gaps.gaps .column {
    padding-right: 15px;
    padding-left: 15px;
  }
  .l_news.t_list .grid.gaps.gaps .column:nth-child(-n+2) {
    margin-top: 0;
  }
  .l_news.t_list .grid.gaps.gaps .column:not(:nth-child(-n+2)) {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .l_news.t_list .grid.gaps.gaps .column {
    padding-right: 10px;
    padding-left: 10px;
  }
  .l_news.t_list .grid.gaps.gaps .column:nth-child(-n+2) {
    margin-top: 0;
  }
  .l_news.t_list .grid.gaps.gaps .column:not(:nth-child(-n+2)) {
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) {
  .l_news.t_list .grid.gaps.gaps .column {
    padding-right: 0;
    padding-left: 0;
  }
  .l_news.t_list .grid.gaps.gaps .column:nth-child(-n+1) {
    margin-top: 0;
  }
  .l_news.t_list .grid.gaps.gaps .column:not(:nth-child(-n+1)) {
    margin-top: 20px;
  }
}
.l_news.t_list .grid.gaps.gaps .column .m_news {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .l_news.t_list .grid.gaps.gaps .column .m_news {
    margin-right: auto;
    margin-left: auto;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- MODULE NORMAL NEWS --------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_news.t_news {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #293548;
}
.m_news.t_news:hover .text div h2 {
  color: #a4b665;
}
.m_news.t_news picture {
  width: 265px;
  height: 265px;
  margin: 0 auto;
  border-radius: 50%;
}
@media screen and (max-width: 1300px) {
  .m_news.t_news picture {
    width: 240px;
    height: 240px;
  }
}
@media screen and (max-width: 1300px) {
  .m_news.t_news picture {
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .m_news.t_news picture {
    width: 180px;
    height: 180px;
  }
}
@media screen and (max-width: 1024px) {
  .m_news.t_news picture {
    width: 160px;
    height: 160px;
  }
}
.m_news.t_news .m_category {
  position: relative;
  margin-top: -20px;
}
.m_news.t_news .text {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.m_news.t_news .text div {
  width: 100%;
}
.m_news.t_news .text div h2 {
  color: #293548;
  transition: color 300ms;
}
@media screen and (min-width: 1440px) {
  .m_news.t_news .text div h2 {
    font-size: 2.125rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .m_news.t_news .text div h2 {
    font-size: calc(1.5rem + (34 - 24) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .m_news.t_news .text div h2 {
    font-size: 1.5rem;
  }
}
.m_news.t_news .text div p {
  margin-top: 30px;
  font: 500 1.125rem/1 "Founders Grotesk", sans-serif;
  color: #a4b665;
}
@media screen and (max-width: 1024px) {
  .m_news.t_news .text div p {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .m_news.t_news .text div p {
    margin-top: 15px;
  }
}
@media screen and (max-width: 425px) {
  .m_news.t_news .text div p {
    margin-top: 10px;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- MODULE ALL NEWS ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.m_news.t_allNews {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}
.m_news.t_allNews:hover .icon {
  background: #fff;
}
.m_news.t_allNews:hover .icon svg {
  stroke: #c5dc76;
}
.m_news.t_allNews picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.m_news.t_allNews picture:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(41, 53, 72, 0.6);
  z-index: 1;
}
.m_news.t_allNews picture:after {
  z-index: 2;
}
.m_news.t_allNews .sideWords {
  position: absolute;
  top: 50%;
  right: -50px;
  font: 700 14.375rem/1 "Founders Grotesk", sans-serif;
  color: transparent;
  opacity: 0.5;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  white-space: nowrap;
  writing-mode: vertical-lr;
  transform: translateY(-50%) rotate(180deg);
  z-index: 1;
}
@media screen and (max-width: 1300px) {
  .m_news.t_allNews .sideWords {
    font-size: 12.5rem;
  }
}
@media screen and (max-width: 1100px) {
  .m_news.t_allNews .sideWords {
    font-size: 11.25rem;
  }
}
@media screen and (max-width: 1024px) {
  .m_news.t_allNews .sideWords {
    font-size: 10rem;
    right: -40px;
  }
}
@media screen and (max-width: 768px) {
  .m_news.t_allNews .sideWords {
    font-size: 9.375rem;
    right: -30px;
  }
}
.m_news.t_allNews h2 {
  position: relative;
  margin-bottom: 70px;
  color: #fff;
  z-index: 1;
}
@media screen and (min-width: 1440px) {
  .m_news.t_allNews h2 {
    font-size: 3.4375rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .m_news.t_allNews h2 {
    font-size: calc(1.875rem + (55 - 30) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .m_news.t_allNews h2 {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 1300px) {
  .m_news.t_allNews h2 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .m_news.t_allNews h2 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .m_news.t_allNews h2 {
    margin-bottom: 20px;
  }
}
.m_news.t_allNews .icon {
  position: absolute;
  width: 64px;
  height: 64px;
  right: 0;
  bottom: 0;
  background: #c5dc76;
  transition: background 300ms;
  z-index: 1;
}
.m_news.t_allNews .icon svg {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 50%;
  stroke: #fff;
  transform: translate(-50%, -50%);
  transition: stroke 300ms;
}

/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_rubricsList.t_normal > li .clickable {
  padding: 50px 120px 50px 45px;
}
@media screen and (max-width: 1200px) {
  .m_rubricsList.t_normal > li .clickable {
    padding: 40px 120px 40px 45px;
  }
}
@media screen and (max-width: 1024px) {
  .m_rubricsList.t_normal > li .clickable {
    padding: 35px 100px 35px 35px;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList.t_normal > li .clickable {
    padding: 30px 90px 30px 25px;
  }
}
@media screen and (max-width: 600px) {
  .m_rubricsList.t_normal > li .clickable {
    padding: 25px 65px 25px 15px;
  }
}
.m_rubricsList.t_normal > li .drawer {
  padding-right: 45px;
  padding-left: 45px;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList.t_normal > li .drawer {
    padding-right: 35px;
    padding-left: 35px;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList.t_normal > li .drawer {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (max-width: 600px) {
  .m_rubricsList.t_normal > li .drawer {
    padding-right: 15px;
    padding-left: 15px;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- PLUS ----------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_rubricsList.t_plus > li .clickable {
  padding: 20px 120px 20px 30px;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList.t_plus > li .clickable {
    padding: 20px 100px 20px 20px;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList.t_plus > li .clickable {
    padding: 15px 90px 15px 20px;
  }
}
@media screen and (max-width: 600px) {
  .m_rubricsList.t_plus > li .clickable {
    padding: 25px 65px 25px 15px;
  }
}
.m_rubricsList.t_plus > li .clickable .flex {
  min-height: 105px;
}
.m_rubricsList.t_plus > li .clickable .flex picture {
  width: 150px;
  height: 150px;
  margin-right: 30px;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .m_rubricsList.t_plus > li .clickable .flex picture {
    width: 125px;
    height: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .m_rubricsList.t_plus > li .clickable .flex picture {
    width: 100px;
    height: 100px;
    margin-right: 25px;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList.t_plus > li .clickable .flex picture {
    width: 75px;
    height: 75px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 600px) {
  .m_rubricsList.t_plus > li .clickable .flex picture {
    display: none;
  }
}
.m_rubricsList.t_plus > li .clickable .flex div {
  width: calc(100% - 180px);
}
@media screen and (max-width: 1200px) {
  .m_rubricsList.t_plus > li .clickable .flex div {
    width: calc(100% - 155px);
  }
}
@media screen and (max-width: 1024px) {
  .m_rubricsList.t_plus > li .clickable .flex div {
    width: calc(100% - 125px);
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList.t_plus > li .clickable .flex div {
    width: calc(100% - 95px);
  }
}
@media screen and (max-width: 600px) {
  .m_rubricsList.t_plus > li .clickable .flex div {
    width: 100%;
  }
}
.m_rubricsList.t_plus > li .clickable .flex div h6 {
  margin-bottom: 5px;
}
.m_rubricsList.t_plus > li .drawer {
  padding-right: 45px;
  padding-left: 30px;
}
@media screen and (max-width: 1024px) {
  .m_rubricsList.t_plus > li .drawer {
    padding-left: 20px;
    padding-right: 35px;
  }
}
@media screen and (max-width: 768px) {
  .m_rubricsList.t_plus > li .drawer {
    padding-left: 25px;
  }
}
@media screen and (max-width: 600px) {
  .m_rubricsList.t_plus > li .drawer {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.m_rubricsList.t_plus > li .drawer .dynamic {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .m_rubricsList.t_plus > li .drawer .dynamic {
    margin-top: 0;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL AND PLUS ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_normal > li a:hover .flex div p, .m_documentsList.t_plus > li a:hover .flex div p {
  color: #a4b665;
}
.m_documentsList.t_normal > li a:hover .icon, .m_documentsList.t_plus > li a:hover .icon {
  background: #a4b665;
  border-color: #a4b665;
}
.m_documentsList.t_normal > li a:hover .icon svg, .m_documentsList.t_plus > li a:hover .icon svg {
  stroke: #fff;
}
.m_documentsList.t_normal > li a .flex, .m_documentsList.t_plus > li a .flex {
  display: flex;
  align-items: center;
}
.m_documentsList.t_normal > li a .flex div p, .m_documentsList.t_plus > li a .flex div p {
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.3;
  color: #293548;
  transition: color 300ms;
}
@media screen and (min-width: 1440px) {
  .m_documentsList.t_normal > li a .flex div p, .m_documentsList.t_plus > li a .flex div p {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1440px) {
  .m_documentsList.t_normal > li a .flex div p, .m_documentsList.t_plus > li a .flex div p {
    font-size: calc(1.375rem + (35 - 22) * (100vw - 20rem) / (1440 - 320));
  }
}
@media screen and (max-width: 320px) {
  .m_documentsList.t_normal > li a .flex div p, .m_documentsList.t_plus > li a .flex div p {
    font-size: 1.375rem;
  }
}
.m_documentsList.t_normal > li a .icon, .m_documentsList.t_plus > li a .icon {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: 45px;
  border: 1px solid rgba(41, 53, 72, 0.3);
  transform: translateY(-50%);
  transition: background 300ms, border-color 300ms;
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_normal > li a .icon, .m_documentsList.t_plus > li a .icon {
    width: 40px;
    height: 40px;
    right: 35px;
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_normal > li a .icon, .m_documentsList.t_plus > li a .icon {
    right: 25px;
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_normal > li a .icon, .m_documentsList.t_plus > li a .icon {
    right: 15px;
  }
}
@media screen and (max-width: 425px) {
  .m_documentsList.t_normal > li a .icon, .m_documentsList.t_plus > li a .icon {
    width: 34px;
    height: 34px;
  }
}
.m_documentsList.t_normal > li a .icon svg, .m_documentsList.t_plus > li a .icon svg {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  left: 50%;
  stroke: #a4b665;
  transform: translate(-50%, -50%);
  transition: stroke 300ms;
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_normal > li a .icon svg, .m_documentsList.t_plus > li a .icon svg {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 425px) {
  .m_documentsList.t_normal > li a .icon svg, .m_documentsList.t_plus > li a .icon svg {
    width: 14px;
    height: 14px;
  }
}
.m_documentsList.t_normal > li a .icon svg.external, .m_documentsList.t_plus > li a .icon svg.external {
  width: 14px;
  height: 14px;
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_normal > li a .icon svg.external, .m_documentsList.t_plus > li a .icon svg.external {
    width: 12px;
    height: 12px;
  }
}
@media screen and (max-width: 425px) {
  .m_documentsList.t_normal > li a .icon svg.external, .m_documentsList.t_plus > li a .icon svg.external {
    width: 10px;
    height: 10px;
  }
}

/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL --------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_normal > li a {
  padding: 50px 120px 50px 45px;
}
@media screen and (max-width: 1200px) {
  .m_documentsList.t_normal > li a {
    padding: 40px 120px 40px 45px;
  }
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_normal > li a {
    padding: 35px 100px 35px 35px;
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_normal > li a {
    padding: 30px 90px 30px 25px;
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_normal > li a {
    padding: 25px 65px 25px 15px;
  }
}
.m_documentsList.t_normal > li a .flex div {
  width: 100%;
}

/*** |- ---------------------------------------- -| ***/
/*** |- PLUS ----------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_plus li a {
  padding: 20px 120px 20px 30px;
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_plus li a {
    padding: 20px 100px 20px 20px;
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_plus li a {
    padding: 15px 90px 15px 20px;
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_plus li a {
    padding: 25px 65px 25px 15px;
  }
}
.m_documentsList.t_plus li a .flex {
  min-height: 50px;
}
.m_documentsList.t_plus li a .flex picture {
  width: 150px;
  height: 150px;
  margin-right: 30px;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .m_documentsList.t_plus li a .flex picture {
    width: 125px;
    height: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_plus li a .flex picture {
    width: 100px;
    height: 100px;
    margin-right: 25px;
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_plus li a .flex picture {
    width: 75px;
    height: 75px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_plus li a .flex picture {
    display: none;
  }
}
.m_documentsList.t_plus li a .flex div {
  width: calc(100% - 180px);
}
@media screen and (max-width: 1200px) {
  .m_documentsList.t_plus li a .flex div {
    width: calc(100% - 155px);
  }
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_plus li a .flex div {
    width: calc(100% - 125px);
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_plus li a .flex div {
    width: calc(100% - 95px);
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_plus li a .flex div {
    width: 100%;
  }
}
.m_documentsList.t_plus li a .flex div h6 {
  margin-bottom: 5px;
}

/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL AND PLUS IN RUBRIC -------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_normal_inRubric li a:hover:before, .m_documentsList.t_plus_inRubric li a:hover:before {
  right: 0%;
}
.m_documentsList.t_normal_inRubric li a:before, .m_documentsList.t_plus_inRubric li a:before {
  content: "";
  position: absolute;
  top: -1px;
  right: 100%;
  bottom: -1px;
  left: 0;
  background: #fff;
  transition: right 500ms cubic-bezier(0.33, 1, 0.68, 1);
}
.m_documentsList.t_normal_inRubric li a .flex, .m_documentsList.t_plus_inRubric li a .flex {
  position: relative;
  display: flex;
  align-items: center;
  transition: padding 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_normal_inRubric li a .flex, .m_documentsList.t_plus_inRubric li a .flex {
    min-height: 100%;
  }
}
.m_documentsList.t_normal_inRubric li a .flex p, .m_documentsList.t_plus_inRubric li a .flex p {
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.5;
  color: #293548;
  font-size: 1.125rem;
}
.m_documentsList.t_normal_inRubric li a svg, .m_documentsList.t_plus_inRubric li a svg {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  right: 30px;
  stroke: #293548;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_normal_inRubric li a svg, .m_documentsList.t_plus_inRubric li a svg {
    right: 25px;
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_normal_inRubric li a svg, .m_documentsList.t_plus_inRubric li a svg {
    right: 20px;
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_normal_inRubric li a svg, .m_documentsList.t_plus_inRubric li a svg {
    right: 5px;
  }
}
.m_documentsList.t_normal_inRubric li a svg.external, .m_documentsList.t_plus_inRubric li a svg.external {
  width: 16px;
  height: 16px;
}

/*** |- ---------------------------------------- -| ***/
/*** |- NORMAL IN RUBRIC ----------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_normal_inRubric li a {
  padding: 20px 75px 20px 30px;
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_normal_inRubric li a {
    padding: 20px 70px 20px 25px;
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_normal_inRubric li a {
    padding: 20px 65px 20px 20px;
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_normal_inRubric li a {
    padding: 20px 35px 20px 5px;
  }
}
.m_documentsList.t_normal_inRubric li a .flex div {
  width: 100%;
}

/*** |- ---------------------------------------- -| ***/
/*** |- PLUS IN RUBRIC ------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_documentsList.t_plus_inRubric a {
  padding: 20px 75px 20px 10px;
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_plus_inRubric a {
    padding: 20px 70px 20px 10px;
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_plus_inRubric a {
    padding: 20px 65px 20px 10px;
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_plus_inRubric a {
    padding: 20px 35px 20px 5px;
  }
}
.m_documentsList.t_plus_inRubric a .flex picture {
  width: 90px;
  height: 90px;
  margin-right: 30px;
  overflow: hidden;
  border-radius: 50%;
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_plus_inRubric a .flex picture {
    width: 75px;
    height: 75px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_plus_inRubric a .flex picture {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_plus_inRubric a .flex picture {
    display: none;
  }
}
.m_documentsList.t_plus_inRubric a .flex > div {
  width: calc(100% - 120px);
}
@media screen and (max-width: 1024px) {
  .m_documentsList.t_plus_inRubric a .flex > div {
    width: calc(100% - 95px);
  }
}
@media screen and (max-width: 768px) {
  .m_documentsList.t_plus_inRubric a .flex > div {
    width: calc(100% - 80px);
  }
}
@media screen and (max-width: 600px) {
  .m_documentsList.t_plus_inRubric a .flex > div {
    width: 100%;
  }
}
.m_documentsList.t_plus_inRubric a .flex > div h6 {
  margin-bottom: 5px;
  padding-top: 5px;
}

/*** ----- FADE ----- ***/
[data-fade] {
  opacity: 0;
  transition: opacity 800ms;
}
[data-fade][data-scrollfire=fire], [data-fade][data-multiscrollfire=fire] {
  opacity: 1;
  transform: none;
}

/*** ----- FADE UP ----- ***/
[data-fadeup] {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 800ms, transform 800ms cubic-bezier(0.25, 1, 0.5, 1);
}
[data-fadeup][data-scrollfire=fire], [data-fadeup][data-multiscrollfire=fire] {
  opacity: 1;
  transform: none;
}

/*** ----- PICTURE ----- ***/
[data-picture]:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[data-picture][data-color=darkBlue]:after {
  background: #293548;
}
[data-picture][data-color=green]:after {
  background: #c5dc76;
}
[data-picture][data-color=white]:after {
  background: #fff;
}
[data-picture][data-direction=top]:after {
  top: 0%;
  transition: top 1100ms cubic-bezier(0.84, -0.01, 0.48, 0.995);
}
[data-picture][data-direction=right]:after {
  right: 0%;
  transition: right 1100ms cubic-bezier(0.84, -0.01, 0.48, 0.995);
}
[data-picture][data-direction=bottom]:after {
  bottom: 0%;
  transition: bottom 1100ms cubic-bezier(0.84, -0.01, 0.48, 0.995);
}
[data-picture][data-direction=left]:after {
  left: 0%;
  transition: left 1100ms cubic-bezier(0.84, -0.01, 0.48, 0.995);
}
[data-picture][data-scale] img {
  transform: scale(1.1);
  transition: transform 1100ms cubic-bezier(0.84, -0.01, 0.48, 0.995);
}
[data-picture][data-scrollfire=fire] img, [data-picture][data-multiscrollfire=fire] img {
  transform: none;
}
[data-picture][data-scrollfire=fire][data-direction=top]:after, [data-picture][data-multiscrollfire=fire][data-direction=top]:after {
  top: 100%;
}
[data-picture][data-scrollfire=fire][data-direction=right]:after, [data-picture][data-multiscrollfire=fire][data-direction=right]:after {
  right: 100%;
}
[data-picture][data-scrollfire=fire][data-direction=bottom]:after, [data-picture][data-multiscrollfire=fire][data-direction=bottom]:after {
  bottom: 100%;
}
[data-picture][data-scrollfire=fire][data-direction=left]:after, [data-picture][data-multiscrollfire=fire][data-direction=left]:after {
  left: 100%;
}

/*** ----- DELAY ----- ***/
[data-scrollfire], [data-multiscrollfire] {
  transition-delay: 0ms;
}
[data-scrollfire][data-delay1], [data-scrollfire][data-delay1]:after, [data-multiscrollfire][data-delay1], [data-multiscrollfire][data-delay1]:after {
  transition-delay: 100ms;
}
[data-scrollfire][data-delay2], [data-scrollfire][data-delay2]:after, [data-multiscrollfire][data-delay2], [data-multiscrollfire][data-delay2]:after {
  transition-delay: 200ms;
}
[data-scrollfire][data-delay3], [data-scrollfire][data-delay3]:after, [data-multiscrollfire][data-delay3], [data-multiscrollfire][data-delay3]:after {
  transition-delay: 300ms;
}
[data-scrollfire][data-delay4], [data-scrollfire][data-delay4]:after, [data-multiscrollfire][data-delay4], [data-multiscrollfire][data-delay4]:after {
  transition-delay: 400ms;
}
[data-scrollfire][data-delay5], [data-scrollfire][data-delay5]:after, [data-multiscrollfire][data-delay5], [data-multiscrollfire][data-delay5]:after {
  transition-delay: 500ms;
}
[data-scrollfire][data-delay6], [data-scrollfire][data-delay6]:after, [data-multiscrollfire][data-delay6], [data-multiscrollfire][data-delay6]:after {
  transition-delay: 600ms;
}

/*** ----- DURING LOADING AND TRANSITION ----- ***/
.showLanding [data-scrollfire], .showLanding [data-multiscrollfire] {
  transition-delay: 0ms !important;
}

.showTransition [data-scrollfire], .showTransition [data-multiscrollfire] {
  transition-delay: 400ms !important;
}

.showTransition [data-fade],
.showLanding [data-fade] {
  opacity: 0 !important;
}
.showTransition [data-fadeup],
.showLanding [data-fadeup] {
  opacity: 0 !important;
  transform: translateY(100px) !important;
}
.showTransition [data-picture][data-scale] img,
.showLanding [data-picture][data-scale] img {
  transform: scale(1.1);
}
.showTransition [data-picture][data-direction=top]:after,
.showLanding [data-picture][data-direction=top]:after {
  top: 0% !important;
}
.showTransition [data-picture][data-direction=right]:after,
.showLanding [data-picture][data-direction=right]:after {
  right: 0% !important;
}
.showTransition [data-picture][data-direction=bottom]:after,
.showLanding [data-picture][data-direction=bottom]:after {
  bottom: 0% !important;
}
.showTransition [data-picture][data-direction=left]:after,
.showLanding [data-picture][data-direction=left]:after {
  left: 0% !important;
}

/*** |- ---------------------------------------- -| ***/
/*** |- WHITE ---------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.slickNav.t_white .slideNumber span {
  color: #fff;
}
.slickNav.t_white .slick-dots li.slick-active button {
  background: #fff;
}
.slickNav.t_white .slick-dots li button {
  background: rgba(255, 255, 255, 0.2);
}

/*** |- ---------------------------------------- -| ***/
/*** |- DARK BLUE ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.slickNav.t_darkBlue .slideNumber span {
  color: #293548;
}
.slickNav.t_darkBlue .slick-dots li.slick-active button {
  background: #293548;
}
.slickNav.t_darkBlue .slick-dots li button {
  background: rgba(41, 53, 72, 0.2);
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS VERTICAL ---------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.slickNav.t_vertical .slick-dots {
  width: 3px;
  height: 200px;
  bottom: 0;
  margin: 15px auto 0;
}
.slickNav.t_vertical .slick-dots li {
  display: block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 5px 0;
}
.slickNav.t_vertical .slick-dots li button {
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.slickNav.t_vertical .slick-dots li button:before {
  display: none;
}

/*** |- ---------------------------------------- -| ***/
/*** |- IS HORIZONTAL -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.slickNav.t_horizontal {
  display: inline-flex;
}
.slickNav.t_horizontal .slideNumber {
  width: 100px;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .slickNav.t_horizontal .slideNumber {
    width: 80px;
  }
}
.slickNav.t_horizontal .slick-dots {
  width: 270px;
  height: 4px;
  bottom: 0;
  margin: 16px 0 0 20px;
}
@media screen and (max-width: 1024px) {
  .slickNav.t_horizontal .slick-dots {
    margin-top: 14px;
  }
}
@media screen and (max-width: 768px) {
  .slickNav.t_horizontal .slick-dots {
    margin-top: 12px;
    margin-left: 0;
    width: 200px;
  }
}
@media screen and (max-width: 500px) {
  .slickNav.t_horizontal .slick-dots {
    width: 170px;
  }
}
@media screen and (max-width: 375px) {
  .slickNav.t_horizontal .slick-dots {
    width: 150px;
  }
}
.slickNav.t_horizontal .slick-dots li {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0 10px;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .slickNav.t_horizontal .slick-dots li {
    padding: 0 5px;
  }
}
@media screen and (max-width: 425px) {
  .slickNav.t_horizontal .slick-dots li {
    padding: 0 3px;
  }
}
.slickNav.t_horizontal .slick-dots li button {
  padding: 0;
  width: 100%;
  height: 4px;
  border-radius: 0;
}
.slickNav.t_horizontal .slick-dots li button:before {
  display: none;
}

/*** |- ---------------------------------------- -| ***/
/*** |- DARK BLUE ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.searchField.t_darkBlue input {
  color: #293548;
  border-color: #293548;
}
.searchField.t_darkBlue .btnX:hover:before, .searchField.t_darkBlue .btnX:hover:after {
  background: rgba(41, 53, 72, 0.6);
}
.searchField.t_darkBlue .btnX:before, .searchField.t_darkBlue .btnX:after {
  background: #293548;
}
.searchField.t_darkBlue svg {
  stroke: #293548;
}
.searchField.t_darkBlue ::-webkit-input-placeholder {
  color: #293548;
}
.searchField.t_darkBlue ::-moz-placeholder {
  color: #293548;
}
.searchField.t_darkBlue :-ms-input-placeholder {
  color: #293548;
}
.searchField.t_darkBlue :-moz-placeholder {
  color: #293548;
}

/*** |- ---------------------------------------- -| ***/
/*** |- WHITE ---------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.searchField.t_white input {
  color: #fff;
  border-color: #fff;
}
.searchField.t_white .btnX:hover:before, .searchField.t_white .btnX:hover:after {
  background: rgba(255, 255, 255, 0.6);
}
.searchField.t_white .btnX:before, .searchField.t_white .btnX:after {
  background: #fff;
}
.searchField.t_white svg {
  stroke: #fff;
}
.searchField.t_white ::-webkit-input-placeholder {
  color: #fff;
}
.searchField.t_white ::-moz-placeholder {
  color: #fff;
}
.searchField.t_white :-ms-input-placeholder {
  color: #fff;
}
.searchField.t_white :-moz-placeholder {
  color: #fff;
}