/*** |- ---------------------------------------- -| ***/
/*** |- DARK BLUE ------------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.searchField.t_darkBlue {
    input {
        color: $color1;
        border-color: $color1;
    }

    .btnX {
        &:hover {
            &:before, &:after {
                background: rgba($color1, 0.6);
            }
        }
        &:before, &:after {
            background: $color1;
        }
    }

    svg { stroke: $color1; }

    ::-webkit-input-placeholder { color: $color1; }
    ::-moz-placeholder { color: $color1; }
    :-ms-input-placeholder {  color: $color1; }
    :-moz-placeholder { color: $color1; }
}



/*** |- ---------------------------------------- -| ***/
/*** |- WHITE ---------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.searchField.t_white {
    input {
        color: #fff;
        border-color: #fff;
    }

    .btnX {
        &:hover {
            &:before, &:after {
                background: rgba(#fff, 0.6);
            }
        }
        &:before, &:after {
            background: #fff;
        }
    }

    svg { stroke: #fff; }

    ::-webkit-input-placeholder { color: #fff; }
    ::-moz-placeholder { color: #fff; }
    :-ms-input-placeholder {  color: #fff; }
    :-moz-placeholder { color: #fff; }
}
