.l_filters {
    padding-top: 50px;
    padding-bottom: 70px;

    @media screen and (max-width: 1024px) { padding-top: 40px; padding-bottom: 50px; }
    @media screen and (max-width: 900px)  { padding-bottom: 40px; }
    @media screen and (max-width: 768px)  { padding-top: 30px; padding-bottom: 30px; }
    @media screen and (max-width: 600px)  { padding-top: 25px; padding-bottom: 25px; }

    .content {
        @media screen and (max-width: 900px) { padding-right: 0; padding-left: 0; }
    }

    p {
        margin-bottom: 20px;
        font-size: rem(18);

        @media screen and (max-width: 900px)  { padding-left: 50px; margin-bottom: 15px; }
        @media screen and (max-width: 768px)  { padding-left: 30px; margin-bottom: 10px; }
        @media screen and (max-width: 600px)  { padding-left: 25px; }
    }

    .dragAndSlide {
        @media screen and (max-width: 900px) {
            padding-right: 50px;
            padding-left: 50px;
            font-size: 0;
            position: relative;
            display: inline-block;
            width: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            transform: scale(1);
            transition: all 0.2s;
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            scrollbar-width: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        @media screen and (max-width: 768px) { padding-right: 30px; padding-left: 30px; }
        @media screen and (max-width: 600px) { padding-right: 25px; padding-left: 25px; }

        &::-webkit-scrollbar { width: 0 !important }

        .m_category {
            margin: 0 20px 20px 0;

            @media screen and (max-width: 1024px) { margin-bottom: 0; }
        }
    }

    .date {
        display: flex;
        width: 100%;
        margin-top: 30px;
        align-items: center;

        @media screen and (max-width: 900px) { padding-right: 50px; padding-left: 50px; }
        @media screen and (max-width: 768px) { padding-right: 30px; padding-left: 30px; margin-top: 20px; }
        @media screen and (max-width: 700px) { display: block; }
        @media screen and (max-width: 600px) { padding-right: 25px; padding-left: 25px; }

        h2 {
            @media screen and (max-width: 700px) { margin-bottom: 20px; }
        }

        .btn {
            margin-left: auto;

            @media screen and (max-width: 700px) { margin-left: 0; }
        }
    }
}
