.hamburger {
    position: relative;
    width: 24px;
    height: 18px;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background: #fff;
        opacity: 1;
        left: 0%;
        transform: rotate(0deg);
        transition: 250ms;

        &:nth-child(1) { top: 0px; }
        &:nth-child(2), &:nth-child(3) { top: calc(50% - 0.5px); width: 80%; }
        &:nth-child(4) { bottom: 0px; }
    }
}

.showOverlayMenu {
    .hamburger {
        span {
            &:nth-child(1) { width: 0%; bottom: 0px; left: 50%; opacity: 0; }
            &:nth-child(2) { transform: rotate(45deg); width: 100%; }
            &:nth-child(3) { transform: rotate(-45deg); width: 100%; }
            &:nth-child(4) { width: 0%; bottom: 0px; left: 50%; opacity: 0; }
        }
    }
}
