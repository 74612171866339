.m_documentsList {
    list-style-type: none;

    li {
        border-top: 1px solid #bec2c8;
        border-bottom: 1px solid #bec2c8;

        &:not(:first-child) { margin-top: -1px; }

        a {
            display: block;
            position: relative;
        }
    }
}
