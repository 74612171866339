.calendarWrapper {
    position: absolute;
    top: 0;
    right: 80px;

    @media screen and (max-width: 1024px) { right: 50px; }
    @media screen and (max-width: 768px)  { right: 30px; }
    @media screen and (max-width: 600px)  { right: 25px; }
}
.btnCalendar {
    position: relative;
    width: 90px;
    height: 90px;
    margin: 0 auto;

    @media screen and (max-width: 850px), screen and (max-height: 599px) { width: 60px; height: 60px; }
    @media screen and (max-width: 600px) { width: 50px; height: 50px; }

    &:hover:before { transform: scale(1.1); }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $color3;
        border-radius: 50%;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        transition: transform 300ms $easeOutCubic, background 600ms;
    }

    .btnX {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity 600ms;

        &:before, &:after {
            content: '';
            position: absolute;
            width: 26px;
            height: 2px;
            top: 50%;
            left: 50%;
            background: #fff;

            @media screen and (max-width: 850px), screen and (max-height: 599px) { width: 22px; }
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after { transform: translate(-50%, -50%) rotate(-45deg); }
    }

    svg {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 50%;
        fill: #fff;
        stroke: #fff;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: opacity 600ms;

        @media screen and (max-width: 600px) { width: 20px; height: 20px; }
    }
}
