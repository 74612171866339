/*** |- ---------------------------------------- -| ***/
/*** |- BOUTON DE PARTAGE ---------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_btnShare {
    font: 500 rem(15)/1 $font1;
    color: $color1;
    text-transform: uppercase;
    white-space: nowrap;
    transition: color 300ms;

    &:hover {
        color: $color3;

        svg { fill: $color3; }
    }

    svg {
        position: relative;
        width: 20px;
        height: 18px;
        margin-right: 15px;
        top: -1px;
        vertical-align: middle;
        fill: $color1;
        transition: fill 300ms;
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- MENTION CRÉDIT SUR UNE PHOTO ----------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_credit {
    padding: 14px 30px 16px;
    font: 500 rem(14)/1 $font1;
    color: #fff;
    background: $color1;
}


/*** |- ---------------------------------------- -| ***/
/*** |- UNE CATÉGORIE -------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_category {
    display: inline-block;
    padding: 10px 20px;
    font: 500 rem(15)/1.1 $font1;
    color: $color1;
    background: #fff;
    border: 1px solid $color1;
    transition: background 300ms, border-color 300ms;

    &:hover {
        background: $color2;
        border-color: $color2;
    }

    &.is_active {
        background: $color2;
        border-color: $color2;
    }
}
