.inputField {
    input {
        width: 100%;
        padding: 20px;
        font: rem(21)/1 $font1;
        color: $color1;
        background: transparent;
        border: 1px solid $color1;

        @media screen and (max-width: 768px) { font-size: rem(18); padding: 15px 10px; }
    }

    .dynamic{
        pre{
            p{
                font-size: 0.9em;
            }
        }
    }

    ::-webkit-input-placeholder { color: rgba($color1, 0.5); }
    ::-moz-placeholder { color: rgba($color1, 0.5); }
    :-ms-input-placeholder {  color: rgba($color1, 0.5); }
    :-moz-placeholder { color: rgba($color1, 0.5); }
}
