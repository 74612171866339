/*** |- ---------------------------------------- -| ***/
/*** |- IS OPEN -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayFastLinks {
    #overlayFastLinks {
        visibility: visible;
        pointer-events: all;
        z-index: 13;

        .wrapper {
            opacity: 1;
            transition: opacity 600ms;

            .sideWords {
                right: -30px;
                transition: right 800ms $easeOutQuart 800ms;

                @media screen and (max-width: 1100px), screen and (max-height: 700px) { right: -20px; }
            }

            > div .column {
                a {
                    opacity: 1;
                    transform: none;
                    transition: opacity 800ms $easeOutQuart, transform 800ms $easeOutQuart;
                }

                &:nth-child(1) a { transition-delay: 200ms; }
                &:nth-child(2) a { transition-delay: 250ms; }
                &:nth-child(3) a { transition-delay: 300ms; }
                &:nth-child(4) a { transition-delay: 500ms; }
                &:nth-child(5) a { transition-delay: 550ms; }
                &:nth-child(6) a { transition-delay: 600ms; }
            }
        }
    }
}



/*** |- ---------------------------------------- -| ***/
/*** |- IS CLOSING ----------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.showOverlayFastLinks.closingOverlayFastLinks #overlayFastLinks {
    pointer-events: none;

    .wrapper {
        opacity: 0;

        > div .column a {
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 300ms linear 0ms, transform 0ms linear 300ms !important;
        }
    }
}
