#overlayCalendar {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
	visibility: hidden;
	z-index: 5;
    pointer-events: none;

    &::-webkit-scrollbar { display: none; }

    @media screen and (max-width: 600px) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
    }

	.wrapper {
        position: relative;
        pointer-events: none;
        text-align: center;
        width: 350px;
        z-index: 5;
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 600ms $easeOutCubic, transform 600ms $easeOutCubic;

        @media screen and (max-width: 600px) {
            position: absolute;
            width: auto;
            padding-top: 25px;
            top: 50%;
            right: 25px;
            left: 25px;
            transform: translateY(-30%);
            z-index: 11;
        }
	}
}
