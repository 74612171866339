#overlayDropdown {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
	overflow: hidden;
	visibility: hidden;
    z-index: 10;
    pointer-events: none;

    @media screen and (max-height: 599px), screen and (max-width: 850px) { display: none; }

    .btnCloseDropdown {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 45px 0 30px;
        background: #fff;
        opacity: 0;
        text-align: center;
        z-index: 1;

        @media screen and (max-height: 800px) { padding: 35px 0 20px; }
        
        &:hover svg {
            top: 15px;

            @media screen and (max-height: 800px) { top: 10px; }
        }
        
        &:before {
            content: '';
            position: absolute;
            top: 53px;
            left: calc(50% - 56px);
            right: calc(50% - 56px);
            height: 1px;
            background: rgba($color1, 0.2);

            @media screen and (max-height: 800px) { top: 43px; }
        }
        
        
        span {
            position: relative;
            padding: 0 20px;
            font: 500 rem(15)/1 $font1;
            color: $color1;
            background: #fff;
            text-transform: uppercase;
            z-index: 2;
        }
        
        svg {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 25px;
            left: 50%;
            stroke: $color1;
            transform: translateX(-50%) rotate(-90deg);
            transition: top 300ms $easeOutCubic;

            @media screen and (max-height: 800px) { top: 16px; }
        }
    }

    .wrapper {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 100%;
        left: 0;
		background: $color5;
        z-index: 92;
        overflow: hidden;

        .section {
            position: absolute;
            width: 100%;
            padding: 200px 100px 100px;
            top: 0;
            bottom: 0;
            opacity: 0;
            visibility: hidden;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: none;
            transform: translateY(50px);

            @media screen and (max-height: 800px) { padding-top: 175px; }
            @media screen and (max-width: 1300px) { padding-right: 75px; padding-left: 75px; }
            @media screen and (max-width: 1200px) { padding-right: 50px; padding-left: 50px; }
            @media screen and (max-width: 1100px) { padding-right: 35px; padding-left: 35px; }
            @media screen and (max-width: 1024px) { padding-top: 150px; }

            &::-webkit-scrollbar { display: none; }

            > div {
                max-width: 1300px;
                margin: 0 auto;
            }

            h2 {
                margin-bottom: 50px;
                font: rem(39)/1 $font1;
                color: $color1;
                opacity: 0;
                transform: translateY(50px);

                @media screen and (max-height: 800px), screen and (max-width: 1300px) { margin-bottom: 40px; }
                @media screen and (max-height: 750px), screen and (max-width: 1200px) { margin-bottom: 30px; }
                @media screen and (max-height: 700px), screen and (max-width: 1024px) { margin-bottom: 25px; }
                @media screen and (max-width: 1200px) { font-size: rem(34); }
                @media screen and (max-width: 1024px) { font-size: rem(30); }
            }

            .grid {
                flex-flow: nowrap;

                > div {
                    width: 100%;
                    opacity: 0;
                    transform: translateY(50px);

                    &.itemLinks {
                        padding-right: 20px;

                        h3 {
                            min-height: 38px;
                            font: 500 rem(15)/1.3 $font2;
                            color: $color3;
                            text-transform: uppercase;
                        }
                        li {
                            margin-top: 20px;

                            @media screen and (max-height: 800px), screen and (max-width: 1200px) { margin-top: 15px; }
                            @media screen and (max-height: 700px), screen and (max-width: 1024px) { margin-top: 10px; }

                            &:first-child { margin-top: 10px; }

                            a {
                                font: rem(17)/1.2 $font1;
                                color: $color1;
                                transition: color 300ms;

                                &:hover { color: rgba($color1, 0.5); }

                                @media screen and (max-width: 1024px) { font-size: rem(16); }
                            }
                        }
                    }

                    &.itemFeatured {
                        width: 100%;
                        max-width: 200px;
                        margin-top: -90px;

                        @media screen and (max-height: 800px), screen and (max-width: 1300px) { margin-top: -60px; }
                        @media screen and (max-height: 750px), screen and (max-width: 1200px) { margin-top: -50px; }
                        @media screen and (max-height: 700px) { margin-top: 0; }
                        @media screen and (max-width: 1024px) { display: none; }

                        a {
                            position: relative;
                            display: block;

                            &:hover picture img {
                                transform: scale(1.1);
                            }

                            picture {
                                position: relative;
                                height: 350px;
                                overflow: hidden;

                                @media screen and (max-height: 800px), screen and (max-width: 1300px) { height: 300px; }

                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    background: rgba($color1, 0.7);
                                }

                                img {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    transition: transform 300ms $easeOutCubic;
                                }
                            }

                            span {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                font: 500 rem(30)/1.2 $font1;
                                color: #fff;
                                text-align: center;
                                transform: translate(-50%, -50%);

                                @media screen and (max-width: 1200px) { font-size: rem(27); }
                                @media screen and (max-width: 1024px) { font-size: rem(24); }
                            }

                            div {
                                position: absolute;
                                width: 66px;
                                height: 66px;
                                right: 0;
                                bottom: 0;
                                background: $color2;

                                @media screen and (max-height: 800px), screen and (max-width: 1300px) { width: 56px; height: 56px; }
                                @media screen and (max-height: 750px), screen and (max-width: 1200px) { width: 46px; height: 46px; }

                                svg {
                                    position: absolute;
                                    width: 16px;
                                    height: 16px;
                                    top: 50%;
                                    left: 50%;
                                    stroke: #fff;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
