.m_gallery {
    a {
        position: relative;
        float: left;

        &:hover {
            &:before, &:after { opacity: 1; }
            picture:after { background: rgba(#000, 0.6); }
        }

        picture {
            width: 100%;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(#000, 0);
                transition: background 300ms;
            }
        }
    }

    .photo1 {
        float: left;
        width: calc(100% - 175px);
        margin-right: 30px;

        @media screen and (max-width: 1024px) { margin-right: 20px; }
        @media screen and (max-width: 500px)  { width: 100%; }

        picture {
            height: 320px;

            @media screen and (max-width: 500px) { height: 275px; }
            @media screen and (max-width: 375px) { height: 225px; }
        }
    }

    .photo2, .photo3 {
        width: 145px;

        @media screen and (max-width: 1024px) { width: 155px; }
        @media screen and (max-width: 500px)  { width: calc(50% - 10px); margin-top: 20px; }

        picture {
            height: 145px;

            @media screen and (max-width: 1024px) { height: 150px; }
            @media screen and (max-width: 425px)  { height: 125px; }
            @media screen and (max-width: 375px)  { height: 100px; }
        }
    }

    .photo2 {
        margin-bottom: 30px;

        @media screen and (max-width: 1024px) { margin-bottom: 20px; }
        @media screen and (max-width: 500px)  { margin-right: 20px; margin-bottom: 0; }
    }

    .photo3 {
        span {
            position: absolute;
            width: 12px;
            height: 12px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                background: #fff;
                transform: translate(-50%, -50%);
            }
            &:before {
                width: 12px;
                height: 2px;
            }
            &:after {
                width: 2px;
                height: 12px;
                transition: height 300ms;
            }
        }

        picture {
            position: relative;

            &:after { background: rgba(#000, 0.5); }
        }
    }

    a:not(.photo1):not(.photo2):not(.photo3) { display: none; }
}
