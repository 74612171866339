/*** |- ---------------------------------------- -| ***/
/*** |- MARQUEE -------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_marquee {
    padding: 90px 0;
    background: #fff;

    @media screen and (max-width: 1200px) { padding: 70px 0; }
    @media screen and (max-width: 1024px) { padding: 50px 0; }
    @media screen and (max-width: 768px)  { padding: 40px 0; }

    h2 {
        display: block;
        width: 100%;
        font-family: $font1;
        line-height: 1;
        font-weight: 700;
        color: $color3;
        overflow: hidden;
        @include responsiveFontSize(74, 30);
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- NEWS ----------------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_news {
    position: relative;

    .content { position: relative; }
}


/*** |- ---------------------------------------- -| ***/
/*** |- NEWS NAVIGATION ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.l_navigationNews {
    .wrapper {
        display: flex;
        a {
            font: 500 rem(18)/1 $font1;
            color: $color1;
            transition: color 300ms;
            vertical-align: middle;

            &:hover {
                color: $color3;

                svg { stroke: $color3; }
            }
            &.prev svg {
                margin-right: 5px;
                transform: rotate(180deg);
            }
            &.next {
                margin-left: auto;

                svg { margin-left: 5px; }
            }

            svg {
                position: relative;
                top: -1px;
                width: 14px;
                height: 14px;
                stroke: $color1;
                vertical-align: middle;
                transition: stroke 225ms;
            }
        }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- SEARCH RESULTS ------------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_searchResults {
    .result {
        width: 100%;
        padding: 40px 20px;
        border-bottom: 1px solid rgba($color1, 0.3);

        @media screen and (max-width: 1200px) { padding: 60px 60px; }
        @media screen and (max-width: 1024px) { padding: 50px 50px; }
        @media screen and (max-width: 768px)  { padding: 40px 30px; }
        @media screen and (max-width: 600px)  { padding: 30px 25px; }


        &:first-child { border-top: 1px solid rgba($color1, 0.3); }

        mark {
            background: $color2;
            font-style: normal;
            font-weight: normal;
        }

        h2 {
            margin: 10px 0 0;
            font-family: $font1;
            line-height: 1.2;
            color: $color1;
            @include responsiveFontSize(35, 25);
        }

        p:not(.minusculeTitle) { margin: 20px 0 0; }

        .link {
            display: inline-block;
            margin: 30px 0 0;

            @media screen and (max-width: 768px)  { margin-top: 25px; }
            @media screen and (max-width: 600px)  { margin-top: 20px; }
        }

        &:hover .link { color: $color3; &:before { width: 50%; background: $color3; }}
    }
}
