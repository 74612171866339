/*** |- ---------------------------------------- -| ***/
/*** |- LAYOUT SLICK NEWS ---------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_news.t_slick {
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        top: 40%;
        bottom: 0%;
        left: 0;
        background: $color3;
    }

    .slickNews, .slickNav {
        padding-left: 200px;

        @media screen and (max-width: 1024px) { padding-left: 150px; }
        @media screen and (max-width: 850px)  { padding-left: 120px; }
        @media screen and (max-width: 600px)  { padding-left: 25px; }
    }

    .slickNews {
        margin-bottom: 0;

        .m_news {
            margin-right: 75px;

            @media screen and (max-width: 1300px) { margin-right: 50px; }
            @media screen and (max-width: 1200px) { margin-right: 40px; }
            @media screen and (max-width: 1024px) { margin-right: 30px; }
            @media screen and (max-width: 768px)  { margin-right: 25px; }
            @media screen and (max-width: 600px)  { margin-right: 20px; }
            @media screen and (max-width: 500px)  { margin-right: 15px; }
        }
    }

    .slickNav {
        margin-top: 50px;

        @media screen and (max-width: 1024px) { margin-top: 40px; }
        @media screen and (max-width: 768px)  { margin-top: 30px; }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- LAYOUT LIST NEWS ----------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.l_news.t_list {
    .grid.gaps {
        &.gaps {
            width: calc(100% + 25px + 25px);
            margin-left: -25px;
            margin-right: -25px;

            @media screen and (max-width: 850px) {
                width: calc(100% + 15px + 15px);
                margin-left: -15px;
                margin-right: -15px;
            }
            @media screen and (max-width: 768px) {
                width: calc(100% + 10px + 10px);
                margin-left: -10px;
                margin-right: -10px;
            }
            @media screen and (max-width: 600px) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }

            .column {
                padding-right: 25px;
                padding-left: 25px;
                @include setMarginTop(50px, 2);

                @media screen and (max-width: 850px) {
                    padding-right: 15px;
                    padding-left: 15px;
                    @include setMarginTop(30px, 2);
                }
                @media screen and (max-width: 768px) {
                    padding-right: 10px;
                    padding-left: 10px;
                    @include setMarginTop(20px, 2);
                }
                @media screen and (max-width: 600px) {
                    padding-right: 0;
                    padding-left: 0;
                    @include setMarginTop(20px, 1);
                }

                .m_news {
                    width: 100%;

                    @media screen and (max-width: 600px) { margin-right: auto; margin-left: auto; }
                }
            }
        }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- MODULE NORMAL NEWS --------------------- -| ***/
/*** |- ---------------------------------------- -| ***/
.m_news.t_news {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: #fff;
    border: 1px solid $color1;

    &:hover .text div h2 {
        color: $color3;
    }

    picture {
        width: 265px;
        height: 265px;
        margin: 0 auto;
        border-radius: 50%;

        @media screen and (max-width: 1300px) { width: 240px; height: 240px; }
        @media screen and (max-width: 1300px) { width: 200px; height: 200px; }
        @media screen and (max-width: 1024px) { width: 180px; height: 180px; }
        @media screen and (max-width: 1024px) { width: 160px; height: 160px; }
    }

    .m_category {
        position: relative;
        margin-top: -20px;
    }

    .text {
        display: flex;
        flex-grow: 1;
        align-items: center;

        div {
            width: 100%;

            h2 {
                color: $color1;
                @include responsiveFontSize(34, 24);
                transition: color 300ms;
            }

            p {
                margin-top: 30px;
                font: 500 rem(18)/1 $font1;
                color: $color3;

                @media screen and (max-width: 1024px) { margin-top: 20px; }
                @media screen and (max-width: 768px)  { margin-top: 15px; }
                @media screen and (max-width: 425px)  { margin-top: 10px; }
            }
        }
    }
}


/*** |- ---------------------------------------- -| ***/
/*** |- MODULE ALL NEWS ------------------------ -| ***/
/*** |- ---------------------------------------- -| ***/
.m_news.t_allNews {
    display: flex;
    align-items: flex-end;
    overflow: hidden;

    &:hover {
        .icon {
            background: #fff;

            svg { stroke: $color2; }
        }
    }

    picture {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($color1, 0.6);
            z-index: 1;
        }
        &:after { z-index: 2; }
    }

    .sideWords {
        position: absolute;
        top: 50%;
        right: -50px;
        font: 700 rem(230)/1 $font1;
        color: transparent;
        opacity: 0.5;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fff;
        white-space: nowrap;
        writing-mode: vertical-lr;
        transform: translateY(-50%) rotate(180deg);
        z-index: 1;

        @media screen and (max-width: 1300px) { font-size: rem(200); }
        @media screen and (max-width: 1100px) { font-size: rem(180); }
        @media screen and (max-width: 1024px) { font-size: rem(160); right: -40px; }
        @media screen and (max-width: 768px)  { font-size: rem(150); right: -30px; }
        // @media screen and (max-width: 600px)  { display: none; }
    }

    h2 {
        position: relative;
        margin-bottom: 70px;
        color: #fff;
        @include responsiveFontSize(55, 30);
        z-index: 1;

        @media screen and (max-width: 1300px) { margin-bottom: 40px; }
        @media screen and (max-width: 1024px) { margin-bottom: 30px; }
        @media screen and (max-width: 768px)  { margin-bottom: 20px; }
    }

    .icon {
        position: absolute;
        width: 64px;
        height: 64px;
        right: 0;
        bottom: 0;
        background: $color2;
        transition: background 300ms;
        z-index: 1;

        svg {
            position: absolute;
            width: 12px;
            height: 12px;
            top: 50%;
            left: 50%;
            stroke: #fff;
            transform: translate(-50%, -50%);
            transition: stroke 300ms;
        }
    }
}
