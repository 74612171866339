.l_dynamic {
    .content {
        //padding-top: 50px;

        @media screen and (max-width: 1024px) { padding-top: 40px; }
        @media screen and (max-width: 768px)  { padding-top: 30px; }
    }
    .intro {
        position: relative;
        padding-left: 40px;
        margin-bottom: 60px;
        font-family: $font1;
        line-height: 1.2;
        color: $color1;
        @include responsiveFontSize(35, 25);

        &:last-child{
            margin-bottom: 0px;
        }

        @media screen and (max-width: 1024px) { padding-left: 30px; }
        @media screen and (max-width: 768px)  { padding-left: 20px; }

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            top: 0;
            bottom: 0;
            left: 0;
            background: rgba($color1, 0.3);
        }
    }
}

.dynamic {
    > :first-child { margin-top: 0 !important; }
    > :last-child { margin-bottom: 0 !important; }

    h2 {
        @include largeTitle();
        margin: 50px 0 30px;

        @media screen and (max-width: 1024px) { margin: 50px 0 25px; }
        @media screen and (max-width: 768px)  { margin: 40px 0 20px; }
        @media screen and (max-width: 500px)  { margin: 30px 0 15px; }
    }

    h3 {
        @include mediumTitle();
        margin: 40px 0 20px;

        @media screen and (max-width: 1024px) { margin: 30px 0 20px; }
        @media screen and (max-width: 768px)  { margin: 25px 0 15px; }
    }

    h4 {
        @include smallTitle();
        margin: 30px 0 15px;

        @media screen and (max-width: 1024px) { margin: 25px 0 10px; }
        @media screen and (max-width: 768px)  { margin: 20px 0 10px; }
    }

    p {
        margin: 15px 0;
        @include paragraph();

        @media screen and (max-width: 768px) { margin: 10px 0; }
    }

    strong {
        font-style: normal;
        font-weight: 500;
    }

    em {
        font-style: italic;
        font-weight: normal;
    }

    strong em,
    em strong{
        font-weight: 600;
    }

    blockquote {
        position: relative;
        padding: 0 0 0 40px;
        margin: 30px 0;
        font-family: $font1;
        line-height: 1.3;
        color: $color1;
        @include responsiveFontSize(26, 18);

        @media screen and (max-width: 1024px) { margin: 20px 0; padding: 0 0 0 30px; }
        @media screen and (max-width: 768px)  { margin: 15px 0; padding: 0 0 0 25px; }

        p {
            margin-bottom: 0;
            font-family: $font1;
            @include responsiveFontSize(26, 18);
        }

        &:after {
            content: '';
            position: absolute;
            width: 6px;
            top: 5px;
            bottom: 0;
            left: 0;
            background: $color2;
        }
    }

    a:not(.link):not(.btn) {
        display: inline;
        line-height: 1.5;
        font-family: $font1;
        color: $color1;
        box-shadow: inset 0px -2px 0 $color1;
        transition: color 300ms, box-shadow 300ms;
        @include fontsize(21, 0, 18, 16, 0, 0, 0);

        &:hover { color: $color3; box-shadow: inset 0px -2px 0 $color3; }
    }

    .btn {
        margin: 10px 0;
    }

    ul,ol {
        padding-left: 20px;
        margin: 30px 0;
        list-style-position: outside;

        li:not(:last-child){
            margin-bottom: 20px;
        }

        @media screen and (max-width: 768px) { margin: 10px 0; }

        ul, ol {
            margin: 0 0 0 20px;
            margin-top: 20px;

            @media screen and (max-width: 1024px) { margin: 0 0 0 15px; }
            @media screen and (max-width: 768px)  { margin: 0; }
            @media screen and (max-width: 600px)  { padding-left: 10px; }
        }

        > li:last-child { margin-bottom: 0; }

        li {
            @include paragraph();
            font-weight: 500;
            color: $color3;
            line-height: 1;

            //padding-bottom: 30px;
            //@media screen and (max-width: 1024px) { padding-bottom: 15px; }
            //@media screen and (max-width: 768px)  { padding-bottom: 10px; }

            p {
                margin: 0!important;
                font-weight: normal;
                @include paragraph();
                line-height: 1;

                @media screen and (max-width: 1024px) { padding-left: 20px; }
                @media screen and (max-width: 768px)  { padding-left: 10px; }
            }
        }
    }

    ul { list-style-type: disc; }
    ol { list-style-type: decimal; }

    .tableWrapper {
        width: 100%;
        overflow-x: auto;

        margin: 30px 0;
        @media screen and (max-width: 1024px) { margin: 25px 0; }
        @media screen and (max-width: 768px)  { margin: 20px 0; }
        @media screen and (max-width: 500px)  { margin: 15px 0; }

        table { min-width: 600px; }
    }

    table {
        width: auto;
        white-space: nowrap;
        overflow-x: hidden;
        table-layout: fixed;

        thead {
            tr th, tr td {
                border: 1px solid rgba(#fff, 0.3);
                border-bottom: none;
                padding: 10px 30px;
                color: #fff;
                background: $color1;
                white-space: normal;
                text-align: left;

                &, p, h4, h3, h2 {
                    line-height: 1.5;
                    font-family: $font1;
                    font-weight: 500;
                    @include fontsize(21, 0, 18, 16, 0, 0, 0);
                }

                @media screen and (max-width: 768px) { font-size: rem(16) }
            }
        }

        tbody {
            tr:nth-child(odd) td,
            tr:nth-child(odd) th{
                background: #f4f5f6;
            }
            tr{
                td,th{
                    &.style1{
                        background: $color3;
                        color: #fff;
                    }
                }
            }
            tr td:first-child, tr th:first-child { border-left: 1px solid rgba($color1, 0.2); }
            tr:last-child td, tr:last-child th { border-bottom: 1px solid rgba($color1, 0.2); }
            tr td, tr th {
                padding: 10px 30px;
                margin: 0;
                font: rem(18)/1.5 $font1;
                font-weight: normal;
                color: $color1;
                white-space: normal;
                border-right: 1px solid rgba($color1, 0.2);
                vertical-align: middle !important;
                background: #fff;

                @media screen and (max-width: 768px) { font-size: rem(16) }

                > :first-child { margin-top: 0 !important; }
                > :last-child { margin-bottom: 0 !important; }

                p {
                    font-size: rem(18);
                    color: $color1;

                    @media screen and (max-width: 768px) { font-size: rem(16); }
                }
            }

            tr:only-child td:only-child {
                padding: 40px;
                border: 0;
                background: $color5;

                @media screen and (max-width: 1024px) { padding: 30px; }
                @media screen and (max-width: 768px)  { padding: 25px; }
            }

            &:first-child{
                border-top: 1px solid rgba($color1, 0.2);
            }
        }
    }

    pre {
        margin: 10px 0;
        font-family: $font1;
        line-height: 1.3;
        color: $color1;
        white-space: normal;
        @include fontsize(15, 0, 0, 0, 14, 0, 0);
    }

    .tableWrapper + pre{
        margin-top: -10px;
        margin-bottom: 30px;
        @media screen and (max-width: 1024px) { margin-bottom: 25px; }
        @media screen and (max-width: 768px)  { margin-bottom: 20px; }
        @media screen and (max-width: 500px)  { margin-bottom: 15px; }
    }
}
