#overlaySearch {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
    z-index: 10;

    .wrapper {
        position: relative;
        width: 100%;
        height: 0px;
        margin-left: auto;
        background: #fff;
        overflow: hidden;
        z-index: 81;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

        .f_search {
            padding: 130px 50px 30px;

            @media screen and (max-width: 850px) { padding-top: 110px; }
            @media screen and (max-width: 768px) { padding: 100px 30px 20px; }
        }
    }
}
